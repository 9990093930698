import React, { useState } from "react";
import {
  useSendOTPMutation,
  useVerifyOTPMutation,
  useCreateUserMutation,
} from "../../features/api/authSlice";
import AuthSuccess from "./authsuccess";
import CreateAccount from "./createAccount";
import CreatePassword from "./createPassword";
import Success from "./success";
import TwoFA from "./twofa";
import TwoFAVerifcation from "./twofaverification";
import Verify from "./verify";

const Signup = () => {
  const [errMsg, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [network_name, setNetwork] = useState<string>("");
  const [step, setStep] = useState<number>(0);

  const [sendOTP, { isLoading: sendingOTP }] = useSendOTPMutation();
  const [verifyOTP, { isLoading: verifyingOTP }] = useVerifyOTPMutation();
  const [createUser, { isLoading: creatingUser }] = useCreateUserMutation();

  const handleSubmit = () => {
    sendOTP({ email: email })
      .unwrap()
      .then((res) => {
        if (res.success) {
          setStep(1);
        } else {
          setError(res?.Message);
        }
      })
      .catch((err) => {
        setError(err?.res?.Message);
      });
  };

  const handleVerify = (data: { token: string; email: string }) => {
    verifyOTP(data)
      .unwrap()
      .then((res) => {
        setError("")
        if (res) {
          setStep(2);
        }
      })
      .catch((err) => {
        setError(err?.data?.Message || "Something went wrong")
      });
  };

  const handleCreate = (password: string) => {
    let bodyFormData = new FormData();
    bodyFormData.append("FirstName", network_name);
    bodyFormData.append("LastName", "");
    bodyFormData.append("Email", email);
    bodyFormData.append("Password", password);
    bodyFormData.append("RoleId", "1");
    bodyFormData.append("PhoneNumber", "");
    bodyFormData.append("Photo", "");
    bodyFormData.append("CreateNetwork.NetworkName", network_name);
    createUser(bodyFormData)
      .unwrap()
      .then((res) => {
        if (res) {
          setStep(4);
        } else {
          setError("Something went wrong!")
        }
      })
      .catch((err) => {
        setError(err?.data?.Message);
      });
  };

  const handleBack = (val: number) =>{
    setStep(val);
    setError("");
  }

  const renderComponent = () => {
    switch (step) {
      case 0:
        return (
          <CreateAccount
            createAccount={handleSubmit}
            err={errMsg}
            loading={sendingOTP}
            email={email}
            setEmail={setEmail}
            setNetwork={setNetwork}
            network_name={network_name}
          />
        );
      case 1:
        return (
          <Verify
            verifyOTP={handleVerify}
            loading={verifyingOTP}
            err={errMsg}
            email={email}
            goBack={handleBack}
          />
        );
      case 2:
        return <Success handleProceed={setStep} email={email} />;
      case 3:
        return (
          <CreatePassword
            loading={creatingUser}
            createPassword={handleCreate}
            email={email}
            err={errMsg}
          />
        );
      case 4:
        return <TwoFA email={email} setStep={setStep} />;
      case 5:
        return <TwoFAVerifcation email={email} setStep={setStep} />
      case 6:
        return <AuthSuccess email={email} title="Account Created!" />;
      default:
        return;
    }
  };
  return <>{renderComponent()}</>;
};

export default Signup;
