import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { Link } from "react-router-dom";
import { Advert, Campaign } from "../Adverts/types";
import {
  BusinessKYCData,
  IBusinessess,
} from "../../features/api/businessesSliceTypes";
import { UserDetail } from "../../features/api/authTypes";
import { numberWithCommasWithoutDecimal } from "../../helpers";
import { AddButton, WalletBalanceInput } from "./Table.styled";

type Person = {
  Date?: string;
  Advert?: string;
  Reference?: string;
  Amount?: string;
  FundingMethod?: string;
  More?: string;
  Countries?: number;
  Action?: string;
  Network?: string;
  Budget?: string;
  Expense?: string;
  Status?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  date?: string;
  country?: string;
  action?: string;
  created_at?: Date;
  campaign?: Campaign,
  advert_bid?: string,
};

const columnHelper = createColumnHelper<Person>();

export const columnsEarnings = [
  columnHelper.accessor("created_at", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor((row) => row.Advert, {
    id: "advert",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Advert</span>,
  }),
  columnHelper.accessor("ref", {
    header: "Reference",
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
  }),
];

export const columnsWithdraws = [
  columnHelper.accessor("created_at", {
    cell: (val) => {
      const createdAt = val.getValue();
      return <p> {moment(createdAt).format("DD MMM hh:mm")} </p>;
    },
  }),
  columnHelper.accessor("advert_name", {
    header: "Advert",
  }),
  columnHelper.accessor("internal_ref", {
    header: "Reference",
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    cell: (val) => {
      const withdrawnAmount = val.getValue() as number;
      return <p>₦{numberWithCommasWithoutDecimal(withdrawnAmount || 0)}</p>;
    },
  }),
];

const columnAdHelper = createColumnHelper<Advert>();

export const columnsAd = [
  columnAdHelper.accessor("created_at", {
    header: "Date & time created",
    cell: (val) => {
      const dateTime = val.getValue();
      return <p>{moment(dateTime).format("DD MMM hh:mm")}</p>;
    },
  }),
  columnAdHelper.accessor("name", {
    header: "Advert",
  }),
  // columnAdHelper.accessor("advert_countries", {
  //   header: "Countries",
  //   cell: (val) => {
  //     const countries = val.getValue();
  //     return <p>{countries?.length}</p>;
  //   },
  // }),
  columnAdHelper.accessor("budget_daily_cap", {
    header: "Daily budget",
    cell: (val) => {
      const dailyBudget = val.getValue();
      return <p>{dailyBudget ? numberWithCommasWithoutDecimal(dailyBudget || 0) : 0}</p>;
    },
  }),
  columnAdHelper.accessor("id", {
    header: "More",
    cell: (val) => {
      const advertId = val.getValue();
      return (
        <Link to={`/home/adverts/${advertId}`}>
          {" "}
          <span>View More</span>{" "}
        </Link>
      );
    },
  }),
];

export const columnsBids = [
  columnAdHelper.accessor("campaign", {
    header: "Effective Bid",
    cell: (val) => {
      const campaign = val.getValue();
     return <p>{campaign ? campaign?.default_advert_bid?.effective_bid : 0}</p>;
    },
  }),
  columnAdHelper.accessor("campaign", {
    header: "Minimal Bid",
    cell: (val) => {
      const campaign = val.getValue();
      return <p>{campaign ? campaign?.default_advert_bid?.minimal_bid : 0}</p>;
    },
  }),
  columnAdHelper.accessor("advert_bid", {
    header: "My Bid",
  }),
];


export const columnsNetworks = [
  columnHelper.accessor("name", {
    header: "Name",
  }),
  columnHelper.accessor("user", {
    header: "Email",
    cell: (val) => {
      const user = val.getValue() as UserDetail;
      return (
        <p> {user?.email}</p>
      );
    },
  }),
  columnHelper.accessor("user", {
    header: "Phone Number",
    cell: (val) => {
      const user = val.getValue() as unknown as UserDetail;
      return (
        <p> {user?.phone_number || "N/A"}</p>
      );
    },
  }),
  columnHelper.accessor("created_at", {
    header: "Date Joined",
    cell: (val) => {
      const createdAt = val.getValue();
      return (
        <p> {moment(createdAt || new Date()).format("DD MMM YYYY, hh:mm")}</p>
      );
    },
  }),
];

const columnBusinessKYCHelper = createColumnHelper<BusinessKYCData>();

export const columnsBusinesses = [
  columnBusinessKYCHelper.accessor(`user`, {
    header: "Name",
    cell: (val) => {
      const user = val.getValue();
      return <p>{user?.first_name} {" "} {user?.last_name}</p>;
    },
  }),
  columnBusinessKYCHelper.accessor("user", {
    header: "Email",
    cell: (val) => {
      const user = val.getValue();
      return <p>{user?.email}</p>;
    },
  }),
  // columnBusinessKYCHelper.accessor("user", {
  //   header: "Country",
  //   cell: (val) => {
  //     const user = val.getValue();
  //     return <p>{user?.networks[0]?.name}</p>;
  //   },
  // }),
  columnBusinessKYCHelper.accessor("created_at", {
    header: "Date Received",
    cell: (val) => {
      const dateTime = val.getValue();
      return <p>{moment(dateTime).format("DD MMM hh:mm")} WAT</p>;
    },
  }),
  columnBusinessKYCHelper.accessor("id", {
    header: "Action",
    // cell: (info) => <CellRendererButton />,
    cell: (val) => {
      const businessId = val.getValue();
      return (
        <Link to={`/home/businesses/${businessId}`}>
          <span>View More</span>{" "}
        </Link>
      );
    },
  }),
];

const columnBusinessHelper = createColumnHelper<IBusinessess>();

export const columnsAllBusinesses = [
  columnBusinessHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),
  columnBusinessHelper.accessor("user", {
    header: "Email",
    cell: (val) => {
      const user = val.getValue();
      return <p>{user?.email}</p>;
    },
  }),
  columnBusinessHelper.accessor("wallet_balance", {
    header: "Wallet Balance",
    cell: (val) => {
      const walletBalance = val.getValue();
      return <p>₦{numberWithCommasWithoutDecimal(walletBalance || 0)}</p>;
    },
  }),
  columnBusinessHelper.accessor("wallet_balance", {
    header: "",
    cell: (val) => {
      return <AddButton> Add</AddButton>;
    },
  }),
  columnBusinessHelper.accessor("created_at", {
    header: "Date Joined",
    cell: (val) => {
      const dateTime = val.getValue();
      return <p>{moment(dateTime).format("DD MMM hh:mm")} WAT</p>;
    },
  }),
];
