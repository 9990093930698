import React, { useState } from "react";
import {
  Input,
  Label,
  Title,
  InnerContainer,
  Description,
  ContainerVerify,
  FlexBox,
  Form,
  ErrorMsg,
} from "./styles/Container.styled";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { BsFillEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Button } from "../Reusable/Buttons/button";

interface ICreatePassword {
  loading: boolean;
  createPassword: (val: string) => void;
  email: string;
  err: string;
}

const CreatePassword: React.FC<ICreatePassword> = ({
  email,
  loading,
  err,
  createPassword,
}) => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeConfirm, setPasswordTypeConfirm] = useState("password");
  const [errMsg, setError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePasswordConfirm = () =>
    passwordTypeConfirm === "password"
      ? setPasswordTypeConfirm("text")
      : setPasswordTypeConfirm("password");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const onBlur = () => {
    if (password && confirmPassword && password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };

  const handlePassword = () => {
    if (!password) {
      setError("Please enter a valid password");
    } else if (!confirmPassword) {
      setError("Please confirm password");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
      createPassword(password);
    }
  };

  const isButtonDisabled = () => {
    const isPasswordEmpty = !password;
    const isConfirmPasswordEmpty = !confirmPassword;
    const isErrorPresent = !!errMsg;
    const doPasswordsMatch = password !== confirmPassword;

    return (
      isPasswordEmpty ||
      isConfirmPasswordEmpty ||
      isErrorPresent ||
      doPasswordsMatch
    );
  };

  return (
    <ContainerVerify as={"section"}>
      <InnerContainer gap="3.75rem">
        <FlexBox>
          <Logo className="logo" width={115} height={25.578} />
          <div className="">
            <Title marginBottom="1rem">Create Password</Title>
            <Description className="welcome" marginBottom="1rem">
              Welcome : <span className="email">{email}</span>
            </Description>
          </div>
          <Description className="desc">
            Create a password and secure your account
          </Description>
        </FlexBox>

        <Form>
          <div className="formControlPassword">
            <Label htmlFor="password" textAlign="left">
              Enter Password
            </Label>
            <Input
              type={passwordType}
              name="password"
              id="password1"
              value={password}
              onChange={onInputChange}
              onBlur={onBlur}
            />
            <span onClick={togglePassword} className="eye">
              {passwordType === "password" ? (
                <BsEyeSlashFill className="" />
              ) : (
                <BsFillEyeFill className="" />
              )}
            </span>
          </div>
          <div className="formControlPassword">
            <Label htmlFor="password" textAlign="left">
              Confirm Password
            </Label>
            <Input
              type={passwordTypeConfirm}
              name="confirmPassword"
              id="password"
              value={confirmPassword}
              onChange={onInputChange}
              onBlur={onBlur}
            />
            <span onClick={togglePasswordConfirm} className="eye">
              {passwordTypeConfirm === "password" ? (
                <BsEyeSlashFill className="" />
              ) : (
                <BsFillEyeFill className="" />
              )}
            </span>
          </div>
        </Form>
        <ErrorMsg> {errMsg || err}</ErrorMsg>
        <Button
          text="Set Password"
          onClick={() => handlePassword()}
          isLoading={loading}
          height="40px"
          disabled={isButtonDisabled()}
        />
      </InnerContainer>
    </ContainerVerify>
  );
};

export default CreatePassword;
