import React from "react";
import { BackArrow } from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
} from "../Dashboard/Dashboard.styled";
import ArrowYellow from "../../assets/icons/Wallet/arrow-yellow.svg";
import { ScaleLoader } from "react-spinners";
import { CountryBox } from "./Wallet.styled";
import { useGetBankAccountsQuery } from "../../features/api/walletSlice";
import { BeneficiaryModalProps } from "../../types";

const BeneficiaryModal: React.FC<BeneficiaryModalProps> = ({
  show,
  handleCloseModal,
  modalRef,
  handleBeneficiary
}) => {
  const { data: beneficiaries, isLoading } = useGetBankAccountsQuery();
  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer gapSpace="1.875rem">
        <BackArrow>
          <div className="back-wallet">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Select Bank</ModalTitle>
          </div>
        </BackArrow>
        <div>
          {isLoading ? (
            <ScaleLoader
              loading={isLoading}
              height={18}
              width={4}
              color={"#3d5170"}
            />
          ) :
            beneficiaries?.data?.map((each) => {
              return (
                <CountryBox key={each?.id} onClick={()=>handleBeneficiary(each)}>
                  <div className="flex">
                    <img src={each?.bank?.photo} alt="Country's flag" />
                    <h4 className="title">{each?.account_name} ({each?.account_no.slice(0, 6)} ** {each?.account_no.slice(8)})</h4>
                  </div>
                  <img src={ArrowYellow} alt="" />
                </CountryBox>
              )
            })

          }
        </div>
      </ModalContainer>
    </MainContainer>
  );
};

export default BeneficiaryModal;
