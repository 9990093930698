import React from "react";
import { AccountTitle } from "../../Account/Account.styled";
import TableMain from "../../Tables/TableMain";
import { columnsBids } from "../../Tables/TableData";
import { useGetOneAdvertsQuery } from "../../../features/api/advertSlice";
import { useParams } from "react-router-dom";

const Bids = () => {
  const greenColor = "#1A1A1A";
  const {advertId} = useParams();
  
  //Get the advert detail
  const { data: advertDetail} = useGetOneAdvertsQuery(advertId as string);
  console.log(advertDetail, 'detail')
  return (
    <div>
      <AccountTitle>Bids</AccountTitle>
      {advertDetail?.data &&
      <div style={{ textAlign: "left" }}>
        <TableMain
          defaultData={[advertDetail?.data] || []}
          columns={columnsBids}
          greenColor={greenColor}
        />
      </div>}
    </div>
  );
};

export default Bids;
