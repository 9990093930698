import React from "react";
import { BackArrow } from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  NoticeBox,
  Box2,
  MainContainer,
} from "./Dashboard.styled";
import TodayIcon from "../../assets/icons/HomeIcons/speaker-today.svg";
import YesterdayIcon from "../../assets/icons/HomeIcons/yesterday.svg";
import { ModalMainProps } from "../../types";

const DashboardModal: React.FC<ModalMainProps> = ({
  show,
  handleCloseModal,
  modalRef,
}) => {
  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer>
        <BackArrow justifyContent alignItems>
          <div className="back">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Notification</ModalTitle>
          </div>
        </BackArrow>

        <NoticeBox>
          <h4>Today</h4>
          <Box2 nonWrap>
            <img src={TodayIcon} alt="" className="notify-icon" />
            <div>
              <h5>Pending Approval</h5>
              <p>Good day, You having pending advert requests for approval</p>
            </div>
          </Box2>
        </NoticeBox>
        <NoticeBox>
          <h4>Yesterday</h4>
          <Box2 nonWrap>
            <img src={YesterdayIcon} alt="" className="notify-icon" />
            <div>
              <h5>Service Maintainance</h5>
              <p>
                Good day, the platform will be on a technical maintainance fro
                12am to 6pm on 20th of April 2023
              </p>
            </div>
          </Box2>
        </NoticeBox>
      </ModalContainer>
    </MainContainer>
  );
};

export default DashboardModal;
