import React, { useState } from "react";
import {
  Input,
  Label,
  Form,
  ErrorMsg,
} from "../Authentication/styles/Container.styled";
import { BsFillEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button } from "../Reusable/Buttons/button";
import {
  AccountTitle,
  HeadingBox,
  MarginBox,
  SecurityForm,
} from "./Account.styled";
import { useResetSignedInPasswordMutation } from "../../features/api/authSlice";
import toast from "react-hot-toast";
import { TabComponent } from "../Reusable/types";

interface InputState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  [key: string]: string;
}

interface ErrorState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  [key: string]: string;
}

const initialInputState: InputState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const initialErrorState: ErrorState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const Security = () => {
  const [passwordVisibility, setPasswordVisibility] = useState<InputState>({
    currentPassword: "password",
    newPassword: "password",
    confirmPassword: "password",
  });

  const [
    resetPasswordForLoggedInUser,
    { data, isLoading: sendingResetPassword },
  ] = useResetSignedInPasswordMutation();

  const [input, setInput] = useState<InputState>(initialInputState);
  const [error, setError] = useState<ErrorState>(initialErrorState);

  const [errMsg, setErr] = useState<string>("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(name, value);
  };

  const validateInput = (name: string, value: string) => {
    const stateObj = { ...error, [name]: "" };

    switch (name) {
      case "currentPassword":
        if (!value) {
          stateObj[name] = "Please enter current password.";
        }
        break;

      case "newPassword":
        if (!value) {
          stateObj[name] = "Please enter new Password.";
        } else if (input.confirmPassword && value !== input.confirmPassword) {
          stateObj["confirmPassword"] =
            "Password and Confirm Password do not match.";
        }
        break;

      case "confirmPassword":
        if (!value) {
          stateObj[name] = "Please enter Confirm Password.";
        } else if (input.newPassword && value !== input.newPassword) {
          stateObj[name] = "Password and Confirm Password do not match.";
        }
        break;

      default:
        break;
    }

    setError(stateObj);
  };

  // const allFieldsAreErrorFree = () => {
  //   return (
  //     Object.values(error).every((item) => item === "") &&
  //     Object.values(input).every((item) => item !== "")
  //   );
  // };

  const allFieldsAreErrorFree = () => {
    const errorMessages: any = {};

    if (!input.currentPassword) {
      errorMessages["currentPassword"] = "Please enter current password.";
    }

    if (!input.newPassword) {
      errorMessages["newPassword"] = "Please enter new Password.";
    }

    if (!input.confirmPassword) {
      errorMessages["confirmPassword"] = "Please enter Confirm Password.";
    }

    setError((prev) => ({
      ...prev,
      ...errorMessages,
    }));

    return (
      Object.keys(errorMessages).length === 0 &&
      Object.values(input).every((item) => item !== "")
    );
  };

  const togglePasswordVisibility = (name: string) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [name]: prev[name] === "password" ? "text" : "password",
    }));
  };

  const hasErrors = () => {
    return Object.values(error).some((errorMessage) => errorMessage !== "");
  };

  const handleSubmit = () => {
    // if (!allFieldsAreErrorFree()) {
    //   let arr: ("currentPassword" | "newPassword" | "confirmPassword")[] = [
    //     "currentPassword",
    //     "newPassword",
    //     "confirmPassword",
    //   ];
    //   for (let key of arr) {
    //     if (input[key] == "") {
    //       setError((prev) => {
    //         return { ...prev, [key]: "Field must not be empty" };
    //       });
    //     }
    //   }
    //   return;
    // }

    const { currentPassword, newPassword, confirmPassword } = input;
    const data = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };

    // Check for errors before submitting
    if (!allFieldsAreErrorFree()) {
      return;
    }

    resetPasswordForLoggedInUser(data)
      .unwrap()
      .then((res) => {
        if (res) {
          toast.success("Password reset was successful");
        }
      })
      .catch((error) => {
        const errorMessage = error?.data?.Message || "An error occurred.";
        setErr(errorMessage);
        toast.error("An error occurred");
      })
      .finally(() => {
        setInput(initialInputState);
        setErr("");
      });
  };

  return (
    <div>
      <HeadingBox>
        <AccountTitle>Reset Password</AccountTitle>
        <span className="line"></span>
      </HeadingBox>

      <SecurityForm>
        <Form>
          {["currentPassword", "newPassword", "confirmPassword"].map((name) => (
            <div className={`formControlPassword m40`} key={name}>
              <Label htmlFor={name} textAlign="left">
                {name === "currentPassword"
                  ? "Current Password"
                  : name === "newPassword"
                  ? "New Password"
                  : "Confirm Password"}
              </Label>
              <Input
                type={passwordVisibility[name]}
                name={name}
                id={`password-${name}`}
                value={input[name]}
                onChange={onInputChange}
                onBlur={() => validateInput(name, input[name])}
              />
              <span
                onClick={() => togglePasswordVisibility(name)}
                className="eye"
              >
                {passwordVisibility[name] === "password" ? (
                  <BsEyeSlashFill className="" />
                ) : (
                  <BsFillEyeFill className="" />
                )}
              </span>
              {error[name] && <span className="err">{error[name]}</span>}
            </div>
          ))}
        </Form>
        {errMsg && (
          <MarginBox margin="-1rem 0 1rem 0">
            <ErrorMsg> {errMsg}</ErrorMsg>
          </MarginBox>
        )}
        <Link to={""}>
          <Button
            text="Save Changes"
            widthProps="50%"
            isLoading={sendingResetPassword}
            onClick={handleSubmit}
            height="40px"
            disabled={hasErrors()}
          />
        </Link>
      </SecurityForm>
    </div>
  );
};

export default Security;
