import React, { useRef, useState } from "react";
import {
  RoleInnerBox,
  RoleBoxMain,
  BtnGroup,
  PinInputBox,
} from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { ErrorMsg, InputNum } from "../Authentication/styles/Container.styled";

interface AuthCodeProps {
  showRole: boolean;
  handleCloseRole: () => void;
  modalRef?: any;
  setAuthCode: (val: string) => void;
  handleNext: (val: string)=> void;
  isLoading: boolean;
  err: string
}


const AuthCode: React.FC<AuthCodeProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
  setAuthCode,
  handleNext,
  isLoading,
  err
}) => {
  const [focusIndex, setFocusIndex] = useState(0);
  const [errMsg, setError] = useState<string>("");
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleDigit = (val: string, idx: number) => {
    if (val.length > 1) {
      val = val.slice(0, 1);
    }
    setPin((pre) => pre.map((e, id) => (idx === id ? val : e)));

    if (val.length === 1) {
      const nextIndex = idx + 1;
      inputRefs.current[nextIndex]?.focus();
    }
  };

  const [pin, setPin] = useState<Array<string | null>>([
    null,
    null,
    null,
    null,
    null,
    null
  ]);

  const handleKeyDown = (key: string, idx: number) => {
    if (key === "Backspace" && pin[idx] === "") {
      const preIndex = idx - 1;
      inputRefs.current[preIndex]?.focus();
    }
  };

  const handleFocus = (idx: number) => {
    setFocusIndex(idx);
  };

  const handleSubmit = () => {
    let stringPin = pin.join("");
    if (stringPin.length !== 6) {
      setError("Please enter the 6 digit authentication pin");
    } else {
      setAuthCode(stringPin);
      setError("");
      handleNext(stringPin)
    }
  }

  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <div className="role-class">
        <RoleInnerBox>
          <div >
            <h2>Enter Auth Code</h2>
            <p> Please enter your 6-digit authentication code from <br />the Authenticator app.</p>
          </div>
          <div style={{ marginTop: " 42px" }}>

            <PinInputBox>
              {pin.map((val, idx) => {
                return (
                  <InputNum
                    type="number"
                    name="digit1"
                    value={val || ""}
                    max={1}
                    onChange={(e) => handleDigit(e.target.value, idx)}
                    ref={(ref) => (inputRefs.current[idx] = ref)}
                    className={`${val !== null && val !== "" ? "inputClr" : "inputGrey"
                      } ${idx === focusIndex ? "borderBlue" : ""}`}
                    onKeyDown={(e) => handleKeyDown(e.key, idx)}
                    onFocus={() => handleFocus(idx)}
                  />
                );
              })}
            </PinInputBox>
          </div>
          <p> {errMsg || err && <ErrorMsg> {errMsg || err}</ErrorMsg>} </p>
          <BtnGroup style={{ marginTop: " 42px" }} className="role-btn mt-5">
            <Button
              text="Confirm"
              onClick={handleSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </BtnGroup>
        </RoleInnerBox>
      </div>
    </RoleBoxMain>
  );
};

export default AuthCode;
