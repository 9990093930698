// Format dates as "dd-mm-yyyy"
export const formatDate = (date: Date | null): string => {
  if (date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  return "";
};

export function numberWithCommasWithoutDecimal(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//Open Camers
export const openCamera = () => {
  return async () => {
    let stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    let videotostream = window.document.getElementById(
      "videocamera"
    ) as HTMLVideoElement;
    videotostream.srcObject = stream;
    return stream;
  };
};

//Take a picture
export const takePicture = (stream: MediaStream) => {
  return (): string => {
    const canvas = document.getElementById(
      "captureImage"
    ) as HTMLCanvasElement | null;
    const video = document.getElementById(
      "videocamera"
    ) as HTMLVideoElement | null;

    if (!canvas || !video) {
      throw new Error("Canvas or video element not found.");
    }

    const context = canvas.getContext("2d");
    if (!context) {
      throw new Error("Canvas context not available.");
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const image_data_url = canvas.toDataURL("image/jpeg");

    video.srcObject = null;

    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());

    return image_data_url;
  };
};

type IdCollection = {
  [key: number]: string;
};

export const idCollection: IdCollection = {
  1: "International Passport",
  2:  "Drivers License",
  3:  "National Identity Card"
}

/**
 * Copies the specified text to the clipboard.
 * @param text - The text to be copied.
 */
 export const copyToClipboard = (text: string): void => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};


type StatusCodes = {
  [key: string]: string;
};

export const NetworkChainId: StatusCodes  = {
  "1": "Ethereum",
  "137": "Polygon",
  "56": "Binance Smart Chain"
}

export const NetworkChains = [{key: "1", network: "Ethereum"}, {key: "137", network: "Polygon"}, {key: "56", network: "Binance Smart Chain"}]