import React from "react";
import {
  ProgressBarContainer,
} from "./styles/Container.styled";
import 'react-circular-progressbar/dist/styles.css';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';


interface IProgressbar{
    percentage: number,
    text: string
}
const ProgressBar: React.FC<IProgressbar> = ({percentage, text}) => {

  return (
    <ProgressBarContainer style={{ width: 48, height: 48 }}>
        <CircularProgressbar 
        value={percentage} 
        strokeWidth={4} 
        background={true} 
        text={text} 
        styles={buildStyles({ 
            // Text size
            textSize: '20px',     
            // Colors
            pathColor: `rgba(229, 185, 16)`,
            textColor: '#E5B910',
            trailColor: '#EEE1A9',
            backgroundColor: "rgba(229, 185, 16, 20%)",
        })}
        />
  </ProgressBarContainer>
  );
};

export default ProgressBar;
