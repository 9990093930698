import React, { MouseEvent, useEffect, useRef, useState } from "react";
import {
  Box5,
  Form2,
  Input2,
  Label2,
} from "../Wallet/Wallet.styled";
import { columnsNetworks } from "../Tables/TableData";
import TableMain from "../Tables/TableMain";
import { Button } from "../Reusable/Buttons/button";
import useOutsideClick from "../../hooks/useOutsideClick";
import FilterGroupCampaign from "../Dashboard/FilterGroupCamp";
import { Section } from "../Dashboard/Dashboard.styled";
import { AlignBox } from "./network.styled";
import AddNetwork from "./NetworkModal";
import { useGetNetworksQuery } from "../../features/api/networkSlice";
import { CardLoader } from "../Reusable/CardLoader";
import EmptyBox from "../Reusable/EmptyBox";

const greenColor = "#1A1A1A";
const Networks = () => {
  const [searchValue, setSearch] = useState("");
  const [showRole, setShowRole] = useState(false);
  const [showAddNetwork, setShowAddNetwork] = useState(false);

  const { data: networks, isLoading: gettingNetworks, isFetching: fetchingNetworks, refetch } = useGetNetworksQuery(searchValue)


  const modalRef = useRef<HTMLElement>(null);
  const modalRef2 = useRef<HTMLElement>(null);

  // const handleRole = (e: MouseEvent<HTMLButtonElement>): void => {
  //   e.preventDefault();
  //   setShowRole(true);
  // };

  const handleOpenAddBusiness = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowAddNetwork(true);
  };

  const handleCloseRole = () => {
    setShowRole(false);
    setShowAddNetwork(false);
  };

  useEffect(()=>{
    if(searchValue === ""){
      refetch()
    }
  }, [searchValue])

  useOutsideClick(modalRef, handleCloseRole);
  useOutsideClick(modalRef2, handleCloseRole);
  return (
    <Section>
      <Form2>
        <Box5>
          <Label2 htmlFor="search">Search</Label2>
          <Input2
            type="text"
            id="search"
            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setSearch(e.target.value)}
            value={searchValue}
            placeholder="Name or email"
            inputWidth="100%"
          />
        </Box5>
        <AlignBox>
          <Button
            text="Add Network"
            widthProps="12rem"
            paddingProps="0.45rem"
            fontSizeProps="0.9rem"
            onClick={handleOpenAddBusiness}
          />
        </AlignBox>
      </Form2>
      {gettingNetworks || fetchingNetworks ? (
        <CardLoader />
      ) : (
        (networks?.data?.length || 0) > 0 ? (
        <div style={{ textAlign: "left" }}>
          <TableMain
            defaultData={networks?.data || []}
            columns={columnsNetworks}
            greenColor={greenColor}
          />
        </div>) :
        <EmptyBox message="No networks found!" />
      )}
      {showRole && (
        <FilterGroupCampaign
          handleCloseRole={handleCloseRole}
          showRole={showRole}
          modalRef={modalRef}
        />
      )}
      <AddNetwork
        show={showAddNetwork}
        handleCloseModal={handleCloseRole}
        modalRef={modalRef2}
      />
    </Section>
  );
};

export default Networks;
