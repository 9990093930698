import React, { useState } from "react";
import {
  BackArrow,
  ErrorMsg,
  Form,
} from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
} from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { Input16, Label14 } from "../Reusable/Reuseable.styled";
import { useCreateUserMutation } from "../../features/api/authSlice";
import { ModalMainProps } from "../../types";

const INITIAL_STATE = {
  networkName: "",
  email: "",
  defaultPassword: "",
  phoneNumber: "",
};
const AddNetwork: React.FC<ModalMainProps> = ({
  show,
  handleCloseModal,
  modalRef,
}) => {
  const [form, setForm] = useState(INITIAL_STATE);
  const [profileUrl, setprofileUrl] = useState<string>("");
  const [errMsg, setErr] = useState<string>("");

  const [createNetwork, {isLoading: creatingNetwork}] = useCreateUserMutation()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setprofileUrl(blobUrl);
    }
    setForm({ ...form, [e.target.name]: value });
  };

  const handleProfileUpdate = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("FirstName", form.networkName);
    bodyFormData.append("LastName", "");
    bodyFormData.append("Email", form.email);
    bodyFormData.append("Password", form.defaultPassword);
    bodyFormData.append("RoleId", "1");
    bodyFormData.append("PhotoNumber", "");
    bodyFormData.append("Photo", "");
    bodyFormData.append("CreateNetwork.NetworkName", form.networkName);
    createNetwork(bodyFormData)
    .unwrap()
    .then((res)=>{
      if (res) {
        handleCloseModal()
      } else {
        //setErr(res.Message)
      }
      console.log(res, 'i am the res from creating netwrork')
    })
    .catch((err)=>{
      setErr(err.data.Message)
    })
  };

  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer>
        <BackArrow justifyContent alignItems>
          <div className="back">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Add Network</ModalTitle>
          </div>
        </BackArrow>
        {/* <BoxIcon>
          <CircleSpan width="120px" height="120px" background="#f3eeee">
            <img
              src={profileUrl || BeeLogo}
              alt="Company's logo"
              width={80}
              height={80}
              onError={imgError}
            />
          </CircleSpan>
          <Relative>
            <Link to={""}>Change</Link>
            <FileInput
              type="file"
              name="file"
              id="idCard"
              onChange={handleChange}
              accept=".png, .jpeg, .jpg"
            />
          </Relative>
        </BoxIcon> */}
        <Form height="100%">
          <div>
            <Label14 htmlFor="networkName">Network Name</Label14>
            <Input16
              type="text"
              placeholder="Adayemi"
              value={form.networkName}
              onChange={handleChange}
              name="networkName"
              id="networkName"
            />
          </div>
          <div>
            <Label14 htmlFor="email">Email</Label14>
            <Input16
              type="email"
              value={form.email}
              onChange={handleChange}
              name="email"
              id="email"
              placeholder="Adayemiadam@gmail.com"
            />
          </div>
          <div>
            <Label14 htmlFor="defaultPassword">Default Password</Label14>
            <Input16
              type="text"
              value={form.defaultPassword}
              onChange={handleChange}
              name="defaultPassword"
              id="defaultPassword"
              placeholder="Adam"
            />
          </div>
          {/* <div>
            <Label14 htmlFor="phoneNumber">Phone number</Label14>
            <Input16
              type="tel"
              value={form.phoneNumber}
              onChange={handleChange}
              name="phoneNumber"
              id="phoneNumber"
              placeholder="+000 000 000 0000"
            />
          </div> */}
        </Form>
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
        <Button
          text="Add Network"
          widthProps="10rem"
          height="40px"
          marginTop="auto"
          fontSizeProps="0.9rem"
          isLoading={creatingNetwork}
          onClick={handleProfileUpdate}
        />
      </ModalContainer>
    </MainContainer>
  );
};

export default AddNetwork;
