import React from "react";
import { Button } from "../../Reusable/Buttons/button";
import {
  AccountTitle,
  HeadingBox,
  ContentBox,
} from "../../Account/Account.styled";
import { Avatar, BtnGroup } from "../../Dashboard/Dashboard.styled";
import {
  useGetOneAdvertsQuery,
  useSetAdvertStatusMutation,
} from "../../../features/api/advertSlice";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { numberWithCommasWithoutDecimal } from "../../../helpers";
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import { DetailsProps } from "../../../types";

const Details: React.FC<DetailsProps> = ({ handleRejection }) => {
  const { advertId } = useParams();
  const navigate = useNavigate();

  const [setAdvertStatus, { isLoading: settingAdvertStatus }] =
    useSetAdvertStatusMutation();
  //Get the advert detail
  const { data: advertDetail, isLoading: gettingAdvert } =
    useGetOneAdvertsQuery(advertId as string);

  const handleApprove = () => {
    let data = {
      id: advertId as string,
      approve: true,
      reason: "",
    };

    setAdvertStatus(data)
      .unwrap()
      .then((res) => {
        toast.success("Advert approved successfully!")
        navigate("/home/adverts");
      })
      .catch((err) => {
        toast.error(err?.data?.Message || "Something went wrong")
      });
  };

  return (
    <div>
      <div>
        <HeadingBox>
          <AccountTitle>Details</AccountTitle>
          <span className="line"></span>
          {Number(advertDetail?.data?.status) ===  1  &&
          <BtnGroup className="grp-btn">
            <Button
              text="Reject"
              variant="outline"
              fontSizeProps="0.875rem"
              paddingProps="0.4rem 1.5rem"
              widthProps="auto"
              onClick={handleRejection}
            />
            <Button
              text="Accept"
              fontSizeProps="0.875rem"
              paddingProps="0.4rem 1.5rem"
              widthProps="auto"
              isLoading={settingAdvertStatus}
              onClick={handleApprove}
            />
          </BtnGroup>
          }
        </HeadingBox>
      </div>
      <ContentBox>
        <Avatar className="avatar">
          <span>
            {(advertDetail?.data?.user?.businesses[0]?.name || "  ")
              .slice(0, 2)
              .toUpperCase()}
          </span>
        </Avatar>
        <div className="business">
          <p className="heading">Business Name </p>
          <p className="details">
            {gettingAdvert ?
              <ScaleLoader
                loading={gettingAdvert}
                height={18}
                width={4}
                color={"#3d5170"}
              /> : advertDetail?.data?.user?.businesses[0]?.name}
          </p>
        </div>
        <div className="campign">
          <p className="heading">Campaign Name</p>
          <p className="details">{gettingAdvert ?
            <ScaleLoader
              loading={gettingAdvert}
              height={18}
              width={4}
              color={"#3d5170"}
            /> : advertDetail?.data?.name}</p>
        </div>
        <div className="total">
          <p className="heading">Total Amount spent</p>
          <p className="details">
          ₦
            {gettingAdvert ?
              <ScaleLoader
                loading={gettingAdvert}
                height={18}
                width={4}
                color={"#3d5170"}
              /> : numberWithCommasWithoutDecimal(
                advertDetail?.data?.budget_daily_cap || 0
              )}
          </p>
        </div>
        <p className="user">
          User since:{" "}
          {gettingAdvert ?
            <ScaleLoader
              loading={gettingAdvert}
              height={18}
              width={4}
              color={"#3d5170"}
            /> : moment(advertDetail?.data?.user?.created_at).format("DD MMM hh:mm")}
        </p>
      </ContentBox>
    </div>
  );
};

export default Details;
