import React, { useEffect, useRef, useState } from "react";
import TableMain from "../Tables/TableMain";
import {
  Box5,
  Form2,
  Input2,
  Label2,
} from "../Wallet/Wallet.styled";
import EmptyBox from "../Reusable/EmptyBox";
import { CardLoader } from "../Reusable/CardLoader";
import { useGetBusinessesQuery } from "../../features/api/businessesSlice";
// import { columnsAllBusinesses } from "../Tables/TableData";

import FundBusiness from "./FundBusiness";
import { createColumnHelper } from "@tanstack/react-table";
import { IBusinessess } from "../../features/api/businessesSliceTypes";
import { numberWithCommasWithoutDecimal } from "../../helpers";
import { AddButton } from "../Tables/Table.styled";
import moment from "moment";
import useOutsideClick from "../../hooks/useOutsideClick";

const greenColor = "#1A1A1A";
const AllBusinesses = () => {

  const [searchValue, setSearch] = useState<string>("");
  const [fundingModal, showFunding] = useState<boolean>(false);
  const [selectedBusiness, setSelectedBusiness] = useState<IBusinessess>();


  // Get Businesses
  const { data: allBusinessesData, isLoading: gettingBusinesses, isFetching: fetchingBusinesses, refetch: refetchBusinesses } = useGetBusinessesQuery({ search: searchValue });

  useEffect(() => {
    if (searchValue === "") {
      refetchBusinesses()
    }
  }, [searchValue])

  const columnBusinessHelper = createColumnHelper<IBusinessess>();

  const columnsAllBusinesses = [
    columnBusinessHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Name",
    }),
    columnBusinessHelper.accessor("user", {
      header: "Email",
      cell: (val) => {
        const user = val.getValue();
        return <p>{user?.email}</p>;
      },
    }),
    columnBusinessHelper.accessor("wallet_balance", {
      header: "Wallet Balance",
      cell: (val) => {
        const walletBalance = val.getValue();
        return <p>₦{numberWithCommasWithoutDecimal(walletBalance || 0)}</p>;
      },
    }),
    columnBusinessHelper.accessor("wallet_balance", {
      header: "",
      cell: (val) => {
        return <AddButton onClick={()=> {showFunding(true); setSelectedBusiness(val.row?.original)}}> Add</AddButton>;
      },
    }),
    columnBusinessHelper.accessor("created_at", {
      header: "Date Joined",
      cell: (val) => {
        const dateTime = val.getValue();
        return <p>{moment(dateTime).format("DD MMM hh:mm")} WAT</p>;
      },
    }),
  ];
  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    showFunding(false)
  };
  useOutsideClick(modalRef, handleCloseModal);


 
  return (
    <>
      <Form2>
        <Box5>
          <Label2 htmlFor="search">Search</Label2>
          <Input2
            type="text"
            id="search"
            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setSearch(e.target.value)}
            value={searchValue}
            placeholder="Name or email"
            inputWidth="100%"
          />
        </Box5>
      </Form2>
      {gettingBusinesses || fetchingBusinesses ? <CardLoader /> :
        (allBusinessesData?.data?.length || 0) > 0 ? (
          <div>
            <div style={{ textAlign: "left" }}>
              <TableMain
                defaultData={allBusinessesData?.data || []}
                columns={columnsAllBusinesses}
                greenColor={greenColor}
              />
            </div>
            {/* {requestBusiness?.length > 10 && (
            <Walletbtn>
              <button>Load more</button>
            </Walletbtn>
          )} */}
          </div>
        ) : (
          <EmptyBox message="No business found!" />
        )}

      <FundBusiness showRole={fundingModal} modalRef={modalRef} handleCloseRole={handleCloseModal}  selectedBusiness={selectedBusiness as IBusinessess} />
    </>
  );
};

export default AllBusinesses;
