import { ReactComponent as GraphIcon } from "../assets/icons/HomeIcons/graph.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/HomeIcons/profile.svg";
import { ReactComponent as SpeakerIcon } from "../assets/icons/HomeIcons/speaker.svg";
import { ReactComponent as NetworkIcon } from "../assets/icons/HomeIcons/networks.svg";
import { ReactComponent as BusinessIcon } from "../assets/icons/HomeIcons/business.svg";
import {ReactComponent as WalletIcon} from "../assets/icons/HomeIcons/wallet.svg";

export const SIDEBAR_DATA = [
  {
    icon: <GraphIcon />,
    title: "Dashboard",
    to: "/home/dashboard",
    path: "dashboard",
  },
  {
    icon: <SpeakerIcon />,
    title: "Adverts",
    to: "/home/adverts",
    path: "adverts",
  },
  {
    icon: <WalletIcon/>,
    title: "Wallet",
    to: "/home/wallet",
     path: "wallet"
  },
  {
    icon: <ProfileIcon />,
    title: "My Account",
    to: "/home/account",
    path: "account",
  },
];

export const ADMIN_NAVBAR_DATA = [
  {
    icon: <GraphIcon />,
    title: "Dashboard",
    to: "/home/dashboard",
    path: "dashboard",
  },
  {
    icon: <SpeakerIcon />,
    title: "Adverts",
    to: "/home/adverts",
    path: "adverts",
  },
  {
    icon: <BusinessIcon />,
    title: "Businesses",
    to: "/home/businesses",
    path: "businesses",
  },
  {
    icon: <NetworkIcon />,
    title: "Networks",
    to: "/home/networks",
    path: "networks",
  },
  {
    icon: <WalletIcon/>,
    title: "Wallet",
    to: "/home/wallet",
     path: "wallet"
  },
  {
    icon: <ProfileIcon />,
    title: "My Account",
    to: "/home/account",
    path: "account",
  },
];
