import React, { useState } from "react";
import {
  Container,
  Input,
  Label,
  Forgot,
  Title,
  Alternative,
  CreateLink,
  WrapperLeft,
  InnerContainer,
  Description,
  Form,
  // ButtonGoogle,
  ErrorMsg,
} from "./styles/Container.styled";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Button } from "../Reusable/Buttons/button";
import { useLogInUserMutation } from "../../features/api/authSlice";
import WrapperRightSide from "./WrapperRightSide";
import LoginTwoFAVerifcation from "./logintwoFA";
import {LoginResponse} from "../../features/api/authTypes"

const INITIAL_STATE = {
  email: "",
  password: "",
};
const Login: React.FC = () => {
  const navigate = useNavigate();
  const [visibilityStatus, toggleVisibility] = useState<boolean>(false);
  const [form, setForm] = useState(INITIAL_STATE);
  const [errMsg, setErr] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [userInfo, setUserInfo] = useState<LoginResponse>();

  const [logIn, { isLoading }] = useLogInUserMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!visibilityStatus && e.target.name === "password") {
      // If visibilityStatus is true, allow only numeric input and limit to 4 characters
      if (!/^\d*$/.test(value) || value.length > 4) {
        // If input contains non-numeric characters or exceeds length 4, don't update the form state
        return;
      }
    }
    setForm({ ...form, [e.target.name]: value });
  };

  function submitUser() {
    if (!form.email) {
      setErr("Please enter a valid email");
    } else if (!form.password || form.password.length > 4) {
      setErr("Please enter a valid password");
    } else {
      setErr("");
      let data = {
        username: form.email,
        password: form.password,
        fcm_token: "",
        user_type: 1
      };
      logIn(data)
        .unwrap()
        .then((data) => {
          if (data) {
            setUserInfo(data);
            if (data?.user?.is_two_factor_enabled) {
              setStep(1)
            } else {
              handleLoginAuth()
            }
          }
        })
        .catch((error) => {
          if (error?.data?.Message) {
            setErr(error.data.Message);
          } else {
            setErr("An error occurred.");
          }
        });
    }
  }

  const handleLoginAuth = () => {
    if(userInfo){
      localStorage.setItem("token", JSON.stringify(userInfo?.token));
      localStorage.setItem("user", JSON.stringify(userInfo?.user))
      navigate("/home");
    }
   
  }

  return (
    <>
      {step === 0 ?
        <Container>
          <WrapperLeft>
            <InnerContainer gap="1.875rem">
              <div>
                <Logo className="logo" width={115} height={25.578} />
                <div className="">
                  <Title>Log In</Title>
                  <Description>
                    Hello dear, Log In to your Ads dashboard and start managing ads
                    on your device.
                  </Description>
                </div>
                {/* <ButtonGoogle>
              <img src="/Assets/icon/google.svg" alt="" className="googleImg" />
              <span className="button-text">Sign in with Google</span>
            </ButtonGoogle> */}
              </div>

              {/* <Alternative> Or</Alternative> */}
              <Form>
                <div>
                  <Label htmlFor="email" textAlign="left">
                    Email Address
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={form.email}
                    onChange={handleChange}
                    placeholder="Beemobile@gmail.com"
                  />
                </div>
                <div className="formControlPassword">
                  <Label htmlFor="password" textAlign="left">
                    Enter Password
                  </Label>
                  <Input
                    type={visibilityStatus ? "number" : "password"}
                    name="password"
                    id="password"
                    value={form.password}
                    onChange={handleChange}
                    maxLength={4}
                  />
                  <span
                    onClick={() => toggleVisibility(!visibilityStatus)}
                    className="eye"
                  >
                    {visibilityStatus ? (
                      <BsFillEyeFill className="" />
                    ) : (
                      <BsEyeSlashFill className="" />
                    )}
                  </span>
                </div>
              </Form>
              {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}

              <Forgot>
                <Link to={"/reset"} className="link">
                  Forgot Your pin? <span className="reset"> Reset</span>
                </Link>
              </Forgot>

              <Button
                onClick={() => submitUser()}
                text="Login"
                isLoading={isLoading}
                height="40px"
              />
              <CreateLink className="">
                Don't have an account yet?
                <Link to={"/signup"} className="create">
                  <span> Create Account </span>
                </Link>
              </CreateLink>
            </InnerContainer>
          </WrapperLeft>
          <WrapperRightSide />
        </Container> : <LoginTwoFAVerifcation email={form.email} setStep={setStep} handleLoginAuth={handleLoginAuth} />
      }
    </>
  );
};

export default Login;
