import {
  DeleteResponse,
  DeleteSingleBankAccountResponse,
  SuccessResponse,
} from "./accountSliceTypes";
import { apiSlice } from "./apiSlice";

const accountSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    //Delete bank account
    deleteSingleBankAccount: build.mutation<
      DeleteSingleBankAccountResponse,
      string
    >({
      query: (id) => ({
        url: `/bank-accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Account"],
    }),

    //Update Profile information
    updateProfile: build.mutation<SuccessResponse, Partial<FormData>>({
      query: (payload) => ({
        url: `/users`,
        method: "PUT",
        body: payload,
      }),
    }),

    //Delete a user
    deleteUser: build.mutation<DeleteResponse, Partial<string>>({
      query: (password) => ({
        url: `/users?password=${password}`,
        method: "DELETE"
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useDeleteSingleBankAccountMutation,
  useUpdateProfileMutation,
  useDeleteUserMutation,
} = accountSlice;
