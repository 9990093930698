import  { SetStateAction, useState } from "react";
import {
  Box5,
  Form2,
  Input2,
  Label2,
} from "../Wallet/Wallet.styled";
import TableMain from "../Tables/TableMain";
import EmptyBox from "../Reusable/EmptyBox";
import { CardLoader } from "../Reusable/CardLoader";
import { columnsBusinesses } from "../Tables/TableData";
import { useGetBusinesseskycQuery } from "../../features/api/businessesSlice";

const greenColor = "#1A1A1A";
const Requests = () => {

  const [searchValue, setSearch] = useState<string>("");

  // business kyc
  const { data: businesseskyc, isLoading: gettingBusinessKyc } = useGetBusinesseskycQuery({
    status: "9",
    search: searchValue,
  });

  return (
    <>
      <Form2>
        <Box5>
          <Label2 htmlFor="search">Search</Label2>
          <Input2
            type="text"
            id="search"
            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setSearch(e.target.value)}
            value={searchValue}
            placeholder="Name or email"
            inputWidth="100%"
          />
        </Box5>
      </Form2>
      {gettingBusinessKyc ? <CardLoader /> : 
      (businesseskyc?.data?.length || 0) > 0 ? (
        // <div style={{ textAlign: "left" }}>
        //   <TableMain
        //     defaultData={defaultDataRequest}
        //     columns={columnsRequestBusiness}
        //     greenColor={greenColor}
        //   />
        // </div>
        <div style={{ textAlign: "left" }}>
          <TableMain
            defaultData={businesseskyc?.data || []}
            columns={columnsBusinesses}
            greenColor={greenColor}
          />
        </div>
      ) : (
        <EmptyBox message="No pending approval" />
      )}
    </>
  );
};

export default Requests;
