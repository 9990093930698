import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRouteProps {
  redirectPath: string;
  children: React.ReactNode;
}

const ProtectedRoute = ({
  redirectPath,
  children,
}: PrivateRouteProps): JSX.Element | null => {
  const token = localStorage.getItem("token") || "";
  //   const token = JSON.parse(localStorage.getItem("token") || "{}");
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
