import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  auth: boolean;
}

const initialState: AuthState = {
  auth: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    //logIn
    logIn(state, action: PayloadAction<boolean>) {
      state.auth = action.payload
    },

    //forgot password

    //reset password
    


  },
});

export const { logIn } = authSlice.actions;
export default authSlice.reducer;
