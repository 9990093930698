import React from "react";
import { RoleInnerBox, RoleBoxMain } from "../../Dashboard/Dashboard.styled";
import { FlexBox } from "../../Authentication/styles/Container.styled";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { ADBox2, ImageAD } from "../Adverts.styled";
import { useGetOneAdvertsQuery } from "../../../features/api/advertSlice";
import { useParams } from "react-router-dom";
import { ImageGalleryProps } from "../../../types";

const ImageGallery: React.FC<ImageGalleryProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
  selectedImage,
  handlePreviousImage,
  handleNextImage,
  handleImage,
}) => {


  const { advertId } = useParams();


  //Get the advert detail
  const { data: advertDetail } = useGetOneAdvertsQuery(advertId as string);

  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <RoleInnerBox widthBox="80%" background="#1a1a1a">
        <ADBox2>
          <p style={{ color: "white" }}>Preview</p>
          <button className="cancelbtn" onClick={handleCloseRole}>
            x
          </button>
        </ADBox2>
        <FlexBox
          flexDirection
          gap="1rem"
          style={{ position: "relative", marginBottom: "42px" }}
        >
          <button onClick={handlePreviousImage} className="carolbtn rotate">
            <Arrow />
          </button>

          <ImageAD
            src={selectedImage}
            alt="Selected Image"
            className="selected-image"
            width="346.84px"
            height="407px"
          />
          <button onClick={handleNextImage} className="carolbtn">
            <Arrow />
          </button>
        </FlexBox>
        <div style={{ marginTop: "1.5rem" }}>
          <FlexBox flexDirection gap="1.5rem">
            {advertDetail?.data?.advert_pictures?.map((adPictures) => {
              return (
                <ImageAD onClick={() => handleImage(adPictures?.picture_url)} height="30px" src={adPictures?.picture_url} alt="Advert Picture" />
              )
            })}
          </FlexBox>
        </div>
      </RoleInnerBox>
    </RoleBoxMain>
  );
};

export default ImageGallery;
