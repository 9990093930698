import React from "react";
import DeleteIcon from "../../assets/icons/delete.svg";
import FallbackIcon from "../../assets/icons/fallback.svg";
import { Wrapper } from "./Account.styled";
import ImageWithFallback from "../Reusable/FallbackImage";
import { IconLoader } from "../Reusable/loaders";
import { SingleBeneficiaryprorps } from "./type";

const SingleBeneficiary = ({
  companyLogo,
  service,
  beneficiaryName,
  accountNumber,
  handleDelete,
}: SingleBeneficiaryprorps) => {
  // Check if companyLogo is empty
  // const isCompanyLogoEmpty = !companyLogo;
  function imgError(e: any) {
    e.target.src = FallbackIcon;
  }

  return (
    <Wrapper>
      {/* <ImageWithFallback
        src={isCompanyLogoEmpty ? FallbackIcon : companyLogo}
        alt="Bank"
        fallbackSrc={FallbackIcon}
      /> */}
      <img src={companyLogo} alt="" width={20} height={20} onError={imgError} />
      <div>
        <h3>
          {service}{" "}
          <span>
            (
            {accountNumber.toString().slice(0, 6) +
              "**" +
              accountNumber.toString().slice(8)}
            )
          </span>
        </h3>
        <p>{beneficiaryName}</p>
      </div>
      {/* {isDeleteIconLoading ? (
        <IconLoader loading={isDeleteIconLoading} />
      ) : ( */}
      <button onClick={handleDelete}>
        <img src={DeleteIcon} alt="Delect Icon" />
      </button>
      {/* )} */}
    </Wrapper>
  );
};

export default SingleBeneficiary;
