import { apiSlice } from "./apiSlice";
import {
  BusinessKYCResponse,
  BusinessStatusRequest,
  BusinessessResponse,
  BusinesesRequest,
  BusinesesKYCRequest,
} from "./businessesSliceTypes";

const businessesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // Get all businessess
    getBusinesses: build.query<BusinessessResponse, BusinesesRequest>({
      query: ({ search }) =>
        `/businesses?search=${search}&pageIndex=${0}&pageSize=${100}`,
      providesTags: ["Businesses"]
    }),

    // Get all businessesskyc
    getBusinesseskyc: build.query<BusinessKYCResponse, BusinesesKYCRequest>({
      query: ({ status, search }) =>
        `/businesses/kyc?status=${status}&search=${search}&pageIndex=${0}&pageSize=${20}`, 
        providesTags: ["Kyc"]
    }),

    //Get one business
    getOneBusiness: build.query<any, string>({
      query: (id) => `/businesses/`,
    }),

    // set business status
    setBusinessStatus: build.mutation<any, BusinessStatusRequest>({
      query: (payload) => ({
        url: `/businesses/kyc`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Kyc"]
    }),
  }),
});

export const {
  useGetBusinessesQuery,
  useGetBusinesseskycQuery,
  useGetOneBusinessQuery,
  useSetBusinessStatusMutation,
} = businessesSlice;
