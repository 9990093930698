
import { apiSlice } from "./apiSlice";
import { DashboardOverviewResponse, MinimalBidRequest, MinimalBidResponse, RateRequest, RateResponse } from "./dashboardSliceTypes";

const dashboardSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        //Get Network Dashboard Overview 
        getDashboardOverview: build.query<DashboardOverviewResponse, number>({
            query: (period) => `/networks/overview?period=${period}`,
            providesTags: ['Overview']
        }),

        //Get Mininal Bid
        getMinimalBid: build.query<MinimalBidResponse, void>({
            query: () => `/adverts/bid/defaultBid`,
            providesTags: ['Bid']
        }),

        //Set network rate 
        updateMinimalBid: build.mutation<RateResponse, MinimalBidRequest>({
            query: (payload) => ({
                url: '/adverts/bid/defaultBid',
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["Bid"]
        }),

        //Set network rate 
        setNetworkRate: build.mutation<RateResponse, RateRequest>({
            query: (payload) => ({
                url: '/networks/setRate',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Overview"]
        })
    }),
    overrideExisting: true,
})


export const { useGetDashboardOverviewQuery, useSetNetworkRateMutation, useUpdateMinimalBidMutation, useGetMinimalBidQuery} = dashboardSlice