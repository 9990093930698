import React, { useEffect, useState } from "react";
import { GraphTab, Section } from "../Dashboard/Dashboard.styled";
import MyDetails from "./MyDetails";
import Security from "./Security";
import Beneficiaries from "./Beneficiaries";
import PaymentAccounts from "./PaymentAccounts";
import { UserDetail } from "../../features/api/authTypes";

const Account: React.FC = () => {
  const [option, setOption] = useState<number>(0);
  const [userDetail, setUser] = useState<UserDetail>();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  }, []);


  return (
    <Section>
      <GraphTab widthGraphBox="fit-content">
        <button
          onClick={()=>setOption(0)}
          className={`${option === 0 && "active"}`}
        >
          My Details
        </button>
        <button
          onClick={()=> setOption(1)}
          className={`${option === 1 && "active"}`}
        >
          Security
        </button>
        <button
          onClick={()=> setOption(2)}
          className={`${option === 2 && "active"}`}
        >
          Manage Beneficiaries
        </button>
        {userDetail?.role_id === 998 && <button
          onClick={()=> setOption(3)}
          className={`${option === 3 && "active"}`}
        >
          Payment Accounts
        </button>}
      </GraphTab>
      {option === 0 ? <MyDetails/> : option === 1 ? <Security /> : option === 2 ? <Beneficiaries /> : <PaymentAccounts/>  } 
    </Section>
  );
};

export default Account;
