import styled from "styled-components";
import {
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_YELLOW,
  COLOR_GRAY2,
  COLOR_DARK_GRAY,
  COLOR_BLUE,
  COLOR_RED,
} from "../../../styles/themes/colors";
import { BackArrowProps, LabelProps } from "../../../types";
import { FlexProps, GapChange, TextProps } from "../../Reusable/types";

export const Container = styled.div`
  display: flex;
  /* align-items: center; */
  color: ${COLOR_BLACK};
  background-color: ${COLOR_WHITE};
  min-height: 100vh;
`;

export const ContainerVerify = styled(Container)`
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  gap: 3.75rem;
  padding: 1.875rem;

  .details {
    text-align: center;
  }

  .details__para {
    margin-bottom: 0;
  }

  .details__span {
    color: ${COLOR_YELLOW};
  }

  .success-link {
    width: 100%;
  }
`;

export const FlexBox = styled.div<FlexProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) =>
    props.flexDirection === true ? "row" : "column"};
  gap: ${(props) => (!props.gap ? 0 : props.gap)};
  .welcome {
    text-align: center;
  }

  .email {
    color: ${(themeProps) => themeProps.theme.colors.colorYellow};
  }

  .desc {
    font-weight: 400;
    font-size: 1.0625rem;
    line-height: 1.5;
    color: #3e3e3e;
    margin-bottom: 0;
  }

  .downbtn {
    width: 20.94px;
    height: 5.18px;
    background: rgba(207, 168, 9, 0.2);
    border-radius: 23.3301px;
  }

  .downbtn.active {
    background: #e5b910;
  }

  .carolbtn {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .carolbtn.rotate {
    transform: rotate(180deg);
  }

  .big-img {
    width: 17.375rem;
    height: 12.125rem;
    top: -0.175rem;
    position: absolute;
    z-index: 2;
    @media (min-width: 900px) {
      width: 24.375rem;
      height: 17.125rem;
      top: -0.225rem;
    }
    @media (min-width: 1220px) {
      width: 29.375rem;
      height: 21.125rem;
      top: -1.625rem;
    }
  }
  .small-img {
    width: 15.875rem;
    height: 15.125rem;
    position: absolute;
    top: -1.175rem;
    @media (min-width: 900px) {
      width: 21.875rem;
      height: 17.125rem;
      top: -1.325rem;
    }
    @media (min-width: 1220px) {
      width: 25.875rem;
      height: 21.125rem;
      top: -2.875rem;
    }
  }
`;

export const Image = styled.img`
  /* width: 522px;
  height: 338px; */
  width: 32.625rem;
  height: 21.125rem;
  z-index: 3;
  /* 768px */
  @media screen and (max-width: 1200px) {
    width: 85% !important;
    height: 85% !important;
  }
`;

export const InnerContainer = styled.div<GapChange>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 480px;
  padding: 2rem;
  gap: ${(props) => (!props.gap ? 0 : props.gap)};
  align-items: ${(props) => (props.alignItems === true ? "center" : "")};

  .logo {
    /* width: 7.5rem;
    height: 1.9rem; */
    margin-bottom: 3rem;
  }

  .success-link {
    width: 100%;
  }
`;

export const WrapperLeft = styled.div`
  display: flex;
  flex: 0 0 calc(100%);
  max-width: calc(100%);
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;

  /*  26.625em */
  @media (min-width: 426px) {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
  }
`;
export const WrapperRight = styled.div`
  display: none;

  /* 426px */
  @media (min-width: 426px) {
    display: flex;
    justify-content: center;
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
    background-color: #25346a;
    color: white;
    padding: 6rem 0 2rem;
    min-height: 100vh;

    .main-right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 3.5rem;
      height: 100%;
    }

    .piclogin {
      width: 100%;
      padding: 0 1.5rem;
    }
  }
`;

export const Title = styled.h3<TextProps>`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.875rem;
  font-size: clamp(1.5rem, 1.3rem + 0.76vw, 1.875rem);
  line-height: 1.25;
  margin-bottom: ${(props) => props.marginBottom || "1.625rem"};

  .success__title {
    margin-bottom: 0;
  }
`;

export const Description = styled.p<TextProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  font-size: clamp(1rem, 0.95rem + 0.19vw, 1rem);
  line-height: 1.5;
  color: #3e3e3e;
  margin-bottom: ${(props) => props.marginBottom || "1.625rem"};
`;

export const ButtonGoogle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.77rem;
  border: 1.5px solid ${COLOR_BLUE};
  border-radius: 1.625rem;
  width: 100%;
  padding: 0.875rem;
  background-color: ${COLOR_WHITE};
  cursor: pointer;

  .button-text {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: ${COLOR_BLUE};
  }

  .googleImg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const LoginButton = styled(ButtonGoogle)`
  border-radius: 1.625rem;
  background-color: ${COLOR_BLUE};
  color: ${COLOR_WHITE};
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
`;

export const CreateLink = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.46;
  text-align: center;
  color: ${COLOR_DARK_GRAY};

  .create {
    color: ${COLOR_YELLOW};
  }
`;

export const Alternative = styled.p`
  text-align: center;
`;

export const Form = styled.form<TextProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height || "auto"};

  > div:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .formControlPassword {
    position: relative;
  }

  .formControlPassword.m40 {
    margin-bottom: 2.5rem;
  }

  .formControlPassword.m60 {
    margin-bottom: 3.75rem;
  }
  .err {
    font-size: 0.8rem;
    color: ${COLOR_RED};
  }

  .eye {
    color: ${COLOR_YELLOW};
    position: absolute;
    right: 1rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

export const Input = styled.input`
  border: none;
  border-bottom: 2px solid #C4C4C4;
  background: transparent;
  width: 100%;
  padding-bottom: 0.45rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375;
  color: #1d1d1d;

  &[type="password"] {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    letter-spacing: 0.5em;
  }

  &:focus {
    outline: none;
  }
`;

export const Label = styled.label<LabelProps>`
  // display: block;
  // font-weight: 500;
  // font-size: 1rem;
  // line-height: 1.33;
  // color: ${COLOR_BLUE};
  // margin-bottom: 0.5rem;
  // text-align: ${(props) => (!props.textAlign ? "center" : props.textAlign)};

  color: var(--secondary-dark-grey, #6B6B6B);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

export const Terms = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: -0.75rem;
  label {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.46;
    color: black;
    margin-bottom: 0;
  }

  .reset {
    color: ${COLOR_YELLOW};
  }
`;

export const Forgot = styled.div`
  text-align: right;
  font-style: italic;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.46;
  margin-top: -0.975rem;
  color: ${COLOR_YELLOW};

  .link {
    transition: 0.9s ease-in-out;
    &:hover {
      border-bottom: 1px solid ${COLOR_YELLOW};
    }
  }
  .reset {
    color: ${COLOR_GRAY2};
  }
`;

export const RightTitle = styled.h4`
  font-weight: 600;
  font-size: 1.25rem;
  // font-size: 1.125rem;
  // font-size: clamp(1.125rem, 0.375rem + 1.25vw, 1.5rem);
  line-height: 1.44;
  text-align: center;
  max-width: 17.4375rem;
  margin: 0 auto;
  margin-bottom: 1.4rem;
`;

export const RightSubtitle = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.44;
  text-align: center;
  max-width: 20.625rem;
  color: #c4c4c4;
  height: 2.5rem;
`;

export const InputNum = styled.input`
  width: 3.699rem;
  height: 3.699rem;
  background: #f5f5f5;
  border-radius: 0.75rem;
  border: 1px solid #f5f5f5;

  font-family: "General Sans";
  font-weight: 500;
  font-size: 1.54rem;
  line-height: 1.4;
  color: #25346a;
  padding: 1.3rem;

  &.inputGrey {
    background: #f5f5f5;
  }

  /* Styles for the next input box */
  &.borderBlue {
    border: 2px solid #25346a;
  }

  &.inputClr {
    background: rgba(229, 185, 16, 0.1);
  }
`;

export const InputBox = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.79rem;
`;

export const BackArrow = styled.div<BackArrowProps>`
  .back {
    display: flex;
    cursor: pointer;
    align-items: ${(props) =>
      props.alignItems === true ? "flex-start" : "center"};
    justify-content: ${(props) =>
      props.justifyContent === true ? "flex-start" : "center"};
    gap: 0.625rem;
  }

  .back-wallet {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.4;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 0;
    }
  }

  .arrow-left {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  .btn-reset {
    background-color: transparent;
    border: none;
  }
`;

export const SuccessBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.875rem;
  flex-direction: column;

  .success__title {
    margin-bottom: 0;
    color: #00c087;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .success__para {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5;
    color: ${COLOR_BLACK};
    margin-bottom: 0;
  }

  .success__para2 {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.5;
    color: #666666;
    margin-bottom: 0;
  }
`;

export const ErrorMsg = styled.p`
  color: ${COLOR_RED} !important;
  font-size: 0.75rem;
  margin: 1rem;
`;

export const MarginTop = styled.div<TextProps>`
  margin-top: ${(props) => props.marginTop || "3rem"};
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  top: 10%;
  right: 10%;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  display: flex;
  gap: 10px;
  align-items: center;
}
`;