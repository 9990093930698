import React, { useEffect, useState } from "react";
import {
  BackArrow,
  ErrorMsg,
  Form,
  Input,
  Label,
} from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
  Avatar,
} from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { Link } from "react-router-dom";
import BeeLogo from "../../assets/icons/Account/company-logo.svg";
import { BoxIcon, FileInput, Relative } from "./Account.styled";
import { useUpdateProfileMutation } from "../../features/api/accountSlice";
import toast from "react-hot-toast";
import { UserDetail } from "../../features/api/authTypes";
import { CircleSpan } from "../Reusable/Reuseable.styled";
import { ModalMainEditProfileProps } from "../../types";

const EditProfile: React.FC<ModalMainEditProfileProps> = ({
  show,
  handleCloseModal,
  modalRef,
  updateUserFromLocalStorage,
}) => {
  // Get user
  const [userDetail, setUser] = useState<UserDetail>();

  const INITIAL_STATE = {
    companyName: "",
    // email: "",
    phoneNumber: "",
  };

  const [form, setForm] = useState(INITIAL_STATE);
  const [profileUrl, setprofileUrl] = useState<string>("");
  const [photoFile, setphotoFile] = useState<File>();
  const [errMsg, setErr] = useState<string>("");

  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const selectedFile = e.target.files?.[0];
    setphotoFile(selectedFile)
    if (selectedFile) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setprofileUrl(blobUrl);
    }
    setForm({ ...form, [e.target.name]: value });
  };

  // fallback image
  function imgError(e: any) {
    e.target.src = BeeLogo;
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  }, []);

  useEffect(() => {
    if (userDetail) {
      setForm({
        ...form,
        companyName: userDetail.networks[0]?.name,
        phoneNumber: userDetail.phone_number || "",
      });
      setprofileUrl(userDetail.photo || "");
    }
  }, [userDetail]);

  // const handleProfileUpdate = async () => {
  //   setLoading(true);

  //   let data = {
  //     first_name: "",
  //     last_name: "",
  //     photo_number: form.phoneNumber,
  //     network_name: form.companyName,
  //     business_name: "",
  //     photo: profileUrl,
  //     // email: form.email,
  //   };

  //   await updateProfile(data)
  //     .unwrap()
  //     .then((data) => {
  //       toast.success("Profile updated successfully", {
  //         position: "top-right",
  //       });
  //     })
  //     .catch((error) => {
  //       if (error?.data?.Message) {
  //         setErr(error.data.Message);
  //       } else {
  //         setErr(`Error updating profile`);
  //       }
  //       toast.error("Error updating profile", {
  //         position: "top-right",
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //       setForm(INITIAL_STATE);
  //     });
  // };

  const handleProfileUpdate = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("FirstName", form.companyName);
    bodyFormData.append("LastName", "");
    bodyFormData.append("PhoneNumber", form.phoneNumber);
    bodyFormData.append("UpdateNetwork.NetworkName", form.companyName);
    bodyFormData.append("UpdateBusiness.BusinessName", "");
    bodyFormData.append("Photo", photoFile ? photoFile : profileUrl);
    updateProfile(bodyFormData)
      .unwrap()
      .then((res) => {
        if (res.success) {
          const newUserObj = { ...userDetail };
          if (newUserObj && newUserObj.networks && newUserObj.networks[0]) {
            newUserObj.networks[0].name = form.companyName;
          }
          if (newUserObj) {
            newUserObj.phone_number = form.phoneNumber;
            newUserObj.photo = profileUrl;
          }
          localStorage.setItem("user", JSON.stringify(newUserObj));
          updateUserFromLocalStorage();
          // setForm(INITIAL_STATE);
          handleCloseModal();
          toast.success("Profile Updated Successfully");
        } else {
          setErr(res.Message);
        }
      })
      .catch((error) => {
        setErr(error?.data?.Message || "An error occured");
      });
  };

  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer>
        <BackArrow justifyContent alignItems>
          <div className="back">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Edit Profile</ModalTitle>
          </div>
        </BackArrow>
        <BoxIcon>
          <CircleSpan width="120px" height="120px" background="#f3eeee">
            {profileUrl ? (
              <img
                src={profileUrl || ""}
                alt="Company's logo"
                width={80}
                height={80}
                onError={imgError}
                className="image-round"
              />
            ) : (
              <Avatar className="image-round">
                <p>{form.companyName?.slice(0, 2).toUpperCase() || ""}</p>
              </Avatar>
            )}
          </CircleSpan>
          <Relative cursor="pointer" style={{ cursor: "pointer" }}>
            <Link to={""}>Change</Link>
            <FileInput
              type="file"
              name="file"
              id="idCard"
              onChange={handleChange}
              accept=".png, .jpeg, .jpg"
            />
          </Relative>
        </BoxIcon>
        <Form>
          <div>
            <Label htmlFor="companyName" textAlign="left">
              Company Name
            </Label>
            <Input
              type="text"
              value={form.companyName}
              onChange={handleChange}
              name="companyName"
              id="companyName"
            />
          </div>
          {/* <div>
            <Label htmlFor="email" textAlign="left">
              Email{" "}
            </Label>
            <Input
              type="email"
              value={form.email}
              onChange={handleChange}
              name="email"
              id="email"
            />
          </div> */}
          <div>
            <Label htmlFor="phoneNumber" textAlign="left">
              Phone number
            </Label>
            <Input
              type="tel"
              value={form.phoneNumber}
              onChange={handleChange}
              name="phoneNumber"
              id="phoneNumber"
            />
          </div>
        </Form>
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
        <Button
          text="Save"
          widthProps="30%"
          height="40px"
          marginTop="auto"
          onClick={handleProfileUpdate}
          isLoading={isLoading}
        />
      </ModalContainer>
    </MainContainer>
  );
};

export default EditProfile;
