import React from "react";
import { Walletbtn } from "../Wallet/Wallet.styled";
import { columnsAd } from "../Tables/TableData";
import TableMain from "../Tables/TableMain";
// import TableMaincopy from "../Tables/TableMaincopy";
import { Advert } from "./types";
import EmptyBox from "../Reusable/EmptyBox";

interface ActiveAds {
  activeAdverts: Advert[];
}

const Active: React.FC<ActiveAds> = ({
  activeAdverts,
}) => {
  const greenColor = "#1A1A1A";
  return (
    <div>
      {activeAdverts.length > 0 ? (
        <>
          <div style={{ textAlign: "left" }}>
            <TableMain
              defaultData={activeAdverts || []}
              columns={columnsAd}
              greenColor={greenColor}
            />
            {/* <TableMaincopy
          defaultData={defaultDataAd}
          columns={columnsAd}
          greenColor={greenColor}
        /> */}
          </div>
          {/* <Walletbtn>
            <button>Load more</button>
          </Walletbtn> */}
        </>
      ) : (
        <EmptyBox message="No active adverts yet!" />
      )}
    </div>
  );
};

export default Active;
