import React, { MouseEvent, useState } from "react";
import { RoleInnerBox, RoleBoxMain, BtnGroup, Box7 } from "./Dashboard.styled";
import { Button, ButtonReset } from "../Reusable/Buttons/button";
import {
  Box6,
  Box8,
  Input2,
  Label2,
  WalletForm,
} from "../Wallet/Wallet.styled";
import ArrowYellow from "../../assets/icons/Wallet/arrow-yellow.svg";
import { BtnReset } from "../Businesses/business.styled";
import { Relative } from "../Account/Account.styled";
import { SetRoleProps } from "../../types";

const FilterGroupCampaign: React.FC<SetRoleProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
}) => {
  const [isCampaignOpen, setIsCampaignOpen] = useState(false);
  const [isDurationOpen, setIsDurationOpen] = useState(false);

  const handleDuration = () => {
    setIsDurationOpen(!isDurationOpen);
  };

  const handleCampaign = () => {
    setIsCampaignOpen(!isCampaignOpen);
  };

  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <RoleInnerBox padding="1rem" widthBox="auto">
        <WalletForm>
          <Box6>
            <Label2 htmlFor="filter">Duration</Label2>
            <Relative>
              <Box8>
                <Input2
                  type="text"
                  id="search"
                  placeholder="Months"
                  inputWidth="100%"
                />
                <BtnReset className="select-btn" onClick={handleDuration}>
                  <img src={ArrowYellow} alt="" className="select-img" />
                </BtnReset>
              </Box8>
              {isDurationOpen && (
                <Box7>
                  <ul>
                    <li>All</li>
                    <li>Last week</li>
                    <li>Last month</li>
                    <li>Last 2 months</li>
                    <li>Last year</li>
                  </ul>
                </Box7>
              )}
            </Relative>
          </Box6>
          <Box6>
            <Label2 htmlFor="filter">Campaign</Label2>
            <Relative>
              <Box8>
                <Input2
                  type="text"
                  id="search"
                  placeholder="Campaign1"
                  inputWidth="100%"
                />
                <BtnReset className="select-btn" onClick={handleCampaign}>
                  <img src={ArrowYellow} alt="" className="select-img" />
                </BtnReset>
              </Box8>
              {isCampaignOpen && (
                <Box7>
                  <ul>
                    <li>All</li>
                    <li>Campaign 1</li>
                    <li>Campaign 2</li>
                    <li>Campaign 3</li>
                    <li>Campaign 4</li>
                  </ul>
                </Box7>
              )}
            </Relative>
          </Box6>
          <BtnGroup>
            <Button
              text="Apply filter"
              fontSizeProps="0.875rem"
              paddingProps="0.47rem"
            />
            <Button
              text="Cancel"
              variant="outline"
              fontSizeProps="0.875rem"
              onClick={handleCloseRole}
              paddingProps="0.47rem"
            />
          </BtnGroup>
        </WalletForm>
      </RoleInnerBox>
    </RoleBoxMain>
  );
};

export default FilterGroupCampaign;
