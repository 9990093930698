import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  colors: {
    colorWhite: "#fff",
    colorBlack: "#000",
    colorBlueBackground: "#232323",
    colorYellow: "#e5b909",
    colorGray: "#3e3e3e",
    colorGray2: "#a2a2a2",
    colorLightGray: "#c4c4c4",
    colorDarkGray: "#333333",
    colorBlue: "#25346a",
    colorBGSidebar: "#FAFAFA",
  },
};
