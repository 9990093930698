import styled from "styled-components";
import { COLOR_YELLOW, WICRYPT_BORDER_LINE } from "../../styles/themes/colors";
import { styledProps } from "./type";
import { TextProps } from "../Reusable/types";

export const MainBox = styled.div`
  margin-top: 2rem;
  .blueBox {
    width: 100%;
    background: #eaf1ff;
    border: 0.5px solid #2f80ed;
    border-radius: 12px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.4;
    color: #14316b;
    padding: 1rem 1.5rem;
    margin-bottom: 1.875rem;
    @media (min-width: 390px) {
      max-width: 70%;
    }
  }
`;

export const InnerBox = styled.div`
  margin-bottom: 12px;
`;

export const HeadingBox = styled.div`
  padding-bottom: 0.875rem;
  margin-bottom: 2.5rem;

  span.line {
    display: block;
    width: 100%;
    height: 0.06rem;
    background-color: ${WICRYPT_BORDER_LINE};
    border-bottom: 1px solid ${WICRYPT_BORDER_LINE} !important;
    margin: 1.56rem 0 1.8rem;
  }
  .grp-btn {
    justify-content: flex-start;
  }
  @media (min-width: 390px) {
    display: flex;
    align-items: center;
    gap: 1.56rem;
    border-bottom: 1px solid ${WICRYPT_BORDER_LINE};

    span.line {
      display: none;
    }
  }
`;

export const AccountTitle = styled.h2`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: #1a1a1a;
  margin-right: auto;
`;

export const ContentBox = styled.div`
  display: grid;
  grid-template-areas:
    "avatar business campaign"
    ". total ."
    ". user .";
  gap: 2.5rem;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.2);
  box-shadow: 0px 2px 6px rgba(13, 10, 44, 0.08);
  border-radius: 20px;
  padding: 2.94rem 2.25rem;

  .avatar {
    grid-area: avatar;
    width: 2.5rem;
    height: 2.5rem;
  }
  .business {
    grid-area: business;
  }
  .campaign {
    grid-area: campaign;
  }
  .total {
    grid-area: total;
  }
  .user {
    grid-area: user;
  }
  > .right-content {
    margin-left: 2.5rem;

    h4 {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.75;
      color: #000000;
    }

    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 2.6;
      color: #6b6b6b;
    }
  }
  > div {
    margin-right: auto;
  }

  > p {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.6;
    color: #6b6b6b;
    align-self: flex-end;
  }
  p.heading {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #6b6b6b;
  }
  p.details {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: #1a1a1a;
    margin-top: 0.625rem;
  }
  @media (min-width: 390px) {
    display: flex;
    align-items: center;
    .avatar {
      width: 3.125rem;
      height: 3.125rem;
    }
  }
`;
export const ContentBox2 = styled(ContentBox)`
  display: grid;
  grid-template-areas:
    "head content1"
    "head content2";

  .companyLogo {
    grid-area: head;
    align-self: flex-start;
    width: 3rem;
    height: 3rem;
    @media (min-width: 390px) {
      width: 5rem;
      height: 5rem;
    }
  }
  .startDate {
    grid-area: content2;
    margin-top: 3.125rem;
  }
  > div {
    margin-right: 0;
  }
  .right-content {
    grid-area: content1;
    margin-left: 0;
  }

  @media (min-width: 390px) {
    /* grid-template-areas: "head content1 content2"; */
    display: flex;
    .startDate {
      margin-top: 0;
      margin-left: auto;
      align-self: center;
    }
    .right-content {
      margin-left: 2.5rem;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 33.6rem;
  padding: 1.25rem 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  line-height: 1.44;
  margin-bottom: 1rem;
  white-space: nowrap;

  @media (max-width: 375px) {
    white-space: normal;
  }

  h3 {
    padding-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  p {
    font-size: 10px;
    color: #6b6b6b;
  }

  > div {
    margin-right: auto;
  }

  button {
    background-color: transparent;
    border: none;
  }
`;

export const BoxIcon = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  a {
    align-self: center;
    color: ${COLOR_YELLOW};
  }
`;

export const SecurityForm = styled.div`
  width: 100%;
  max-width: 398px;
`;

export const FileInput = styled.input`
  position: absolute;
  overflow: hidden;
  opacity: 0;
  cursor: pointer !important;
  width: 4rem;
  left: 0;
`;

export const Relative = styled.div<TextProps>`
  position: relative;
  cursor: ${(props) => props.cursor || "auto"};
  /* cursor: auto; */
`;

export const MarginBox = styled.div<TextProps>`
  margin: ${(props) => props.margin || "0.5rem 0"};
`;
