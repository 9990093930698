import React, { useEffect, useState } from "react";
import { BackArrow, Input } from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
} from "../Dashboard/Dashboard.styled";
import ArrowYellow from "../../assets/icons/Wallet/arrow-yellow.svg";
import { CountryBox, Form2 } from "./Wallet.styled";
import Search from "../../assets/icons/Wallet/Search.svg";
import { useGetBanksQuery } from "../../features/api/walletSlice";
import { CardLoader } from "../Reusable/CardLoader";
import { Banks } from "../../features/api/walletSliceTypes";
import { BankModalProps } from "../../types";

const BankModal: React.FC<BankModalProps> = ({
  show,
  handleCloseModal,
  modalRef,
  handleSelect
}) => {
  const { data: allBanks, isLoading: gettingBanks } = useGetBanksQuery();
  const [banks, setBanks] = useState<Banks[]>([]);

   //This function handles searching the bank list
   const handleSearch = (val: string) => {
    if (allBanks) {
      if (val === "") {
        setBanks(allBanks?.data);
      } else {
        const result = (allBanks?.data).filter((bank) =>
          bank.name.toLowerCase().startsWith(val.toLowerCase())
        );
        setBanks(result);
      }
    }
  };

  useEffect(() => {
    if (allBanks) {
      setBanks(allBanks?.data);
    }
  }, [allBanks]);


  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer gapSpace="1.875rem">
        <BackArrow>
          <div className="back-wallet">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Select Bank</ModalTitle>
          </div>
        </BackArrow>
        <Form2>
          <img src={Search} alt="" className="img-size" />
          <Input
            type="text"
            placeholder="Search Bank"
            className="input-padding"
            onChange={(e)=> handleSearch(e.target.value)}
          />
        </Form2>
        <div>
          {gettingBanks ? <CardLoader /> :
           banks.map((bank) => {
            return (
              <CountryBox  onClick={() => {handleSelect(bank); handleCloseModal()}}>
                <div className="flex">
                  <img src={bank.photo} alt="Country's flag" />
                  <h4 className="title">{bank.name}</h4>
                </div>
                <img src={ArrowYellow} alt={bank.name} />
              </CountryBox>
            )
          })}
        </div>
      </ModalContainer>
    </MainContainer>
  );
};

export default BankModal;
