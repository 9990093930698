// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
// } from "recharts";
// import styled from "styled-components";

// type DataPoint = {
//   month: string;
//   amount: number;
// };

// type GraphProps = {
//   data: DataPoint[];
// };

// export const Graph = ({ data }: GraphProps) => {
//   return (
//     <Wrapper>
//       <LineChart width={1100} height={300} data={data}>
//         <XAxis dataKey="month" />
//         <YAxis />
//         <CartesianGrid strokeDasharray="3 3" />
//         <Tooltip />
//         <Line type="monotone" dataKey="amount" stroke="#8884d8" />
//       </LineChart>
//     </Wrapper>
//   );
// };

// const Wrapper = styled.div`
//   margin-top: 24px;
//   .recharts-wrapper,
//   svg {
//     max-width: 100%;
//     min-height: 200px;
//   }
// `;

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import moment from "moment";

type DataPoint = {
  date: string;
  amount: number;
};

type GraphProps = {
  data: DataPoint[];
};

const GraphBox = styled.div`
  border-radius: 12px;
  border: 1px solid #f5edff;
  background: #fff;
  padding: 3rem 1.5rem 1.5rem 0.5rem;
`;

const TooltipBox = styled.div`
  background: black;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 0.5rem;
`;

export function Graph({ data }: GraphProps) {

  function formatXAxis(tickItem: Date) {
    return moment(tickItem).format('Do MMM YY')
  }
  
  return (
    <GraphBox>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={1100}
          height={300}
          data={data}
          margin={{
            top: 15,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="date" tickFormatter={formatXAxis}/>
          <YAxis />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Line
            type="monotone"
            dataKey="amount"
            stroke="#E5B910"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </GraphBox>
  );
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipBox>
        {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
        <div>
          {payload.map((pld: any) => (
            <div style={{ display: "inline-block" }}>
              <div style={{ color: pld.fill }}>{pld.value}</div>
              {/* <div>{pld.dataKey}</div> */}
              <div>{label}</div>
            </div>
          ))}
        </div>
      </TooltipBox>
    );
  }

  return null;
};
