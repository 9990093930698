import { AdvertRequest, AdvertResponse, AdvertStatusRequest, OneAdvertResponse } from "./advertSliceTypes";
import { apiSlice } from "./apiSlice";

const advertSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        //Get adverts
        getAdverts: build.query<AdvertResponse, AdvertRequest>({
            query: ({status, search}) => `/adverts/status?status=${status}&search=${search}`,
            providesTags: ["Adverts"]
        }),

         //Get one advert
         getOneAdverts: build.query<OneAdvertResponse, string>({
            query: (id) => `/adverts/${id}`,
            providesTags: ["Adverts"]
        }),

        //Set advert Status
        setAdvertStatus: build.mutation<any, AdvertStatusRequest>({
            query: (payload) => ({
                url: `/adverts/approve`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ["Adverts"],
        }),
    }),
    overrideExisting: true,
})


export const { useGetAdvertsQuery, useGetOneAdvertsQuery, useSetAdvertStatusMutation } = advertSlice