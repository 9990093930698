import React from "react";
import { AccountTitle, HeadingBox } from "../../Account/Account.styled";
import { PicBox } from "../Adverts.styled";
import { ButtonReset } from "../../Reusable/Buttons/button";
import { useParams } from "react-router-dom";
import { useGetOneAdvertsQuery } from "../../../features/api/advertSlice";

interface ContentProps {
  handleImage: (val: string) => void;
}

const Content: React.FC<ContentProps> = ({ handleImage }) => {

  const {advertId} = useParams();
  
  //Get the advert detail
  const { data: advertDetail } = useGetOneAdvertsQuery(advertId as string);
  
  return (
    <div>
      <HeadingBox>
        <AccountTitle>Content</AccountTitle>
      </HeadingBox>

      <PicBox>
        {advertDetail?.data?.advert_pictures.map((adPictures)=>{
          return(
            <ButtonReset onClick={() => handleImage(adPictures?.picture_url)}>
                <img src={adPictures?.picture_url} alt="Advert Picture" />
            </ButtonReset>
          )
        })}
      </PicBox>
    </div>
  );
};

export default Content;
