import React, { useRef, useState } from "react";
import {
    RoleInnerBox,
    RoleBoxMain,
    BtnGroup,
    InputBox,
} from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { ErrorMsg, Label } from "../Authentication/styles/Container.styled";
import { AmountSection, AvailableBalance } from "./business.styled";
import { useManualFundingMutation } from "../../features/api/walletSlice";
import { IBusinessess } from "../../features/api/businessesSliceTypes";
import AuthCode from "../Account/AuthCode";
import useOutsideClick from "../../hooks/useOutsideClick";

interface FundBusinessProps {
    showRole: boolean;
    handleCloseRole: () => void;
    modalRef?: any;
    selectedBusiness: IBusinessess,

}

const FundBusiness: React.FC<FundBusinessProps> = ({
    showRole,
    handleCloseRole,
    modalRef,
    selectedBusiness,
}) => {
    const [amountToFund, setAmount] = useState<string>("");
    const [fundBusiness, { isLoading: fundingAccount }] = useManualFundingMutation();
    const [errMsg, setErr] = useState<string>("");
    const [authCode, setAuthCode] = useState<string>("");

    const [twoFA, show2FA] = useState<boolean>(false);

    const handleNext = () => {
        if (typeof Number(amountToFund) === 'number' && Number(amountToFund) > 0) {
            setErr("")
            show2FA(true)
        } else {
            setErr("Please enter a valid amount")
        }
    };


    const handleSubmit = (twoFACode: string) => {
        setErr("");
        let bodyFormData = new FormData();
        bodyFormData.append("TwoFactorCode", twoFACode);
        bodyFormData.append("Amount", amountToFund);
        bodyFormData.append("CurrencyId", "1");
        bodyFormData.append("BusinessEmail", selectedBusiness?.user?.email);
        bodyFormData.append("File", "");
        bodyFormData.append("ManualFundingData.AccountNo", "");
        bodyFormData.append("ManualFundingData.AccountName", "");
        bodyFormData.append("ManualFundingData.WalletAddress", "");
        bodyFormData.append("ManualFundingData.ChainId", "");
        bodyFormData.append("ManualFundingData.BankId", "");
        fundBusiness(bodyFormData)
            .unwrap()
            .then((res) => {
                if (res) {
                    console.log(res, 'i am the res')
                    setErr("");
                    handleCloseRole()
                    show2FA(false)
                }
            })
            .catch((err) => {
                setErr(err?.data?.Message || "Something went wrong")
            });
    };

    const handleCloseModal = () => {
        show2FA(false)
    };

    const modalRef2 = useRef<HTMLElement>(null);

    useOutsideClick(modalRef2, handleCloseModal);
    return (
        <>
            <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
                <div className="role-class">
                    <RoleInnerBox>
                        <div >
                            <h2>Add Amount</h2>
                            <p> You can top-up the business balance from here</p>
                        </div>
                        <div style={{ marginTop: " 42px" }}>
                            <Label> Add Top-up Amount</Label>
                            <AmountSection>
                                <span>₦</span>
                                <InputBox>
                                    <input
                                        onChange={(e) => setAmount(e.target.value)}
                                        value={amountToFund}
                                        width="100%"
                                    />
                                </InputBox>
                            </AmountSection>
                            <Label> Available Balance</Label>
                            <AmountSection>
                                <span>₦</span>
                                <AvailableBalance> {selectedBusiness?.wallet_balance}</AvailableBalance>
                            </AmountSection>

                        </div>
                        <p> {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>} </p>
                        <BtnGroup style={{ marginTop: " 42px" }} className="role-btn mt-5">
                            <Button
                                text="Confirm"
                                onClick={handleNext}
                                // disabled={fundingAccount}
                                // isLoading={fundingAccount}
                            />
                        </BtnGroup>
                    </RoleInnerBox>
                </div>
            </RoleBoxMain>

            <AuthCode
                showRole={twoFA}
                handleCloseRole={handleCloseModal}
                modalRef={modalRef2}
                setAuthCode={setAuthCode}
                handleNext={handleSubmit}
                isLoading={fundingAccount}
                err={errMsg}
            />
        </>
    );
};

export default FundBusiness;
