import React, { useState } from "react";
import {
  RoleInnerBox,
  RoleBoxMain,
  BtnGroup,
} from "../../Dashboard/Dashboard.styled";
import { Button } from "../../Reusable/Buttons/button";
import {
  ErrorMsg,
  Form,
  Input,
  Label,
} from "../../Authentication/styles/Container.styled";
import { useSetAdvertStatusMutation } from "../../../features/api/advertSlice";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { SetRoleProps } from "../../../types";

const RejectRequest: React.FC<SetRoleProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
}) => {
  const navigate = useNavigate();
  const {advertId} = useParams();
  
  const [rejectReason, setRejectreason] = useState<string>("");
  const [errMsg, setErr] = useState<string>("");

  const [setAdvertStatus, {isLoading: settingAdvertStatus}] = useSetAdvertStatusMutation()

  const handleReject = () =>{
    let data ={
      id: advertId as string,
      approve: false,
      reason: rejectReason
    }
    setErr("")
    setAdvertStatus(data)
    .unwrap()
    .then((res)=>{
      if(res.success){
        toast.success("Advert Rejected Successfully!")
        navigate("/home/adverts")
      }else{
        setErr(res?.data?.Message || "Something went wrong")
      }
     
    })
    .catch((err)=>{
      setErr(err?.data?.Message || "Something went wrong")
    })
  }

  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <div className="role-class">
        <RoleInnerBox widthBox="32.75rem">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="cancelbtn" onClick={handleCloseRole}>
              x
            </button>
          </div>
          <div className="heading">
            <h2>Reject Request</h2>
            <p>Please indicate why this advert is being rejected</p>
          </div>
          <Form className="form-margin">
            <div className="formControlPassword">
              <Label htmlFor="password" textAlign="left">
                Reason
              </Label>
              <Input
                type="text"
                name="reason"
                id="reason"
                value={rejectReason}
                onChange={(e)=> setRejectreason(e.target.value)}
                placeholder="This advert does not follow the networks ethics"
              />
            </div>
          </Form>
          {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
          <BtnGroup>
            <Button text="Cancel" variant="outline" disabled={settingAdvertStatus} onClick={handleCloseRole} />
            <Button text="Reject" BGProps="#FF1E1E" isLoading={settingAdvertStatus} onClick={()=> handleReject()} />
          </BtnGroup>
        </RoleInnerBox>
      </div>
    </RoleBoxMain>
  );
};

export default RejectRequest;
