import { apiSlice } from "./apiSlice";
import {
  CreateUserResponse,
  LoginRequest,
  LoginResponse,
  PasswordReset,
  ResetRequest,
  SendOTPRequest,
  SendOTPResponse,
  SignedInResetPassword,
  TwoFAResponse,
  VerifyOTOResponse,
  VerifyOTPRequest,
  VerifyTwoFARequest,
} from "./authTypes";

const authSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    //Send OTP to a user
    sendOTP: build.mutation<SendOTPResponse, Partial<SendOTPRequest>>({
      query: (payload) => ({
        url: "/otp/signup",
        method: "POST",
        body: payload,
      }),
    }),
    //Verify OTP to a user
    verifyOTP: build.mutation<VerifyOTOResponse, Partial<VerifyOTPRequest>>({
      query: (payload) => ({
        url: "/auth/verify",
        method: "POST",
        body: payload,
      }),
    }),
    //Create User
    createUser: build.mutation<CreateUserResponse, Partial<FormData>>({
      query: (payload) => ({
        url: "/users",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Network"]
    }),
    //Create Network
    createNetwork: build.mutation<VerifyOTOResponse, Partial<VerifyOTPRequest>>(
      {
        query: (payload) => ({
          url: "/networks",
          method: "POST",
          body: payload,
        }),
      }
    ),
    //Login a particular user
    logInUser: build.mutation<LoginResponse, Partial<LoginRequest>>({
      query: (payload) => ({
        url: "/auth/login",
        method: "POST",
        body: payload,
      }),
    }),
    //request password reset
    requestPasswordReset: build.mutation<ResetRequest, Partial<ResetRequest>>({
      query: (payload) => ({
        url: "/password/forgot",
        method: "POST",
        body: payload,
      }),
    }),
    //reset password
    resetPassword: build.mutation<PasswordReset, Partial<PasswordReset>>({
      query: (payload) => ({
        url: "/password/reset",
        method: "POST",
        body: payload,
      }),
    }),
    //reset signIn password
    resetSignedInPassword: build.mutation<
      SignedInResetPassword,
      Partial<SignedInResetPassword>
    >({
      query: (payload) => ({
        url: "/password/signedin/reset",
        method: "POST",
        body: payload,
      }),
    }),
    //Log out
    logOut: build.query({
      query: () => `/auth/logout`,
    }),
    //setup 2fa 
    twofaSetup: build.mutation<TwoFAResponse, Partial<string>>({
      query: (email) => ({
        url: `/users/2fa/setup?email=${email}`,
        method: "POST",
      }),
    }),
    //Verify 2fa
    verifytwoFA: build.mutation<VerifyOTOResponse, Partial<VerifyTwoFARequest>>({
      query: ({email, code}) => ({
        url: `/users/2fa/verify?email=${email}&code=${code}`,
        method: "POST",
      }),
  }),
  }),
  overrideExisting: true,
});

export const {
  useLogInUserMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useResetSignedInPasswordMutation,
  useSendOTPMutation,
  useVerifyOTPMutation,
  useCreateUserMutation,
  useTwofaSetupMutation, 
  useVerifytwoFAMutation
} = authSlice;
