import React from "react";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableBox } from "./Table.styled";
import { TableMainProps } from "../../types";

const TableMain: React.FC<TableMainProps> = ({
  defaultData,
  columns,
  greenColor,
}) => {
  const [data, setData] = React.useState(() => [...defaultData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableBox>
      <table className="dx-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, columnIndex) => (
                <td
                  key={cell.id}
                  style={{
                    color:
                      columnIndex === table.getRowModel().rows.length - 1
                        ? `${greenColor}`
                        : "#1A1A1A",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <table className="sm-table">
        {/* <tbody> */}
        {table.getRowModel().rows.map((row: any) => (
          <tbody key={row.id}>
            {row.getVisibleCells().map((cell: any) => (
              <tr key={cell.id}>
                <th>{cell.column.columnDef.header}</th>
                <td>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              </tr>
            ))}
          </tbody>
        ))}
        {/* </tbody> */}
      </table>
    </TableBox>
  );
};

export default TableMain;
