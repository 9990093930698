import React, { useState } from "react";
import { GraphTab, Section } from "../Dashboard/Dashboard.styled";
import Requests from "./Requests";
import AllBusinesses from "./AllBusinesses";

const Businesses = () => {
  const [status, setStatus] = useState<string>("1");

  return (
    <Section>
      <GraphTab>
        <button
          onClick={() => setStatus("1")}
          className={status === "1" ? "active" : ""}
        >
          Requests
        </button>
        <button
          onClick={() => setStatus("8")}
          className={status === "8" ? "active" : ""}
        >
          All businesses
        </button>
      </GraphTab>
      {/* <Requests
        show={showTabs.requests}
        requestBusiness={allBusinessesData?.data || []}
      />
      <AllBusinesses
        show={showTabs.allBusinesses}
        requestBusiness={allBusinessesData?.data || []}
      /> */}
      {status === "1" ? ( <Requests/>) : (<AllBusinesses/>)}
    </Section>
  );
};

export default Businesses;
