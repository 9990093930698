import React, { useState, MouseEvent, useRef, useEffect } from "react";
import { AccountTitle, HeadingBox, MainBox } from "../Account/Account.styled";
import { Button } from "../Reusable/Buttons/button";
import { ErrorMsg, Input, Label } from "../Authentication/styles/Container.styled";
import { Form27, FormBox, Text3 } from "./Wallet.styled";
import User from "../../assets/icons/Wallet/user.svg";
import CountryModal from "./CountryModal";
import BankModal from "./BankModal";
import BeneficiaryModal from "./BeneficiaryModal";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Section } from "../Dashboard/Dashboard.styled";
import { Network } from "../../features/api/networkSliceTypes";
import { Banks, Beneficiary } from "../../features/api/walletSliceTypes";
import { useGetNetworkDetailQuery } from "../../features/api/networkSlice";
import { useWithdrawEarningsMutation } from "../../features/api/walletSlice";
import { useCreateBankAccountsMutation, useGetWithdrawalFeeQuery } from "../../features/api/walletSlice";
import { ScaleLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const WiithdrawBalance: React.FC = () => {
  const navigate = useNavigate();
  const [showCountry, setShowCountry] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [showBeneficiary, setShowBeneficiary] = useState(false);
  const [createBankAccount, { isLoading: creatingBankAccount }] = useCreateBankAccountsMutation();

  const [selectedCountry, selectCountry] = useState<Network>();
  const [selectedBank, selectBank] = useState<Banks>();
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [amtTowithdraw, setAmtToWithdraw] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const [errorMsg, setError] = useState<string>('');
  const [selectedBankAccount, selectBankAccount] = useState<number>();

  const { data: networkDetail } = useGetNetworkDetailQuery();

  const [withdrawEarnings, { isLoading: withdrawingEarning }] = useWithdrawEarningsMutation();
  const { data: withdrawalFee } = useGetWithdrawalFeeQuery()


  const modalRef = useRef<HTMLElement>(null);
  const modalRef2 = useRef<HTMLElement>(null);
  const modalRef3 = useRef<HTMLElement>(null);

  const handleCountry = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowCountry(true);
  };

  const handleBank = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowBank(true);
  };

  const handleCloseModal = () => {
    setShowCountry(false);
    setShowBank(false);
    setShowBeneficiary(false)
  };

  const handleWithdraw = () => {
    if (!selectedBank) {
      setError("Please select a bank")
    } else if (!accountNumber) {
      setError("Please enter an account number")
    } else if (!amtTowithdraw) {
      setError("Please enter amount to wiithdraw")
    } else if (Number(amtTowithdraw) > Number(networkDetail?.data?.wallet_balance || 0)) {
      setError("Amount to withdraw cannot be more than your available balance")
    } else if (Number(amtTowithdraw) <= 0) {
      setError("Amount to withdraw must be greater than 0")
    } else {
      setError("")
      let data = {
        bank_account_id: selectedBankAccount as number,
        amount: Number(amtTowithdraw),
        user_id: 0
      }
      withdrawEarnings(data)
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success("Withdrawal Successful!");
            navigate("/home/wallet")
          }
        })
        .catch((err) => {
          setError(err?.data?.Message || "Something went wrong")
        })
    }
  }

  useOutsideClick(modalRef, handleCloseModal);
  useOutsideClick(modalRef2, handleCloseModal);
  useOutsideClick(modalRef3, handleCloseModal);
  console.log(withdrawalFee, "withdrawalFee")
  const handleResolution = () => {
    if (!selectedBank) {
      setError("Please select a bank")
    } else if (!accountNumber) {
      setError("Please enter an account number")
    } else {
      setError("")
      let bankData = {
        account_no: accountNumber,
        currency: "NGN",
        bank_id: selectedBank.id
      }
      createBankAccount(bankData)
        .unwrap()
        .then((res) => {
          setAccountName(res?.data?.account_name)
          selectBankAccount(res?.data?.id)
        })
        .catch((err) => {
          setError(err?.data?.Message)
        })
    }
  }

  const handleBeneficiary = (val: Beneficiary) => {
    setAccountNumber(val.account_no);
    selectBank(val.bank);
    setAccountName(val.account_name);
    selectBankAccount(val.id)
    handleCloseModal()
  }


  useEffect(() => {
    if ((accountNumber.length === 10 && !accountName) && selectedBank) {
      handleResolution()
    }
  }, [accountNumber, selectedBank])

  return (
    <Section>
      <Text3>
        My Wallet <span className="bank-transfer">- Bank Transfer</span>
      </Text3>
      <HeadingBox>
        <AccountTitle>Bank Transfer</AccountTitle>
        <span className="line"></span>
        <div style={{ width: "fit-content" }}>
          {accountName && <Button
            text="Proceed"
            fontSizeProps="0.875rem"
            paddingProps="0.66rem 1.5rem"
            isLoading={withdrawingEarning}
            onClick={() => handleWithdraw()}
          />}
        </div>
      </HeadingBox>
      {errorMsg && <ErrorMsg> {errorMsg}</ErrorMsg>}
      <MainBox>
        <div className="blueBox">
          <p>
            Only transfer to bank accounts that you own. Transfers to accounts
            that don't belong to you will be flagged.{" "}
            <p>
              Be aware that wire transfers often come with fees, so you may want
              to check with your bank to see what charges apply.
            </p>
          </p>
        </div>
        <Form27>
          {/* <FormBox>
            <Label textAlign="left">Country</Label>
            <Input disabled type="text" value={selectedCountry?.name} />
            <button className="balance-btn" onClick={handleCountry}>
              change
            </button>
          </FormBox> */}
          <FormBox>
            <Label textAlign="left">Bank Name</Label>
            <Input type="text" disabled value={selectedBank?.name} />
            <button className="balance-btn" onClick={handleBank}>
              change
            </button>
          </FormBox>
          <FormBox>
            <Label textAlign="left">Account Number</Label>
            <Input
              type="text"
              value={accountNumber}
              onChange={(e) => { setAccountNumber(e.target.value); setAccountName("") }}
            />
            <img src={User} alt="" className="balance-user pointer" onClick={() => setShowBeneficiary(true)} />
          </FormBox>
          <FormBox>
            {creatingBankAccount ?
              <ScaleLoader
                loading={creatingBankAccount}
                height={12}
                width={2}
                color={"#3d5170"}
              /> :
              <p className="accountName">{accountName}</p>}
            {/* <div className="flex">
              <input type="radio" name="" id="" />
              <Label textAlign="left">Save beneficiary</Label>
            </div> */}
          </FormBox>
          {accountName &&
            <>
              <FormBox>
                <Label textAlign="left">Amount</Label>
                <p className="absolute"> ₦</p>
                <Input type="text" className="pl-20" value={amtTowithdraw} onChange={(e) => setAmtToWithdraw(e.target.value)} />
                <button className="balance-btn" onClick={(e) => { e.preventDefault(); setAmtToWithdraw(networkDetail?.data?.wallet_balance.toString() || "0") }}>
                  All
                </button>
              </FormBox>
              <p className="fee">
                FEE: <span>₦{withdrawalFee?.data}</span>
              </p>
            </>
          }

        </Form27>
      </MainBox>
      <CountryModal
        show={showCountry}
        handleCloseModal={handleCloseModal}
        modalRef={modalRef}
        handleSelect={selectCountry}
        selectedValues={selectedCountry}
      />
      <BankModal
        show={showBank}
        handleCloseModal={handleCloseModal}
        modalRef={modalRef3}
        handleSelect={selectBank}
      />
      <BeneficiaryModal
        show={showBeneficiary}
        handleCloseModal={handleCloseModal}
        handleBeneficiary={handleBeneficiary}
      />

    </Section>
  );
};

export default WiithdrawBalance;
