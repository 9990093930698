import React, { useEffect, useState } from "react";
import {
  TitleBC,
  Avatar,
  NotifyBar,
  Menu,
  Box2,
  TitleGroup,
} from "../Dashboard/Dashboard.styled";
// import Notification from "../../assets/icons/Breadcrumbs/notification.svg";
import Arrow from "../../assets/icons/Breadcrumbs/arrow.svg";
// import Headphone from "../../assets/icons/Breadcrumbs/headphone.svg";
import { ReactComponent as MenuIcons } from "../../assets/icons/HomeIcons/menu.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import { ButtonReset } from "../Reusable/Buttons/button";
import { UserDetail } from "../../features/api/authTypes";
import { CircleSpan } from "../Reusable/Reuseable.styled";
import { BreadcrumbsProps } from "../../types";

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  handleNotifyClick,
  handleMenu,
  handleLogOut,
}) => {
  const location = useLocation();
  const [userDetail, setUser] = useState<UserDetail>();

  const titleMap: { [key: string]: string } = {
    "/home/dashboard": "Dashboard",
    "/home/adverts": "Adverts",
    "/home/wallet": "Wallet",
    "/home/account": "Account",
    "/home/businesses": "Businesses",
    "/home/networks": "Networks",
    "/home/adverts/:advertId": "Flash sales",
    "/home/wallet/withdrawbalance": "Withdraw balance",
  };

  const defaultTitle = "Dashboard";
  const title = titleMap[location.pathname] || defaultTitle;

  const isSpecificPath =
    location.pathname === "/home/adverts/:advertId" ||
    location.pathname === "/home/wallet/withdrawbalance";

  const navigate = useNavigate();
  const handleBack = () => {
    if (location.pathname === "/home/wallet/withdrawbalance") {
      navigate("/home/wallet");
    }
    if (location.pathname === "/home/adverts/:advertId") {
      navigate("/home/adverts");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const notify = document.querySelector(".notify") as HTMLElement;
      if (window.scrollY > 10) {
        notify.style.borderBottom = "0.5px solid rgba(0,0,0,0.3)";
      } else {
        notify.style.borderBottom = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  }, []);

  return (
    <div className="notify">
      <NotifyBar>
        <Box2 marginRight alignItems>
          <Menu>
            <ButtonReset onClick={handleMenu}>
              <MenuIcons />
            </ButtonReset>
          </Menu>
          <TitleGroup>
            {isSpecificPath && (
              <ButtonReset className="btn-reset" onClick={() => navigate(-1)}>
                <ArrowLeft className="arrow-left" />
              </ButtonReset>
            )}
            <TitleBC>{title}</TitleBC>
          </TitleGroup>
        </Box2>
        <Box2 alignItems>
          {/* <ButtonReset onClick={handleNotifyClick}>
            <img src={Notification} alt="Notification" />
          </ButtonReset>
          <img src={Headphone} alt="Calls" /> */}
          <ButtonReset onClick={handleLogOut}>
            {userDetail?.photo ? 
              <CircleSpan width="40px" height="40px" background="#f3eeee">
                  <img 
                    src={userDetail?.photo} 
                    alt="profile-photo" 
                    width={40}
                    height={40}
                    className="image-round"
                  /> 
                </CircleSpan>
                : 
              <Avatar>
              <p>
                {userDetail?.networks
                  ? userDetail?.networks[0]?.name.slice(0, 2).toUpperCase() ||
                    ""
                  : ""}
              </p>
            </Avatar>}
            <img src={Arrow} alt="Arrow" />
          </ButtonReset>
        </Box2>
      </NotifyBar>
    </div>
  );
};

export default Breadcrumbs;
