import styled from "styled-components";

export const TableBox = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 3.5rem;
    margin-top: 1.875rem;
  }

  th,
  td {
    border: 1px solid rgb(204, 204, 204);
    border-image: initial;
    padding: 1.34rem;
    border-right: none;
    border-left: none;
  }
  tr:nth-child(2n) {
    background: #fbfbfb;
    border-bottom: 1px solid #efefef;
  }

  th {
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 1.4;
    text-transform: uppercase;
    color: rgb(26, 26, 26);
    white-space: nowrap;
  }

  td {
    font-size: 0.875rem;
    line-height: 1.4;
    color: rgb(26, 26, 26);
  }

  td > a {
    font-weight: 500;
    font-size: 0.69rem;
    line-height: 1.4;
    border-radius: 26px;
    padding: 0.25rem 1rem;
    cursor: pointer;
    background-color: rgb(37, 52, 106);
    color: rgb(255, 255, 255);
    border: none;
    white-space: nowrap;
  }

  th > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .dx-table {
    display: none;
    @media (min-width: 600px) {
      display: table;
    }
  }
  .sm-table {
    display: table;
    tr {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    th {
      display: flex;
      align-items: center;
    }

    tbody {
      display: grid;
      margin-bottom: 3rem;
    }
    td,
    th {
      border-top: none;
    }

    td {
      text-align: right;
    }

    @media (min-width: 600px) {
      display: none;
    }
  }
`;

export const AddButton = styled.button `
  display: flex;
  height: 24px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 26px;
  background: var(--primary-blue, #25346A);
  color: #fefefe;
  border: none;
`

export const WalletBalanceInput = styled.input `
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 26px;
  background: var(--colors-neutral-neutral-600, #F5F5F5);
  border: none;
  padding-left: 60px;
  p{
    color: var(--secondary-dark-grey, #6B6B6B);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 20.16px */
    position: absolute
  }
`
