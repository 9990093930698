import { apiSlice } from "./apiSlice";
import {
    NetworkResponse,
    NetworkDetail
} from "./networkSliceTypes";

const networkSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({

    //Get all networks 
    getNetworks: build.query<NetworkResponse, string>({
      query: (search) => `/networks?search=${search}`,
      providesTags: ['Network']
    }),

    //Get Network detail 
     getNetworkDetail:  build.query<NetworkDetail, void>({
        query: () => `/networks/user`,
        providesTags: ["Overview"]
    }),
  }),
});

export const {
  useGetNetworksQuery,
  useGetNetworkDetailQuery
} = networkSlice;
