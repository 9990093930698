import React, { useState } from "react";
import {
  RoleInnerBox,
  Price,
  RoleBoxMain,
  BtnGroup,
  InputBox,
} from "./Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { useUpdateMinimalBidMutation, useGetMinimalBidQuery } from "../../features/api/dashboardSlice";
import { ErrorMsg } from "../Authentication/styles/Container.styled";
import { SetRoleProps } from "../../types";

const SetRole: React.FC<SetRoleProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
}) => {
  const [network_rate, setNetworkRate] = useState<string>("");
  const [setMinimumBid, { isLoading: settingRate }] = useUpdateMinimalBidMutation();
  const [errMsg, setErr] = useState<string>("");
  const {data: minimumBid}  = useGetMinimalBidQuery();

  const handleClose = () =>{
    setErr("");
    setNetworkRate("");
    handleCloseRole()
  }

  const handleSubmit = () => {
    let data ={
      id: minimumBid?.data?.id as number,
      effective_bid: minimumBid?.data?.effective_bid as number,
      minimal_bid: Number(network_rate)
    }
    setErr("")
    if (network_rate) {
      setMinimumBid(data)
        .unwrap()
        .then((res) => {
          if (res) {
            handleClose();
          }
        })
        .catch((err) => {
          setErr(err?.data?.Message || "Something went wrong")
        });
    }
  };

  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <div className="role-class">
        <RoleInnerBox>
          <div>
            <h2>Update Minimum Bid</h2>
            <p> Update the minimum bid per views</p>
          </div>
          <Price>
            <span>₦</span>
            <InputBox>
              <input
                onChange={(e) => setNetworkRate(e.target.value)}
                value={network_rate}
                width="100%"
              />
            </InputBox>
          </Price>
          <p> {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>} </p>
          <BtnGroup className="role-btn">
            <Button
              text="Cancel"
              variant="outline"
              disabled={settingRate}
              onClick={handleClose}
            />
            <Button
              text="Set Rate"
              onClick={handleSubmit}
              disabled={settingRate}
              isLoading={settingRate}
            />
          </BtnGroup>
        </RoleInnerBox>
      </div>
    </RoleBoxMain>
  );
};

export default SetRole;
