import { useState, useEffect } from "react";
import {
  FlexBox,
  Image,
  RightSubtitle,
  RightTitle,
  WrapperRight,
} from "./styles/Container.styled";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import Rectangle from "../../assets/images/rectangle.png";
import { CAROUSEL_DATA } from "../../data/CarouselData";

const WrapperLeftSide = () => {
  const [carolIndex, setCarolIndex] = useState<number>(0);

  const handleClick = (n: number) => {
    setCarolIndex((prevState) => {
      let newIndex = prevState + n;
      if (newIndex < 0) {
        newIndex = CAROUSEL_DATA.length - 1;
      }
      if (newIndex > CAROUSEL_DATA.length - 1) {
        newIndex = 0;
      }

      return newIndex;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleClick(1);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <WrapperRight>
      <div className="main-right">
        <FlexBox flexDirection gap="1rem" style={{ position: "relative" }}>
          <button onClick={() => handleClick(-1)} className="carolbtn rotate">
            <Arrow />
          </button>
          <img
            src={Rectangle}
            alt=""
            className="small-img"
            width="414px"
            height="338px"
          />
          <img
            src={Rectangle}
            alt=""
            className="big-img"
            width="454px"
            height="338px"
          />
          <Image src={CAROUSEL_DATA[carolIndex].image} alt="" />
          <button onClick={() => handleClick(1)} className="carolbtn">
            <Arrow />
          </button>
        </FlexBox>
        <div>
          <div>
            <RightTitle>{CAROUSEL_DATA[carolIndex].title}</RightTitle>
            <RightSubtitle>
              {CAROUSEL_DATA[carolIndex].description}
            </RightSubtitle>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <FlexBox flexDirection gap="0.5rem">
              <div
                className={`${carolIndex === 0 ? "active" : ""} downbtn`}
              ></div>
              <div
                className={`${carolIndex === 1 ? "active" : ""} downbtn`}
              ></div>
              <div
                className={`${carolIndex === 2 ? "active" : ""} downbtn`}
              ></div>
            </FlexBox>
          </div>
        </div>
      </div>
    </WrapperRight>
  );
};

export default WrapperLeftSide;
