import React, { useState } from "react";
import { AccountTitle, HeadingBox, InnerBox, MainBox } from "./Account.styled";
import SingleBeneficiary from "./SingleBeneficiary";
import { AuthorizedAccessBeneficiaries } from "./AuthorizedAccess";
import { useGetBankAccountsQuery } from "../../features/api/walletSlice";
import { useDeleteSingleBankAccountMutation } from "../../features/api/accountSlice";
import EmptyBox from "../Reusable/EmptyBox";
import toast from "react-hot-toast";
import { CardLoader } from "../Reusable/CardLoader";

const Beneficiaries = () => {
  const [showRole, setShowRole] = useState(false);
  const [errMsg, setErr] = useState<string>("");

  // Store the ID of the beneficiary to be deleted
  const [deleteBeneficiaryId, setDeleteBeneficiaryId] = useState<string | null>(
    null
  );

  const [loadingItemId, setLoadingItemId] = useState<number | null>(null);

  const { data: bankAccounts, isLoading } = useGetBankAccountsQuery();

  const [deleteBankAccount, { isLoading: isBeneficiariesLoading }] =
    useDeleteSingleBankAccountMutation();

  const handleOpenRole = () => {
    setShowRole(true);
  };

  const handleCloseRole = () => {
    setShowRole(false);
  };

  const handleDeleteButtonClick = (id: string) => {
    setDeleteBeneficiaryId(id); // Set the ID of the beneficiary to be deleted
    setShowRole(true); // Open the modal
  };

  const handleDelete = (id: any) => {
    setErr("");
    deleteBankAccount(id as string)
      .unwrap()
      .then((res) => {
        setErr("");
        if (res?.success) {
          setShowRole(false);
          toast.success("Beneficiary account deleted successfully");
        } else {
          setErr(res?.Message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.Message);
      })
      .finally(() => {
        setLoadingItemId(null);
      });
  };
  return (
    <div>
      <HeadingBox>
        <AccountTitle>My Beneficiaries</AccountTitle>
        <span className="line"></span>
      </HeadingBox>
      <p>{errMsg}</p>
      <MainBox>
        <InnerBox>
          {isLoading ? (
            <CardLoader />
          ) : bankAccounts?.data?.length == 0 ? (
            <EmptyBox message="You have no beneficiaries!" />
          ) : (
            bankAccounts?.data?.map((item: any) => {
              return (
                <div key={item?.id}>
                  <SingleBeneficiary
                    companyLogo={item?.bank?.photo}
                    service={item?.account_name}
                    accountNumber={item?.account_no}
                    beneficiaryName={item?.bank?.name}
                    handleDelete={() => handleDeleteButtonClick(item?.id)}
                    isLoading={isBeneficiariesLoading}
                  />
                  {showRole && deleteBeneficiaryId === item?.id && (
                    <AuthorizedAccessBeneficiaries
                      handleDeleteAccount={() => handleDelete(item?.id)}
                      isLoading={isBeneficiariesLoading}
                      handleCloseRole={handleCloseRole}
                      errMsg={errMsg}
                    />
                  )}
                </div>
              );
            })
          )}
        </InnerBox>
      </MainBox>
    </div>
  );
};

export default Beneficiaries;
