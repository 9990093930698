import React, { useState } from "react";
import { GraphTab, Section } from "../Dashboard/Dashboard.styled";
import Active from "./Active";
import Pending from "./Pending";
import { useGetAdvertsQuery } from "../../features/api/advertSlice";
import { CardLoader } from "../Reusable/CardLoader";
import { Form } from "../Authentication/styles/Container.styled";
import { Input2, Label2, Walletbtn } from "../Wallet/Wallet.styled";

const Adverts: React.FC = () => {
  const [status, setStatus] = useState<string>("1");
  const [searchValue, setSearch] = useState<string>("");
  //Get adverts 
  const { data: allAdverts, isLoading: gettingAdverts, isFetching: fetchingAdverts } = useGetAdvertsQuery({ status: status, search: searchValue });

  return (
    <Section>
      <GraphTab>
        <button

          onClick={() => setStatus("1")}
          className={status === "1" ? "active" : ""}
        >
          Pending
        </button>
        <button
          onClick={() => setStatus("8")}
          className={status === "8" ? "active" : ""}
        >
          Active
        </button>
      </GraphTab>
      <Form>
        <Label2 htmlFor="search">Search</Label2>
        <Input2
          type="text"
          id="search"
          onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setSearch(e.target.value)}
          value={searchValue}
          placeholder="Advert name"
        />
      </Form>
      {gettingAdverts || fetchingAdverts ? <CardLoader /> : 
      status === "1" ? <Pending pendingAdverts={allAdverts?.data || []} /> : 
       status === "8" ? <Active activeAdverts={allAdverts?.data || []} /> : null}
    </Section>
  );
};

export default Adverts;
