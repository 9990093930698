import React, { useState, useRef, useEffect } from "react";
import { Button, ButtonReset } from "../Reusable/Buttons/button";
import { AccountTitle, HeadingBox, ContentBox2 } from "./Account.styled";
import DeleteIcon from "../../assets/icons/delete.svg";
import EditProfile from "./EditProfile";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../hooks/useOutsideClick";
import AuthorizedAccess from "./AuthorizedAccess";
import moment from "moment";
import { UserDetail } from "../../features/api/authTypes";
import { Avatar } from "../Dashboard/Dashboard.styled";
import { useDeleteUserMutation } from "../../features/api/accountSlice";
import { TabComponent } from "../Reusable/types";
import { CircleSpan } from "../Reusable/Reuseable.styled";

const MyDetails = () => {
  const [showRole, setShowRole] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [userDetail, setUser] = useState<UserDetail>();

  // error
  const [errMsg, setErr] = useState<string>("");

  // password
  const [password, setPassword] = useState("");

  const modalRef = useRef<HTMLElement>(null);
  const modalRef2 = useRef<HTMLDivElement>(null);

  const [deleteUser, { isLoading: deletingUser }] = useDeleteUserMutation();

  const handleOpenRole = () => {
    setShowRole(true);
  };

  const handleCloseRole = () => {
    setShowRole(false);
  };

  const handleClickDelete = () => {
    setShowAccess(true);
  };

  const handleCloseAccess = () => {
    setShowAccess(false);
  };

  const navigate = useNavigate();

  // function imgError(e: any) {
  //   e.target.src = BeeLogo;
  // }

  const handleDeleteAccount = () => {
    setErr("");
    deleteUser(password)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          navigate("/");
        } else {
          setErr(res?.Message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.Message);
      });
  };

  useOutsideClick(modalRef, handleCloseRole);
  useOutsideClick(modalRef2, handleCloseAccess);

  const updateUserFromLocalStorage = () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  };

  useEffect(() => {
    updateUserFromLocalStorage();
  }, []);

  return (
    <div>
      <HeadingBox>
        <AccountTitle>Personal Details</AccountTitle>
        <span className="line"></span>
        <div className="group-btn" style={{ display: "flex", gap: "1rem" }}>
          <Button
            text="Edit profile"
            onClick={handleOpenRole}
            paddingProps="0.66rem 1.5rem"
            widthProps="auto"
          />
          <ButtonReset onClick={handleClickDelete}>
            <img src={DeleteIcon} alt="Delete Icon" />
          </ButtonReset>
        </div>
      </HeadingBox>
      <ContentBox2>
        <CircleSpan width="120px" height="120px" background="#f3eeee">
          {userDetail?.photo ? (
            <img
              src={userDetail?.photo || ""}
              alt="Company's Logo"
              className="image-round"
              // onError={imgError}
            />
          ) : (
            <Avatar className="image-round">
              <p>
                {userDetail?.networks
                  ? userDetail?.networks[0]?.name.slice(0, 2).toUpperCase() ||
                    ""
                  : ""}
              </p>
            </Avatar>
          )}
        </CircleSpan>
        <div className="right-content">
          <h4>
            {userDetail?.networks ? userDetail?.networks[0]?.name || "" : ""}
          </h4>
          <p>{userDetail?.email}</p>
        </div>
        <p className="startDate">
          User since:{" "}
          {moment(userDetail?.created_at || new Date()).format("DD MMM YYYY")}
        </p>
      </ContentBox2>
      <EditProfile
        show={showRole}
        handleCloseModal={handleCloseRole}
        modalRef={modalRef}
        updateUserFromLocalStorage={updateUserFromLocalStorage}
      />
      {showAccess && (
        <AuthorizedAccess
          handleCloseRole={handleCloseAccess}
          handleDeleteAccount={handleDeleteAccount}
          errMsg={errMsg}
          isLoading={deletingUser}
          password={password}
          setPassword={setPassword}
        />
      )}
    </div>
  );
};

export default MyDetails;
