import styled, { StyledProps } from "styled-components";
import {
  COLOR_BG_SIDEBAR,
  COLOR_BLACK,
  COLOR_WHITE,
  COLOR_YELLOW,
} from "../../styles/themes/colors";
import { NavLink } from "react-router-dom";
import { styledProps } from "../Account/type";
import { TextProps } from "../Reusable/types";
import { Box2Props, GraphProps, ModalContainerProps, ModalProps, RoleProps, RoleWidthBox, SideContainerProps } from "../../types";

export const ContainerDB = styled.div`
  position: relative;
  height: 100vh; //change
  //overflow: hidden; //change
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: 15rem 1fr; //change
    /* grid-template-columns: repeat(auto-fit, minmax(16.25rem, 1fr)); */
    /* grid-template-columns: 1fr; */
  }
`;

export const SideContainer = styled.aside<SideContainerProps>`
  display: ${({ showMenu }) => (showMenu ? "block" : "none")};
  background: white;
  overflow: hidden;
  transition: all 300ms ease;
  z-index: 99;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(8px);
  max-height: 100vh;

  .sidelogo {
    width: 8.3125rem;
    height: 2.1275rem;
  }

  @media (min-width: 769px) {
    position: relative; //change
    display: block;
    width: 15rem;
    background: ${COLOR_BG_SIDEBAR};
    z-index: auto; //change
  }
`;

export const InnerNav = styled.div`
  width: 20rem;
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; */
  height: 100%;
  background: white;
  padding-top: 3.125rem;

  @media (min-width: 769px) {
    display: block;
    width: 100%;
    background: ${COLOR_BG_SIDEBAR};
  }
`;

export const LogoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  padding: 0 1.875rem;

  p {
    font-size: 1.3rem;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 10px;

    @media (min-width: 769px) {
      display: none;
    }
  }
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  .arrow-left {
    width: 20px;
    height: 20px;
  }
`;

export const BoxDB = styled.main`
  overflow-y: auto;
`;

export const Section = styled.section`
  padding: 0 1.25rem 1.25rem;

  @media (min-width: 769px) {
    padding: 0 2.625rem 2.625rem;
  }
`;

export const SideBox = styled.div<TextProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (!props.gap ? 0 : props.gap)};
`;

export const Tab = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  /* padding: 1.156rem 0 1.156rem 1.875rem; */
  /* height: 3.56rem; */
  height: 3rem;
  margin-left: 2rem;
  transition: all 300ms ease;

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.44;
    color: #6b6b6b;
  }

  &.active {
    background: #25346a0d;
    background: rgba(37, 52, 106, 0.05);
    margin-left: 0;
    p {
      font-weight: 600;
      color: #25346a;
    }
    .nav-icon svg path {
      fill: #25346a;
    }
    &::before {
      content: "";
      /* position: absolute;
      left: 0;
      top: 0; */
      width: 0.375rem;
      height: 100%;
      background: #25346a;
      margin-right: calc(1rem - 0.375rem);
    }
  }
`;

export const NotifyBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.53rem 1.25rem;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  @media (min-width: 769px) {
    padding: 1.53rem 2.625rem;
  }
`;

export const SmallBox = styled.div`
  margin: 1.5rem 0 3.5rem;

  .title {
    font-family: "General Sans";
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.44;
    margin-bottom: 1.44rem;
    color: #1a1a1a;
  }
`;

export const Box2 = styled.div<Box2Props>`
  display: flex;
  gap: 1.25rem;
  align-items: ${(props) =>
    props.alignItems === true ? "center" : "flex-start"};
  flex-wrap: ${(props) => (props.nonWrap === true ? "nowrap" : "wrap")};
  margin-right: ${(props) => (props.marginRight === true ? "auto" : 0)};
`;

export const Box2Main = styled.div<Box2Props>`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  @media (min-width: 376px) {
    align-items: ${(props) =>
      props.alignItems === true ? "center" : "flex-start"};
    flex-wrap: ${(props) => (props.nonWrap === true ? "nowrap" : "wrap")};
    margin-right: ${(props) => (props.marginRight === true ? "auto" : 0)};
    flex-direction: row;
  }
`;
export const Box3Main = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  @media (min-width: 376px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 769px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const InnerBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  flex: 1;
  white-space: nowrap;
  padding: 1rem 1.5rem;
  background: #ffffff;
  border: 1px solid rgba(206, 206, 206, 0.25);
  border-radius: 0.5rem;
  gap: 20px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h4 {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.44;
    color: #6b6b6b;
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
  }

  p {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.45;
    color: #1a1a1a;
  }

  button {
    width: 5.74rem;
    height: 1.5rem;
    font-weight: 500;
    font-size: 0.63rem;
    line-height: 1.44;
  }
`;

export const TitleBC = styled.h2`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.44;
  color: #1a1a1a;
`;

export const Avatar = styled.span<TextProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || "2.188rem"};
  /* width: 2.188rem; */
  aspect-ratio: 1;
  background: #25346a;
  border: 1px solid #f5f5f5;
  border-radius: 50%;

  p,
  span {
    font-family: "Work Sans";
    font-size: 1rem;
    line-height: 1.5;
    color: #fefefe;
  }
`;

export const Box3 = styled.div`
  h3 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.44;
    color: #121020;
  }
`;

export const GraphTab = styled.div<GraphProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  width: 100%;
  background: rgba(118, 118, 128, 0.05);
  border-radius: 0.5rem;
  margin: 1.05rem 0 1.5rem;
  transition: all 300ms ease;

  width: ${(props) =>
    !props.widthGraphBox ? "fit-content" : props.widthGraphBox};

  button,
  a {
    border: none;
    background: transparent;
    padding: 0.375rem 0.875rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    letter-spacing: -0.08px;
    color: ${COLOR_BLACK};
    white-space: nowrap;
  }

  button.active,
  a.active {
    background: #e5b910;
    color: ${COLOR_WHITE};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
    border-radius: 7px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;

export const RoleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const RoleInnerBox = styled.div<RoleWidthBox>`
  background: ${(props) => (!props.background ? "#fefefe" : props.background)};
  border-radius: 12px;
  text-align: center;
  width: 24rem;
  padding: 1.5rem;
  margin: 0 2rem;

  @media (min-width: 390px) {
    width: ${(props) => (!props.widthBox ? "100%" : props.widthBox)};
    padding: ${(props) => (!props.padding ? "2.5rem 3rem" : props.padding)};
    margin: 0;
  }

  h2 {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    color: #1a1a1a;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5;
    color: #6b6b6b;
  }

  .form-margin {
    margin: 2.6rem 0;
  }

  /* button {
    width: 8.625rem;
  } */
  .cancelbtn {
    width: 24px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #f5f5f5;
    border: none;
  }
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 2.625rem 0;

  span {
    color: ${COLOR_YELLOW};
  }
`;

export const PinInputBox = styled.div`
  display: flex;
  height: 3rem;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 1.5;
  color: #25346a;
  gap: 10px;
  justify-content: center;
  margin-bottom:50px;
  input {
    background: none;
    border: none;
  }
`;

export const InputBox = styled.div`
  display: flex;
  height: 3rem;
  background: #f5f5f5;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 1.5;
  color: #25346a;
  // gap: 10px;
  // justify-content: center;
  input {
    background: none;
    border: none;
  }
`;

export const MainContainer = styled.section<ModalProps>`
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(8px);
  z-index: 101;
  height: 100vh;
`;

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (!props.gapSpace ? "2.875rem" : props.gapSpace)};
  width: 100%;
  height: 100%;
  padding: 3.125rem 2.5rem 1.5rem;
  background-color: ${COLOR_WHITE};
  overflow-y: auto;
  @media (min-width: 390px) {
    max-width: 30rem;
  }
`;

export const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.44;
  color: rgba(0, 0, 0, 0.87);
`;

export const NoticeBox = styled.div`
  line-height: 1.4;

  h4 {
    font-weight: 500;
    font-size: 0.875rem;
    color: #000000;
    margin-bottom: 1rem;
  }
  h5 {
    font-weight: 500;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
  }
  .notify-icon {
    width: 2rem;
    height: 2rem;
  }
`;

export const Menu = styled.div`
  svg {
    width: 1.5rem;
    aspect-ratio: 1;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;

export const RoleBoxMain = styled.div<RoleProps>`
  display: ${({ showRoleModal }) => (showRoleModal ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(8px);
  overflow: hidden !important;
  z-index: 101;
  height: 100vh;
`;

export const RoleBoxMain2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(8px);
  overflow: hidden !important;
  z-index: 101;
  height: 100vh;
`;

export const H2 = styled.h2`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4;
  text-align: center;
  color: #1a1a1a;
`;

export const H4 = styled.h4`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4;
  color: #25346a;
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const Paragraph = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.4;
  color: #6b6b6b;
`;

export const Box7 = styled.div`
  position: absolute;
  width: 100%;
  background: #fff;
  padding: 0.8rem;
  z-index: 3;
  border-radius: 12px;

  li {
    list-style: none;
    margin-bottom: 0.5rem;
  }
`;

export const LogoutBox = styled.button`
  position: absolute;
  right: 3rem;
  z-index: 5;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #fff;
  border: none;
  border-radius: 8px;
  background: #fff;
  padding: 0.8rem 2rem 0.8rem 1.25rem;
  box-shadow: 1px 0px 4px 3px rgba(74, 68, 68, 0.2);
`;

export const Text14 = styled.div<styledProps>`
  color: ${(props) => props.color || "#000"};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
`;

// overview info
export const Box22 = styled.div<{ isTooltipVisible: boolean }>`
  position: relative;
  cursor: pointer;

  /* img {
    width: 1rem;
  } */

  .tooltip {
    position: absolute;
    top: 100%;
    left: 80%;
    transform: translateX(-80%);
    display: ${({ isTooltipVisible }) => (isTooltipVisible ? "block" : "none")};
    width: auto;
    min-width: 50px;

    /* Add pointer */
    &:before {
      content: "";
      position: absolute;
      top: -4px;
      left: 78.5%;
      transform: translateX(-78.5%);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #6b6b6b;
    }

    .tooltip-content {
      font-size: 0.75rem;
      background-color: #6b6b6b;
      color: white;
      padding: 0.25rem 1rem 0.25rem 0.25rem;
      border-radius: 4px;
      position: relative;
      z-index: 1;
      top: 2px;
      left: -1px;
      width: fit-content;
    }
  }
`;

export const HeaderBox = styled.section`
  position: sticky;
  max-width: 100%;
  top: 0;
  background: white;
  z-index: 4;
`;

export const DropdownContainer = styled.div`
  padding: 0.8rem 2rem 0.8rem 1.25rem;
  box-shadow: 1px 0px 4px 3px rgba(74, 68, 68, 0.2);
  border-radius: 6px;
`