import React, { useState } from "react";
import { Box22, InnerBox } from "./Dashboard.styled";
import InforCircle from "../../assets/icons/HomeIcons/infoCircle.svg";
import { Button } from "../Reusable/Buttons/button";
import { ScaleLoader } from "react-spinners";
import { OverviewProps } from "./types";

const OverviewInfo: React.FC<OverviewProps> = ({
  title,
  value,
  info,
  showButton = false,
  handleRole,
  isLoading,
}) => {
  // State to track tooltip visibility
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  // Function to handle the tooltip visibility
  const handleTooltipToggle = (event: React.MouseEvent) => {
    setTooltipVisible((prevState) => !prevState);
  };

  return (
    <InnerBox>
      <div>
        <h4>{title}</h4>

        <Box22
          isTooltipVisible={isTooltipVisible}
          onMouseEnter={handleTooltipToggle} // Show tooltip on mouse enter
          onMouseLeave={handleTooltipToggle} // Hide tooltip on mouse leave
        >
          <img src={InforCircle} alt="" />
          <div className="tooltip">
            <div className="tooltip-content">
              {/* Add tooltip content */}
              {info}
            </div>
          </div>
        </Box22>
      </div>
      <div>
        <p>
          {isLoading ? (
            <ScaleLoader
              loading={isLoading}
              height={12}
              width={2}
              color={"#3d5170"}
            />
          ) : (
            value
          )}
        </p>
        {showButton && <Button text="Set Bid" onClick={handleRole} />}
      </div>
    </InnerBox>
  );
};

export default OverviewInfo;
