import styled from "styled-components";
import { RoleWidthBox } from "../../types";
import { TextProps } from "./types";

export const CloseBtn = styled.button<TextProps>`
  background: transparent;
  cursor: pointer;
  border: none;
  svg path {
    fill: ${(props) => props.fill || "white"};
  }
`;

export const CloseBtn2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f5f5f5;
  width: 30px !important;
  aspect-ratio: 1;
  border: none;
`;

export const ModalBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(8px);
  overflow: hidden !important;
  z-index: 101;
  height: 100vh;
`;

export const ModalInnerBox = styled.div<RoleWidthBox>`
  background: ${(props) => (!props.background ? "#fefefe" : props.background)};
  border-radius: 12px;
  text-align: center;
  width: ${(props) => props.width || "24rem"};
  padding: 1.5rem;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.6rem;

  @media (min-width: 390px) {
    width: ${(props) => (!props.widthBox ? "100%" : props.widthBox)};
    padding: ${(props) => (!props.padding ? "2.5rem 3rem" : props.padding)};
    margin: 0;
  }
  .cancelbtn {
    width: 24px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #f5f5f5;
    border: none;
  }
`;

export const FlexBox = styled.div<TextProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  gap: ${(props) => props.gap || "0.5rem"};
  margin-top: ${(props) => props.MarginTop || "0"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  @media (max-width: 800px) {
    flex-wrap: ${(props) => props.flexWrap || "nowrap"};
  }
`;

export const MarginBottom = styled.div<TextProps>`
  margin-bottom: ${(props) => props.marginBottom || "0.75rem"};
`;

export const BoxWidth = styled.div<TextProps>`
  width: ${(props) => props.width || "auto"};
  margin: 0 auto;
`;

export const Text10 = styled.p<TextProps>`
  color: ${(props) => props.color || "#6b6b6b"};
  font-size: 0.625rem;
  font-weight: ${(props) => props.fontWeight || "500"};
  line-height: ${(props) => props.lineHeight || "1"};

  /* white-space: nowrap; */
  @media (min-width: 765px) {
    font-weight: ${(props) => props.fontWeightMd || "500"};
  }
`;

export const Text12 = styled.p<TextProps>`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "0.75rem"};
  font-weight: ${(props) => props.fontWeight || "400"};
  line-height: ${(props) => props.lineHeight || "1.4"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin-top: ${(props) => props.marginTop || "0"};
  white-space: nowrap;
`;

export const LoaderContainer = styled.div`
  margin: 0 auto;
`;

export const LoaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const TextBox = styled.div<TextProps>`
  /* margin: 0 auto; */
  margin: ${(props) => props.margin || "0 auto"};
  text-align: center;
`;

export const CircleSpan = styled.span<TextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background || "#e5b910"};
  font-size: 0.75rem;
  color: #fff;
  width: ${(props) => props.width || "18px"};
  height: ${(props) => props.height || "18px"};
  border-radius: 50%;
  margin-right: ${(props) => props.marginRight || "0"};

  .image-round {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const Label14 = styled.label<TextProps>`
  color: ${(props) => props.color || "#6b6b6b"};
  font-size: ${(props) => props.fontSize || "0.875rem"};
  font-weight: 400;
  line-height: 1.7;
`;

export const Input16 = styled.input<TextProps>`
  color: #1d1d1d;
  display: block;
  width: ${(props) => props.width || "100%"};
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 0.75rem;
  border: none;
  border-bottom: 2px solid #c4c4c4;
  &:focus {
    border-bottom: 2px solid #25346a;
  }
`;

export const BtnGroup2 = styled.div<TextProps>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.flexDirection || "row"};
  gap: 0.75rem;
  margin-top: auto;
  width: ${(props) => props.width || "auto"};
`;
