import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Details from "./Details";
import Contents from "./Contents";
import Bids from "./Bids";
import ImageGallery from "./ImageGallery";
import { Text3 } from "../../Wallet/Wallet.styled";
import { ADBox } from "../Adverts.styled";
import RejectRequest from "./RejectRequest";
import useOutsideClick from "../../../hooks/useOutsideClick";
// import AdBig from "../../../assets/images/ad-big.png";
// import PicAd from "../../../assets/images/Rectangle 83.png";
import { Section } from "../../Dashboard/Dashboard.styled";
import {
  useGetOneAdvertsQuery,
} from "../../../features/api/advertSlice";
import { ScaleLoader } from "react-spinners";


const FlashSales: React.FC = () => {
  const { advertId } = useParams();
  const [showRole, setShowRole] = useState(false);
  const [showRole2, setShowRole2] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const navigate = useNavigate()

  //Get the advert detail
  const { data: advertDetail, isLoading: gettingAdvert } =
    useGetOneAdvertsQuery(advertId as string);


  const modalRef = useRef(null);
  const modalRef2 = useRef(null);

  // const imagesAdvert = [AdBig, PicAd];

  const handleRejection = () => {
    setShowRole(true);
  };

  const handleCloseRole = () => {
    setShowRole(false);
    setShowRole2(false);
  };

  const handleImageSelected = (val: string) => {
    setSelectedImage(val);
    setShowRole2(true)
  }

  const handlePreviousImage = () => {
    // setSelectedImage(
    //   (prevIndex) => (prevIndex - 1 + imagesAdvert.length) % imagesAdvert.length
    // );
  };

  const handleNextImage = () => {
    // setSelectedImage((prevIndex) => (prevIndex + 1) % imagesAdvert.length);
  };

  useOutsideClick(modalRef, handleCloseRole);
  useOutsideClick(modalRef2, handleCloseRole);

  return (
    <Section>
      <div>
        <Text3>

          <span className="pointer" onClick={() => navigate('/home/adverts')}>Advert  </span>
          <span className="bank-transfer">-
            {gettingAdvert ?
              <ScaleLoader
                loading={gettingAdvert}
                height={12}
                width={2}
                color={"#3d5170"}
              />
              :
              advertDetail?.data?.name}
          </span>
        </Text3>
        <ADBox>
          <Details handleRejection={handleRejection} />
          <Contents handleImage={handleImageSelected} />
          <Bids />
        </ADBox>
      </div>
      <div>
        <ImageGallery
          showRole={showRole2}
          handleCloseRole={handleCloseRole}
          modalRef={modalRef}
          selectedImage={selectedImage}
          handlePreviousImage={handlePreviousImage}
          handleNextImage={handleNextImage}
          handleImage={handleImageSelected}
        />
        <RejectRequest
          showRole={showRole}
          handleCloseRole={handleCloseRole}
          modalRef={modalRef2}
        />
      </div>
    </Section>
  );
};

export default FlashSales;
