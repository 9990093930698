export const COLOR_WHITE = "#fff";
export const COLOR_BLACK = "#000";
export const COLOR_BLUE_BACKGROUND = "#232323";
export const COLOR_YELLOW = "#e5b909";
export const COLOR_GRAY = "#3e3e3e";
export const COLOR_GRAY2 = "#a2a2a2";
export const COLOR_LIGHT_GRAY = "#c4c4c4";
export const COLOR_DARK_GRAY = "#333333";
export const COLOR_BLUE = "#25346a";
export const COLOR_BG_SIDEBAR = "#FAFAFA";
export const COLOR_RED = "#FF1E1E";
export const COLOR_BOXSHADOW = "#0d0a2c14";
export const COLOR_BORDER_GRAY = "#c4c4c433";
export const COLOR_BORDER_2 = "#e8e8e8";
export const COLOR_H4 = "#6b6b6b";
export const COLOR_PARA1 = "#1a1a1a";
export const COLOR_LABEL = "#757575";

export const WICRYPT_BLUE = "#25346A";
export const WICRYPT_YELLOW = "#E5B910";
export const WICRYPT_WHITE = "F4F4F4";
export const WICRYPT_BLACK = "#1D1D1D";
export const WICRYPT_BLUE_INFO = "#2F80ED";
export const WICRYPT_GREEN_SUCCESS = "#27AE60";
export const WICRYPT_YELLOW_WARNING = "#CFA809";
export const WICRYPT_ERROR = "#DB5F5F";
export const WICRYPT_GREY = "#c4c4c4";
export const WICRYPT_DARK_GREY = "#6b6b6b";
export const WICRYPT_PURE_WHITE = "#FFFFFF";
export const WICRYPT_BODY = "#29313C";
export const WICRYPT_DARK_BG = "#1F2630";

export const WICRYPT_BORDER_LINE = "#efefef";
