import React, { useState } from "react";
import { MainBox } from "../Account/Account.styled";
import { Form } from "../Authentication/styles/Container.styled";
import { Input2, Label2, Walletbtn } from "./Wallet.styled";
import TableMain from "../Tables/TableMain";
import { columnsEarnings } from "../Tables/TableData";
import { TabComponent } from "../Reusable/types";
import { useGetTransactionsQuery } from "../../features/api/walletSlice";
import { CardLoader } from "../Reusable/CardLoader";
import EmptyBox from "../Reusable/EmptyBox";

const Wallet = ({ show }: TabComponent) => {

  const [walletReference, searchByReference] = useState<string>("");

  const { data: transactions, isLoading } = useGetTransactionsQuery({
    status: "",
    transactionType: "1",
    search: walletReference
  });


  const greenColor = "#00C087";
  if (!show) return <></>;
  return (
    <div>
      <MainBox>
        <Form>
          <Label2 htmlFor="search">Search</Label2>
          <Input2 type="text" id="search" value={walletReference} onChange={(e: any) => searchByReference(e.target.value)} placeholder="Reference" />
        </Form>
        {isLoading ? <CardLoader /> :
          (transactions?.data.length || 0) === 0 ?
        <EmptyBox message={"No earnings found!"} /> :
          <div style={{ textAlign: "center" }}>
          <TableMain
            defaultData={transactions?.data || []}
            columns={columnsEarnings}
            greenColor={greenColor}
          />
        </div>}
        
        {/* <Walletbtn>
          <button>Load more</button>
        </Walletbtn> */}
      </MainBox>
    </div>
  );
};

export default Wallet;
