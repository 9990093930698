import styled from "styled-components";
import { COLOR_YELLOW } from "../../styles/themes/colors";

export const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 22px;
  gap: 30px;
  border-radius: 8px;
  border: 1px solid rgba(37, 52, 106, 0.1);
  background: #fff;
`;

export const DetailList = styled.div`
  display: flex;
  padding: 0.8rem 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #eae9ed;
`;

export const ImageBox = styled.div`
  border-radius: 25px;
  border: 1.5px solid #c4c4c4;
`;

export const ImageBox2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const BtnReset = styled.button`
  background: none;
  border: none;
  padding: 0.3rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: #f5f5f8;
    border-radius: 5px;
  }
`;

export const AmountSection = styled.div`
  margin: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  span{
    color: ${COLOR_YELLOW};
  }
  input{
    color: var(--primary-blue, #25346A);
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
export const AvailableBalance = styled.h5`
  color: var(--primary-blue, #25346A);
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`