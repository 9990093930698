import React, { useState, useRef } from "react";
import { DropdownContainer, GraphTab, Section } from "../Dashboard/Dashboard.styled";
import { Button, ButtonReset} from "../Reusable/Buttons/button";
import { AccountTitle, MainBox } from "../Account/Account.styled";
import {
  WalletBox,
  WalletOuterBox,
  InnerWalletBox,
  WalletSelect,
} from "./Wallet.styled";
import Earnings from "./Earnings";
import WithdrawModal from "./WithdrawModal";
import Withdraws from "./Withdraws";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useGetNetworkDetailQuery } from "../../features/api/networkSlice";
import { numberWithCommasWithoutDecimal } from "../../helpers";
import { ScaleLoader } from "react-spinners";
import ArrowYellow from "../../assets/icons/Wallet/arrow-yellow.svg";
import { useNetworkEarningsQuery } from "../../features/api/walletSlice";


const Wallet: React.FC = () => {
  const [showRole, setShowRole] = useState(false);
  const [periodStatus, setPeriodStatus] = useState(false);
  const [period, setPeriod] = useState<number>(1);
  const [showTabs, setShowTabs] = useState({ earnings: true, withdraw: false });

  const {data: networkDetail, isLoading: gettingWalletDetail} = useGetNetworkDetailQuery();
  const {data: networkEarnings} =  useNetworkEarningsQuery(period);

  const modalRef = useRef<HTMLElement>(null);

  const handleRole = () => {
    setShowRole(true);
  };

  const handleCloseRole = () => {
    setShowRole(false);
  };

  useOutsideClick(modalRef, handleCloseRole);

  const handleEarnings = () => {
    setShowTabs({ earnings: true, withdraw: false });
  };
  const handleWithdraw = () => {
    setShowTabs({ earnings: false, withdraw: true });
  };

  const handleExpensePeriod = (val: number) =>{
    setPeriodStatus(!periodStatus);
    setPeriod(val)
  }
  return (
    <Section>
      <WalletOuterBox>
        <WalletBox>
          <InnerWalletBox>
            <h4>Available Balance</h4>
            <p>₦{gettingWalletDetail? 
            <ScaleLoader
              loading={gettingWalletDetail}
              height={12}
              width={2}
              color={"#3d5170"}
            /> 
            :  numberWithCommasWithoutDecimal(networkDetail?.data?.wallet_balance || 0)}</p>
          </InnerWalletBox>
          <div>
            <Button
              text="Withdraw Balance"
              fontSizeProps="0.875rem"
              onClick={handleRole}
              disabled={(networkDetail?.data?.wallet_balance || 0) === 0}
            />
          </div>
        </WalletBox>
        <WalletBox>
          <InnerWalletBox>
            <h4>Total Earnings</h4>
            <p>₦{gettingWalletDetail? 
            <ScaleLoader
              loading={gettingWalletDetail}
              height={12}
              width={2}
              color={"#3d5170"}
            /> 
            : 
            numberWithCommasWithoutDecimal(networkEarnings?.data || 0)}</p>
          </InnerWalletBox>

          <WalletSelect className="pointer relative" onClick={()=> setPeriodStatus(!periodStatus)}>
            <ButtonReset>{period === 1 ? "Daily" : period === 2 ? "Weekly" : "Monthly"}</ButtonReset>
            <img src={ArrowYellow} alt="" />
            {periodStatus && <DropdownContainer className="dropdown">
            <p className="pointer dropdown-item" onClick={() => handleExpensePeriod(1)}> Daily</p>
            <p className="pointer dropdown-item" onClick={() => handleExpensePeriod(2)}> Weekly</p>
            <p className="pointer dropdown-item" onClick={() => handleExpensePeriod(3)}> Monthly</p>
              </DropdownContainer>}
          </WalletSelect>
          
        </WalletBox>
      </WalletOuterBox>
      <AccountTitle>Transaction History</AccountTitle>
      <MainBox>
        <GraphTab widthGraphBox="fit-content">
          <button
            onClick={handleEarnings}
            className={`${showTabs.earnings ? "active" : ""}`}
          >
            Earnings
          </button>
          <button
            onClick={handleWithdraw}
            className={`${showTabs.withdraw ? "active" : ""}`}
          >
            Withdrawals
          </button>
        </GraphTab>
        <Earnings show={showTabs.earnings} />
        <Withdraws show={showTabs.withdraw} />
        <WithdrawModal
          show={showRole}
          handleCloseModal={handleCloseRole}
          modalRef={modalRef}
        />
      </MainBox>
    </Section>
  );
};

export default Wallet;
