import { MouseEvent } from "react";
import {
  RoleInnerBox,
  RoleBoxMain,
  BtnGroup,
} from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { Box6, Box8, Input2, Label2, WalletForm } from "./Wallet.styled";
import ArrowYellow from "../../assets/icons/Wallet/arrow-yellow.svg";
import { SetRoleProps } from "../../types";

const FilterGroup: React.FC<SetRoleProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
}) => {
  const handleRole = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
  };
  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <RoleInnerBox padding="1rem" widthBox="auto">
        <WalletForm>
          <Box6>
            <Label2 htmlFor="filter">Withdraw Method</Label2>
            <Box8>
              <Input2
                type="text"
                id="search"
                placeholder="All"
                inputWidth="100%"
              />
              <button className="select-btn" onClick={handleRole}>
                <img src={ArrowYellow} alt="" className="select-img" />
              </button>
            </Box8>
          </Box6>
          <Box6>
            <Label2 htmlFor="filter">Duration</Label2>
            <Box8>
              <Input2
                type="text"
                id="search"
                placeholder="Default"
                inputWidth="100%"
              />
              <button className="select-btn" onClick={handleRole}>
                <img src={ArrowYellow} alt="" className="select-img" />
              </button>
            </Box8>
          </Box6>
          <BtnGroup>
            <Button
              text="Apply filter"
              fontSizeProps="0.875rem"
              paddingProps="0.47rem"
            />
            <Button
              text="Cancel"
              variant="outline"
              fontSizeProps="0.875rem"
              onClick={handleCloseRole}
              paddingProps="0.47rem"
            />
          </BtnGroup>
        </WalletForm>
      </RoleInnerBox>
    </RoleBoxMain>
  );
};

export default FilterGroup;
