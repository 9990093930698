import styled from "styled-components";

interface ErrProps {
  errMsg: string;
}

const ErrorText = styled.p`
  text-align: left;
  color: #f04438;
  font-weight: 500;
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

export const ErrorMessage = ({ errMsg }: ErrProps) => {
  return <ErrorText data-testid="error-message">{errMsg}</ErrorText>;
};
