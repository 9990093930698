import React, { useState } from "react";
import AuthSuccess from "./authsuccess";
import Reset1 from "./reset1";
import Reset2 from "./reset2";

const Reset: React.FC = () => {
    const [email, setEmail] = useState("");
    const [step, setStep] = useState<number>(1);

    const renderResetFlow = () => {
        switch (step) {
            case 1:
                return (
                    <Reset1 setEmail={setEmail} email={email} handleNext={setStep} />
                )

            case 2:
                return (
                    <Reset2 email={email} handleNext={setStep} />
                )
            case 3:
                return(
                    <AuthSuccess email={email} title= "Password Reset Successful!" />
                )

        }

    }
    return (
        <>
            {renderResetFlow()}
        </>
    );
};

export default Reset;
