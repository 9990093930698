import React from "react";
import { BackArrow } from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
} from "../Dashboard/Dashboard.styled";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import { Button } from "../Reusable/Buttons/button";
import { MarginBottom, WalletInnerBox } from "./Wallet.styled";
import { Link } from "react-router-dom";
import { ModalMainProps } from "../../types";

const WithdrawModal: React.FC<ModalMainProps> = ({
  show,
  handleCloseModal,
  modalRef,
}) => {
  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer>
        <BackArrow justifyContent alignItems>
          <div className="back">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Withdraw Balance</ModalTitle>
          </div>
        </BackArrow>
        <p>Please choose a preferred withdrawal method</p>
        <div>
          <WalletInnerBox>
            <Link to={"/home/wallet/withdrawbalance"}>
              <button onClick={handleCloseModal}>
                <p>Withdraw to Bank Account</p>
                <img src={ArrowRight} alt="" />
              </button>
            </Link>
          </WalletInnerBox>
          {/* <WalletInnerBox>
            <Link to={""}>
              <button>
                <p>Pay via Paypal</p>
              </button>
            </Link>
          </WalletInnerBox> */}
        </div>
        {/* <MarginBottom>
          <Button disabled text="Proceed" />
        </MarginBottom> */}
      </ModalContainer>
    </MainContainer>
  );
};

export default WithdrawModal;
