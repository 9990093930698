import React, { useEffect, useState } from "react";
import { BackArrow, Input } from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
} from "../Dashboard/Dashboard.styled";
import Search from "../../assets/icons/Wallet/Search.svg";
import ArrowYellow from "../../assets/icons/Wallet/arrow-yellow.svg";
import { CountryBox, Form2 } from "./Wallet.styled";
import { useGetCountriesQuery } from "../../features/api/walletSlice";
import { CardLoader } from "../Reusable/CardLoader";
import { Country } from "./types";
import { Text12 } from "../Reusable/Reuseable.styled";
import { CountryModalProps } from "../../types";

const CountryModal: React.FC<CountryModalProps> = ({
  show,
  handleCloseModal,
  modalRef,
  selectedValues,
  handleSelect,
}) => {
  const { data: allCountries, isLoading: gettingCountries } = useGetCountriesQuery();
  const [countries, setCountries] = useState<Country[]>([]);

  //This function handles searching the country list
  const handleSearch = (val: string) => {
    if (allCountries) {
      if (val === "") {
        setCountries(allCountries?.data);
      } else {
        const result = (allCountries?.data).filter((country) =>
          country.name.toLowerCase().startsWith(val.toLowerCase())
        );
        setCountries(result);
      }
    }
  };

  useEffect(() => {
    if (allCountries) {
      setCountries(allCountries?.data);
    }
  }, [allCountries]);

  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer gapSpace="1.875rem">
        <BackArrow>
          <div className="back-wallet">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Select Country</ModalTitle>
          </div>
        </BackArrow>
        <Form2>
          <img src={Search} alt="" className="img-size" />
          <Input
            type="text"
            placeholder="search countries"
            className="input-padding"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Form2>
        {selectedValues && (
          <Text12 color="#6b6b6b">
            Country Selected
          </Text12>
        )}
        <div>
          {gettingCountries ? 
            <CardLoader /> :
          countries?.map(country => {
            return (
              <CountryBox  onClick={() => {handleSelect(country); handleCloseModal()}}>
                <div className="flex">
                  <img src={country.flag} alt="Country's flag" />
                  <h4 className="title">{country.name}</h4>
                </div>
                <img src={ArrowYellow} alt="" />
              </CountryBox>
            )
          })}


        </div>
      </ModalContainer>
    </MainContainer>
  );
};

export default CountryModal;
