import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../Reusable/Buttons/button";
import { Section } from "../Dashboard/Dashboard.styled";
import {
  Text10,
  Text12,
  Text14,
  TextTitle1,
  TextTitle1Span,
} from "../../styles/all.styled";
import { AccountTitle, HeadingBox} from "../Account/Account.styled";
import IdCard from "../../assets/images/id-card-drivers.svg";
import Passport from "../../assets/images/passport-placeholder.svg";
import { CardBox, DetailList, ImageBox } from "./business.styled";
import {
  AuthorizedAccess,
  ConfirmApproval,
  IdentificationDetails,
  VerifyIdentity,
} from "./BusinessModals";
import { BtnGroup2, FlexBox } from "../Reusable/Reuseable.styled";
import {
  useGetBusinesseskycQuery,
} from "../../features/api/businessesSlice";
import { BusinessKYCData } from "../../features/api/businessesSliceTypes";
import { CardLoader } from "../Reusable/CardLoader";
import { idCollection } from "../../helpers";

const BusinessDetails = () => {
  const { businessId } = useParams();
  const [showVerify, setShowVerify] = useState(false);
  const [showIdDetails, setShowIdDetails] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showAccept, setShowAccept] = useState(false);

  const [errMsg, setErr] = useState<string>("");

  const [filteredBusinessDetail, setFilteredBusinessDetail] = useState<BusinessKYCData[]>([]);

  //Get the business detail
  const { data: businessDetail, isLoading: gettingBusiness } =  useGetBusinesseskycQuery({ status: "9", search: "" });


  const navigate = useNavigate();

  const handleCloseRole = () => {
    setShowVerify(false);
    setShowIdDetails(false);
    setShowAccept(false);
    setShowReject(false);
  };

  // fallback image
  function imgError(e: any) {
    e.target.src = IdCard;
  }

  function imgErrorPic(e: any) {
    e.target.src = Passport;
  }

  useEffect(() => {
    if (businessDetail) {

      const filteredData = businessDetail?.data.filter((item: any) => {
        const idMatch = item.id == businessId;
        return idMatch;
      });

      setFilteredBusinessDetail(filteredData);
    }
  }, [businessDetail, businessId]);

  return (
    <Section>
      <div className="top">
        <div>
          <TextTitle1>
            {/* Businesses -  */}
            <span 
              className="pointer" 
              onClick={()=> navigate('/home/businesses')}> 
              Requests -{" "}
            </span>
            <TextTitle1Span>
              {/* Emmanuel Bassey */}
              {filteredBusinessDetail[0]?.user?.first_name
                ? filteredBusinessDetail[0]?.user?.first_name
                    .charAt(0)
                    .toUpperCase() +
                  filteredBusinessDetail[0]?.user?.first_name.slice(1)
                : ""}{" "}
              {filteredBusinessDetail[0]?.user?.last_name}
            </TextTitle1Span>
          </TextTitle1>
        </div>
        <HeadingBox>
          <AccountTitle>Personal Details</AccountTitle>
          <span className="line"></span>
          <BtnGroup2>
            <Button
              text="Reject"
              variant="outline"
              onClick={() => setShowReject(true)}
              widthProps="9rem"
              paddingProps="0.5rem"
            />

            <Button
              text="Accept"
              widthProps="9rem"
              onClick={() => setShowAccept(true)}
              paddingProps="0.5rem"
            />
          </BtnGroup2>
        </HeadingBox>
      </div>
      <FlexBox
        gap="1rem"
        justifyContent="flex-start"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <CardBox>
          <Text12 fontSize="1rem" fontWeight="600">
            ID Card
          </Text12>
          <FlexBox flexDirection="column" gap="3rem" alignItems="stretch">
            <img
              src={filteredBusinessDetail[0]?.identity_photo_url}
              alt="ID card"
              width={280}
              height={160}
              onError={imgError}
            />
            <div>
              <DetailList>
                <Text10 lineHeight="1.4" fontSize="0.75rem">
                  First Name
                </Text10>
                <Text14 color="#1a1a1a" fontWeight="600">
                  {filteredBusinessDetail[0]?.user?.first_name
                    ? filteredBusinessDetail[0]?.user?.first_name
                        .charAt(0)
                        .toUpperCase() +
                      filteredBusinessDetail[0]?.user?.first_name.slice(1)
                    : ""}{" "}
                </Text14>
              </DetailList>
              <DetailList>
                <Text10 lineHeight="1.4" fontSize="0.75rem">
                  Last Name
                </Text10>
                <Text14 color="#1a1a1a" fontWeight="600">
                  {filteredBusinessDetail[0]?.user?.last_name
                    ? filteredBusinessDetail[0]?.user?.last_name
                        .charAt(0)
                        .toUpperCase() +
                      filteredBusinessDetail[0]?.user?.last_name.slice(1)
                    : ""}{" "}
                </Text14>
              </DetailList>
              {/* <DetailList>
                <Text10 lineHeight="1.4" fontSize="0.75rem">
                  Business Name
                </Text10>
                <Text14 color="#1a1a1a" fontWeight="600">
                  Casadesigns
                </Text14>
              </DetailList> */}
              <DetailList style={{borderBottom: "none"}} >
                <Text10 lineHeight="1.4" fontSize="0.75rem">
                  ID type
                </Text10>
                <Text14 color="#1a1a1a" fontWeight="600">
                  {idCollection[Number(filteredBusinessDetail[0]?.identity_type)]}
                </Text14>
              </DetailList>
            </div>
            {/* <MarginBox margin="0 auto">
              <Button
                text="Verify Identity"
                variant="outline"
                onClick={() => setShowVerify(true)}
                widthProps="9rem"
                paddingProps="0.5rem"
              />
            </MarginBox> */}
          </FlexBox>
        </CardBox>
        <CardBox>
          <Text12 fontSize="1rem" fontWeight="600">
            Passport Selfie
          </Text12>
          <ImageBox>
            {gettingBusiness ? (
              <div style={{ width: "300px", height: "237px" }}>
                <CardLoader />
              </div>
            ) : (
              <img
                src={filteredBusinessDetail[0]?.selfie_url}
                alt="passport"
                width={300}
                height={237}
                onError={imgErrorPic}
              />
            )}
          </ImageBox>
        </CardBox>
      </FlexBox>
      {showVerify && (
        <VerifyIdentity
          handleCloseRole={handleCloseRole}
          handleNext={() => {
            setShowVerify(false);
            setShowIdDetails(true);
          }}
          errMsg={errMsg}
          isLoading={false}
        />
      )}
      {showIdDetails && (
        <IdentificationDetails handleCloseRole={handleCloseRole} />
      )}
      {showAccept && <ConfirmApproval handleCloseModal={handleCloseRole} />}
      {showReject && <AuthorizedAccess handleCloseRole={handleCloseRole} />}
    </Section>
  );
};

export default BusinessDetails;
