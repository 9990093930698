import React from "react";
import { Tab } from "./Dashboard.styled";
import { NavigationProps } from "./types";

const NavTab: React.FC<NavigationProps> = ({
  icon,
  title,
  to,
  handleSelect,
}) => {
  return (
    <Tab to={to} onClick={handleSelect}>
      {/* <img src={image} alt="" /> */}
      <div className="nav-icon">{icon}</div>
      <p>{title}</p>
    </Tab>
  );
};

export default NavTab;
