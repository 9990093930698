import React, { useState } from "react";
import { BtnGroup } from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import {
  ErrorMsg,
  Form,
  Input,
  Label,
  MarginTop,
} from "../Authentication/styles/Container.styled";
import { BsEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import {
  AuthorizedAccessBeneficiariesProps,
  AuthorizedAccessProps,
} from "./type";
import { AccessModal } from "../Reusable/Modal";

const AuthorizedAccess = ({
  handleCloseRole,
  handleDeleteAccount,
  errMsg,
  isLoading,
  password,
  setPassword,
}: AuthorizedAccessProps) => {
  const [visibilityStatus, toggleVisibility] = useState<boolean>(false);

  return (
    <AccessModal
      title="Deactivate Account"
      description="To completely wipe your data, press Delete. You will not be able to create an account with this email if you do this"
      close={true}
      handleCloseModal={handleCloseRole}
    >
      <div>
        <Form className="form-margin">
          <div className="formControlPassword">
            <Label htmlFor="password" textAlign="left">
              Enter Password
            </Label>
            <Input
              type={visibilityStatus ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder=""
            />
            <span
              onClick={() => toggleVisibility(!visibilityStatus)}
              className="eye"
            >
              {visibilityStatus ? (
                <BsFillEyeFill className="" />
              ) : (
                <BsEyeSlashFill className="" />
              )}
            </span>
          </div>
        </Form>
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
        <BtnGroup>
          <Button text="Cancel" variant="outline" onClick={handleCloseRole} />
          <Button
            text="Delete"
            BGProps="#FF1E1E"
            height="40px"
            isLoading={isLoading}
            onClick={handleDeleteAccount}
          />
        </BtnGroup>
      </div>
    </AccessModal>
  );
};

export default AuthorizedAccess;

// export const AuthorizedAccessBeneficiaries: React.FC<SetRoleProps> = ({
//   showRole,
//   handleCloseRole,
//   modalRef,
//   handleDeleteAccount,
// }) => {
//   return (
//     <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
//       <div className="role-class">
//         <RoleInnerBox widthBox="30.75rem">
//           <FlexBox justifyContent="flex-end">
//             <button className="cancelbtn" onClick={handleCloseRole}>
//               x
//             </button>
//           </FlexBox>
//           <div className="heading">
//             <h2>Delete Beneficiary</h2>
//             <BoxWidth width="18rem">
//               <p>
//                 You are about to delete this beneficiary Do you really want to?
//               </p>
//             </BoxWidth>
//           </div>
//           <MarginTop marginTop="1.5rem">
//             <BtnGroup>
//               <Button
//                 text="Cancel"
//                 variant="outline"
//                 onClick={handleCloseRole}
//               />
//               <Button
//                 text="Yes, Delete"
//                 BGProps="#FF1E1E"
//                 onClick={handleDeleteAccount}
//               />
//             </BtnGroup>
//           </MarginTop>
//         </RoleInnerBox>
//       </div>
//     </RoleBoxMain>
//   );
// };

export const AuthorizedAccessBeneficiaries = ({
  handleCloseRole,
  handleDeleteAccount,
  errMsg,
  isLoading,
}: AuthorizedAccessBeneficiariesProps) => {
  return (
    <AccessModal
      title="Delete Beneficiary"
      description="You are about to delete this beneficiary Do you really want to?"
      close={true}
      handleCloseModal={handleCloseRole}
    >
      <div>
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
        <MarginTop marginTop="1.5rem">
          <BtnGroup>
            <Button text="Cancel" variant="outline" onClick={handleCloseRole} />
            <Button
              text="Yes, Delete"
              BGProps="#FF1E1E"
              height="40px"
              onClick={handleDeleteAccount}
              isLoading={isLoading}
            />
          </BtnGroup>
        </MarginTop>
      </div>
    </AccessModal>
  );
};
