import MonitorImg from "../assets/images/monitor.svg";
import ManageImg from "../assets/images/manage.svg";
import EarnImg from "../assets/images/earn.svg";
import PicAd2 from "../assets/images/Rectangle 83.png";
import ADBig from "../assets/images/ad-big.png";

export const CAROUSEL_DATA = [
  {
    image: ManageImg,
    title: "Manage adverts from Business owners",
    description: "Approve and oversee adverts request on network devices",
  },
  {
    image: MonitorImg,
    title: "Monitor Adverts and Earnings",
    description: "A brief of overview of your current earnings in real-time",
  },
  {
    image: EarnImg,
    title: "Earn and Withdraw Earnings",
    description: "Seamlessly overview earning transactions and withdraw",
  },
];

export const CAROUSEL_AD_IMAGE = [
  {
    image: ADBig,
  },
  {
    image: PicAd2,
  },
];
