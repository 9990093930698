
import LoaderGif from "../../assets/images/loader.gif";
import { LoaderBox, LoaderContainer } from "./Reuseable.styled";

export const CardLoader = () => {
    return (
        <LoaderBox>
            <LoaderContainer>
                <img src={LoaderGif} alt="GIF" />
            </LoaderContainer>
        </LoaderBox>
    )
};