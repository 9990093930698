import React, { useState, useRef } from "react";
import {
  SmallBox,
  Box3,
  GraphTab,
  SideBox,
  Box3Main,
  Section,
} from "./Dashboard.styled";
import OverviewInfo from "./OverviewInfo";
import SetRole from "./SetRole";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Link } from "react-router-dom";
import { useGetDashboardOverviewQuery, useGetMinimalBidQuery } from "../../features/api/dashboardSlice";
import { numberWithCommasWithoutDecimal } from "../../helpers";
import { Graph } from "./DashboardGraph";
import EmptyBox from "../Reusable/EmptyBox";

const Dashboard: React.FC = () => {
  const [showRole, setShowRole] = useState<boolean>(false);
  const [period, setPeriod] = useState<number>(1);

  const { data: overview, isLoading: loadingOverview } =
    useGetDashboardOverviewQuery(period);

  const {data: minimumBid}  = useGetMinimalBidQuery();

  const modalRef = useRef<HTMLElement>(null);

  const handleRole = () => {
    setShowRole(true);
  };

  const handleCloseRole = () => {
    setShowRole(false);
  };

  useOutsideClick(modalRef, handleCloseRole);

  const OVERVIEW_DATA = [
    {
      title: "Total Adverts",
      value: overview?.data?.total_adverts.toString(),
      info: "Total number of adverts ongoing",
    },
    {
      title: "Total Hotspot Hubs",
      value: overview?.data?.total_hotspot_hubs.toString(),
      info: "Total number of Hotspot hubs",
    },
    {
      title: "Total Amount Generated",
      value: `₦ ${numberWithCommasWithoutDecimal(
        overview?.data?.total_amount || 0
      )}`,
      info: "Total amount generated",
    },
    {
      title: "Minimal Bid",
      value: `₦ ${numberWithCommasWithoutDecimal(
        minimumBid?.data?.minimal_bid || 0
      )}`,
      showButton: true,
      info: "Set the network's mininal bid",
    },
  ];

  return (
    <Section>
      <SideBox>
        <SmallBox>
          <h3 className="title">Overview</h3>
          <Box3Main>
            {OVERVIEW_DATA.map((item, index) => {
              return (
                <OverviewInfo
                  isLoading={loadingOverview}
                  key={index}
                  {...item}
                  handleRole={handleRole}
                />
              );
            })}
          </Box3Main>
        </SmallBox>
        <Box3>
          <h3>Income Inflow</h3>
          <GraphTab>
            <Link
              onClick={() => setPeriod(1)}
              to="/home/dashboard"
              className={period === 1 ? "active" : ""}
            >
              Daily
            </Link>
            <Link
              onClick={() => setPeriod(2)}
              to="/home/dashboard"
              className={period === 2 ? "active" : ""}
            >
              Weekly
            </Link>
            <Link
              onClick={() => setPeriod(3)}
              to="/home/dashboard"
              className={period === 3 ? "active" : ""}
            >
              Monthly
            </Link>
          </GraphTab>
        </Box3>

        {overview?.data?.amount_earned !== undefined &&
        overview?.data?.amount_earned.length >= 1 ? (
          <Graph data={overview?.data?.amount_earned || []} />
        ) : (
          <EmptyBox message="No Income flow within this duration" />
        )}
      </SideBox>
      <SetRole
        showRole={showRole}
        handleCloseRole={handleCloseRole}
        modalRef={modalRef}
      />
    </Section>
  );
};

export default Dashboard;
