import React, { useState } from "react";
import {
  Input,
  Label,
  Title,
  InnerContainer,
  Description,
  ContainerVerify,
  FlexBox,
  Form,
  ErrorMsg,
} from "./styles/Container.styled";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { BsFillEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Button } from "../Reusable/Buttons/button";
import { useResetPasswordMutation } from "../../features/api/authSlice";

interface Reset2Props {
  email: string;
  handleNext: (val: number) => void;
}
const Reset2: React.FC<Reset2Props> = ({ email, handleNext }) => {
  const [passwordType, setPasswordType] = useState<string>("password");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [otp, setOTP] = useState<string>("");
  const [errMsg, setError] = useState<string>("");

  const [passwordVisibility, setPasswordVisibility] = useState({
    password: "password",
    confirmPassword: "password",
    otp: "password", // You can change the initial value to match your requirements
  });

  //reset password
  const [resetPassword, { isLoading: resettingPassword }] =
    useResetPasswordMutation();

  const togglePassword = (name: string) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [name]:
        prev[name as keyof typeof prev] === "password" ? "number" : "password",
    }));
  };

  const handlePasswordReset = () => {
    if (!otp) {
      setError("Please enter the reset OTP that was sent to your email");
    } else if (!password) {
      setError("Please enter your new password");
    } else if (!confirmPassword) {
      setError("Please enter password again");
    } else if (password !== confirmPassword) {
      setError("Password and Confirm password must match");
    } else {
      setError("");
      let data = {
        password: password,
        email: email,
        token: otp,
        confirm_password: confirmPassword,
      };
      resetPassword(data)
        .unwrap()
        .then((res) => {
          if (res) {
            handleNext(3);
          }
        })
        .catch((err) => {
          setError(err?.data?.Message || "Something went wrong");
        });
    }
  };
  return (
    <ContainerVerify>
      <InnerContainer gap="3.75rem">
        <FlexBox>
          <Logo className="logo" width={115} height={25.578} />

          <Title>Reset Password</Title>
          <Description>Set a password for your account</Description>
          <Description marginBottom="0">
            Enter a password and secure your account
          </Description>
        </FlexBox>

        <Form>
          <div className="formControlPassword">
            <Label htmlFor="password" textAlign="left">
              Enter OTP
            </Label>
            <Input
              type={passwordVisibility.otp}
              name="otp"
              id="otp"
              placeholder=""
              value={otp}
              maxLength={4}
              onChange={(e) => setOTP(e.target.value)}
            />
            <span onClick={() => togglePassword("otp")} className="eye">
              {passwordVisibility.otp === "password" ? (
                <BsEyeSlashFill className="" />
              ) : (
                <BsFillEyeFill className="" />
              )}
            </span>
          </div>
          <div className="formControlPassword">
            <Label htmlFor="password" textAlign="left">
              Enter Password
            </Label>
            <Input
              type={passwordVisibility.password}
              name="password"
              id="password1"
              value={password}
              maxLength={4}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={() => togglePassword("password")} className="eye">
              {passwordVisibility.password === "password" ? (
                <BsEyeSlashFill className="" />
              ) : (
                <BsFillEyeFill className="" />
              )}
            </span>
          </div>
          <div className="formControlPassword">
            <Label htmlFor="password" textAlign="left">
              Confirm Password
            </Label>
            <Input
              type={passwordVisibility.confirmPassword}
              name="confirmPassword"
              id="password2"
              value={confirmPassword}
              maxLength={4}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span
              onClick={() => togglePassword("confirmPassword")}
              className="eye"
            >
              {passwordVisibility.confirmPassword === "password" ? (
                <BsEyeSlashFill className="" />
              ) : (
                <BsFillEyeFill className="" />
              )}
            </span>
          </div>
        </Form>
        <ErrorMsg> {errMsg}</ErrorMsg>
        <Button
          onClick={() => handlePasswordReset()}
          text="Set PIN"
          isLoading={resettingPassword}
          height="40px"
        />
      </InnerContainer>
    </ContainerVerify>
  );
};

export default Reset2;
