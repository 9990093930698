interface Config {
    apiUrl: string;
    appName: string;
  }
  
  let localPlatforms = [
    "localhost",
    "adverts-stg.wicrypt.com",
  ];
  
  let platform = localPlatforms.includes(window.location.hostname)
    ? "local"
    : "production";
  
  let config: Config; // Define the type of config
  
  if (platform === "local") {
    config = {
      apiUrl: 'https://adverts-api-stg.wicrypt.com/api/v1',
      appName: "Wicrypt Frontend",
    };
  } else {
    config = {
      apiUrl: 'https://adverts-api.wicrypt.com/api/v1',
      appName: "Wicrypt Frontend",
    };
  }
  
  export default config;
  