import styled from "styled-components";

export const PendBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 75vh; */
  /* height: calc(100% - 298.28px); */
  gap: 2.875rem;
  padding-top: 4rem;
  .empty-box {
    width: 530px;
  }
  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4;
    text-align: center;
  }
  .details {
    font-size: 0.875rem;
    font-weight: 400;
    color: #6b6b6b;
  }
  .btn-width {
    width: 10rem;
    margin: 3rem auto 0;
  }
  @media (max-width: 600px) {
    .bg-ad-img {
      width: 199.5px;
      height: 124.61px;
    }
    .empty-box {
      width: 80%;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3.5rem;
  margin-top: 1.875rem;

  th,
  td {
    border: 1px solid #ccc;
    padding: 1.34rem;
    border-right: none;
    border-left: none;
  }

  th {
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 1.4;
    text-transform: uppercase;
    color: #1a1a1a;
  }

  td {
    font-size: 0.875rem;
    line-height: 1.4;
  }

  tr:nth-child(2n) {
    background: #fbfbfb;
    border-bottom: 1px solid #efefef;
  }
`;

export const PicBox = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const ADBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const ADBox2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.875rem;
`;

export const ImageAD = styled.img`
  cursor: pointer;
`;
