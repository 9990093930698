import React, { useEffect, useState } from "react";
import {
  Title,
  InnerContainer,
  Description,
  ContainerVerify,
  FlexBox,
  Input,
  // BackButtonContainer,
} from "./styles/Container.styled";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { Button } from "../Reusable/Buttons/button";
import ProgressBar from "./progressBar";
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg";
import toast from "react-hot-toast";
import { useTwofaSetupMutation } from "../../features/api/authSlice";
// import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import { ScaleLoader } from "react-spinners";
import { copyToClipboard } from "../../helpers";

interface ICreatePassword {
  email: string;
  setStep: (val: number)=> void
}

const TwoFA: React.FC<ICreatePassword> = ({
  email,  setStep
}) => {

  const [setup2FA, {data: twoFAdata, isLoading}] = useTwofaSetupMutation()

  const copyText = (text: string) =>{
    toast.success("Copied!");
    copyToClipboard(text)
  }

  useEffect(()=>{
    setup2FA(email)
  }, [])

  return (
    <ContainerVerify as={"section"}>
      <InnerContainer gap="3.75rem">
        <FlexBox>
          <Logo className="logo" width={115} height={25.578} />
          {/* <BackButtonContainer onClick={()=> setStep(3)}> 
              <ArrowLeft className="arrow-left" />
              <span> Back</span>
          </BackButtonContainer> */}
          <ProgressBar percentage={75} text={"3/4"} />
          <div>
            <Title marginBottom="1rem">Two factor Authentication</Title>
            <Description className="welcome" marginBottom="1rem">
              Welcome : <br/> <span className="email">{email}</span>
            </Description>
          </div>
          <Description className="desc t-center">
            Link an authenticator to your account for more security
          </Description>
          {isLoading ?
          <ScaleLoader height="12px" width="2px" />
          :<div className="flex items-center justify-center w-32 h-32 p-2 QRcode-bg mt-40">
              <div className="relative z-4">
                  <img src={`data:image/png;base64,${twoFAdata?.data?.encoded_qr_code || ""}`} alt="QRCODE"/>
             </div>
            </div>  }
          <Description className="desc mt-30">
            Download <a target="_blank" rel="no_referer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share">
            <span className="authenticator pointer"> Authenticator</span>
              </a>, add an account, 
            copy and use the 16-digit key, or scan the QR code to set up.
          </Description>
        </FlexBox>
          <div className="relative">
              <div className="copy-position">
                <CopyIcon className="pointer" onClick={()=> copyText(twoFAdata?.data?.secret_key || "")} />
              </div>
            <Input type="text"  className="pl-20" value={twoFAdata?.data?.secret_key} disabled />
          </div>
        {!isLoading &&<Button
          text="Continue"
          onClick={() => setStep(5)}
          height="40px"
        />}
      </InnerContainer>
    </ContainerVerify>
  );
};

export default TwoFA;
