import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Label,
  Form,
  ErrorMsg,
} from "../Authentication/styles/Container.styled";
import { Button, ButtonReset } from "../Reusable/Buttons/button";
import {
  AccountTitle,
  HeadingBox,
  MarginBox,
  SecurityForm,
} from "./Account.styled";
import { FormBox } from "../Wallet/Wallet.styled";
import { Banks } from "../../features/api/walletSliceTypes";
import BankModal from "../Wallet/BankModal";
// import DeleteIcon from "../../assets/icons/delete.svg";
import AuthCode from "../Account/AuthCode";
import { useGetFundingDetailsQuery, useUpdateFundingDetailsMutation } from "../../features/api/walletSlice";
import useOutsideClick from "../../hooks/useOutsideClick";
import { NetworkChainId, NetworkChains } from "../../helpers";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrowDown.svg";
import { DropdownContainer } from "../Dashboard/Dashboard.styled";
import { CardLoader } from "../Reusable/CardLoader";

const PaymentAccounts = () => {
  const [selectedBankName, selectBankName] = useState<string>();
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [chain_id, setChainId] = useState<string>('');
  const [fundingId, setId] = useState<number>(0);
  const [showBank, setShowBank] = useState(false);
  const [errMsg, setErr] = useState<string>("");
  const [authCode, setAuthCode] = useState<string>("");


  const [twoFA, show2FA] = useState<boolean>(false);
  const [networkModal, showNetworks] = useState<boolean>(false);

  const modalRef = useRef<HTMLElement>(null);
  const { data: fundingDetails, isLoading: gettingFundingDetails } = useGetFundingDetailsQuery()
  const [updateFundDetails, { isLoading: updatingFundDetails }] = useUpdateFundingDetailsMutation()

  useEffect(() => {

    if (fundingDetails) {
      setAccountNumber(fundingDetails?.data?.account_number);
      setAccountName(fundingDetails?.data?.account_name);
      setWalletAddress(fundingDetails?.data?.wallet_address);
      selectBankName(fundingDetails?.data?.bank_name);
      setId(fundingDetails?.data?.id);
      setChainId(fundingDetails?.data?.chain_id)
    }
  }, [fundingDetails])

  const handleFundingDetail = (twoFACode: string) => {
    setErr("")
    let data = {
      id: fundingId,
      wallet_address: walletAddress,
      chain_id: chain_id,
      account_name: accountName,
      account_number: accountNumber,
      bank_name: selectedBankName as string,
      two_factor_code: twoFACode
    }
    updateFundDetails(data)
      .unwrap()
      .then((res) => {
        setErr("")
        if (res) {
          show2FA(false);

        }
      })
      .catch((err) => {
        setErr(err?.data?.Message || "Something went wrong")
      })
  }

  const handleCloseModal = () => {
    show2FA(false);
    setErr("")
  };
  const modalRef2 = useRef<HTMLElement>(null);

  useOutsideClick(modalRef2, handleCloseModal);

  const handBankName = (selectBank: Banks) => {
    selectBankName(selectBank.name)
  }

  return (
    <>
      {gettingFundingDetails ? (
        <CardLoader/>
      ) :
        <div>
          <HeadingBox>
            <AccountTitle>Bank Details</AccountTitle>
            <span className="line"></span>
            <div className="group-btn" style={{ display: "flex", gap: "1rem" }}>
              <Button
                text="Save Changes"
                onClick={() => show2FA(true)}
                paddingProps="0.66rem 1.5rem"
                widthProps="auto"
              />
              {/* <ButtonReset>
            <img src={DeleteIcon} alt="Delete Icon" />
          </ButtonReset> */}
            </div>
          </HeadingBox>

          <SecurityForm>
            <Form>
              <FormBox>
                <Label textAlign="left">Bank Name</Label>
                <Input type="text" disabled value={selectedBankName} />
                <button className="balance-btn" onClick={(e) => { e.preventDefault(); setShowBank(true) }}>
                  change
                </button>
              </FormBox>
              <FormBox>
                <Label textAlign="left">Account Number</Label>
                <Input
                  type="text"
                  value={accountNumber}
                  onChange={(e) => { setAccountNumber(e.target.value); }}
                />
              </FormBox>
              <FormBox>
                <Label textAlign="left">Account Name</Label>
                <Input
                  type="text"
                  value={accountName}
                  onChange={(e) => { setAccountName(e.target.value) }}
                />
              </FormBox>
            </Form>
            {errMsg && (
              <MarginBox margin="-1rem 0 1rem 0">
                <ErrorMsg> {errMsg}</ErrorMsg>
              </MarginBox>
            )}

          </SecurityForm>

          <HeadingBox className="mt-40">
            <AccountTitle>Crypto Account Details</AccountTitle>
            <span className="line"></span>
          </HeadingBox>
          <SecurityForm>
            <Form>
              <FormBox>
                <Label textAlign="left">Wallet Address</Label>
                <Input type="text" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />
                {/* <button className="balance-btn">
                  Copy
                </button> */}
              </FormBox>
              <FormBox>
                <Label textAlign="left">Network </Label>
                <Input
                  className="pointer"
                  type="text"
                  value={NetworkChainId[chain_id]}
                  onChange={(e) => { setAccountNumber(e.target.value); }}
                />
                <ArrowDown className="balance-user" onClick={() => showNetworks(true)} />
              </FormBox>
              {networkModal && <DropdownContainer>
                {NetworkChains?.map((each) => {
                  return (<p className="pointer" onClick={() => {setChainId(each.key); showNetworks(false)}}> {each.network}</p>)
                })}
              </DropdownContainer>}
            </Form>
            {errMsg && (
              <MarginBox margin="-1rem 0 1rem 0">
                <ErrorMsg> {errMsg}</ErrorMsg>
              </MarginBox>
            )}

          </SecurityForm>
          <BankModal
            show={showBank}
            handleCloseModal={() => setShowBank(false)}
            modalRef={modalRef}
            handleSelect={handBankName}
          />
          <AuthCode
            showRole={twoFA}
            handleCloseRole={handleCloseModal}
            modalRef={modalRef2}
            setAuthCode={setAuthCode}
            handleNext={handleFundingDetail}
            isLoading={updatingFundDetails}
            err={errMsg}
          />
        </div>}
    </>
  );
};

export default PaymentAccounts;
