import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  Description,
  Title,
  ContainerVerify,
  Box,
  InputNum,
  InputBox,
  BackArrow,
  ErrorMsg,
} from "./styles/Container.styled";
import { Button } from "../Reusable/Buttons/button";

interface IVerify {
  goBack: (val: number) => void;
  loading: boolean;
  verifyOTP: (data: { token: string; email: string }) => void;
  email: string;
  err: string;
}

const Verify: React.FC<IVerify> = ({
  goBack,
  loading,
  verifyOTP,
  email,
  err,
}) => {
  const [errMsg, setError] = useState<string>("");
  const [pin, setPin] = useState<Array<string | null>>([
    null,
    null,
    null,
    null,
  ]);
  const [focusIndex, setFocusIndex] = useState(0);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleDigit = (val: string, idx: number) => {
    if (val.length > 1) {
      val = val.slice(0, 1);
    }
    setPin((pre) => pre.map((e, id) => (idx === id ? val : e)));

    if (val.length === 1) {
      const nextIndex = idx + 1;
      inputRefs.current[nextIndex]?.focus();
    }
  };

  const handleKeyDown = (key: string, idx: number) => {
    if (key === "Backspace" && pin[idx] === "") {
      const preIndex = idx - 1;
      inputRefs.current[preIndex]?.focus();
    }
  };

  const handleFocus = (idx: number) => {
    setFocusIndex(idx);
  };

  useEffect(() => {
    // Focus on the first input box when the component mounts
    inputRefs.current[0]?.focus();
  }, []);

  const handleSubmit = () => {
    let stringPin = pin.join("");
    if (stringPin.length !== 4) {
      setError("Please enter a valid pin");
    } else {
      let data = {
        email: email,
        token: stringPin,
      };
      verifyOTP(data);
    }
  };

  return (
    <ContainerVerify className="justify">
      <Box>
        <Logo className="logo" width={115} height={25.578} />

        <div className="details">
          <Title>Verify Email</Title>
          <Description className="details__para">
            Please enter the four digit pin we sent to :{" "}
            <p className="details__span">{email}</p>
          </Description>
        </div>
        <div>
          <h4>ENTER PIN</h4>

          <InputBox>
            {pin.map((val, idx) => {
              return (
                <InputNum
                  type="number"
                  name="digit1"
                  value={val || ""}
                  max={1}
                  onChange={(e) => handleDigit(e.target.value, idx)}
                  ref={(ref) => (inputRefs.current[idx] = ref)}
                  className={`${
                    val !== null && val !== "" ? "inputClr" : "inputGrey"
                  } ${idx === focusIndex ? "borderBlue" : ""}`}
                  onKeyDown={(e) => handleKeyDown(e.key, idx)}
                  onFocus={() => handleFocus(idx)}
                />
              );
            })}
          </InputBox>
        </div>
        <ErrorMsg> {errMsg || err}</ErrorMsg>
        <Button
          text="Submit"
          isLoading={loading}
          height="40px"
          onClick={handleSubmit}
        />

        <BackArrow onClick={() => goBack(0)}>
          <div className="back">
            <ArrowLeft />
            <span>Back</span>
          </div>
        </BackArrow>
      </Box>
    </ContainerVerify>
  );
};

export default Verify;
