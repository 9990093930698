import React, { useState } from "react";
import {
  Container,
  Input,
  Label,
  Terms,
  CreateLink,
  WrapperLeft,
  InnerContainer,
  Description,
  Form,
  Title,
  ErrorMsg,
  MarginTop,
} from "./styles/Container.styled";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { Link } from "react-router-dom";
import { Button } from "../Reusable/Buttons/button";
import WrapperRightSide from "./WrapperRightSide";

interface ICreateAccount {
  createAccount: () => void;
  err: string;
  loading: boolean;
  email: string;
  setEmail: (val: string) => void;
  network_name: string;
  setNetwork: (val: string) => void;
}
const CreateAccount = ({
  createAccount,
  err,
  loading,
  email,
  network_name,
  setEmail,
  setNetwork,
}: ICreateAccount) => {
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [errMsg, setError] = useState<string>("");

  const handleSubmit = () => {
    if (!email) {
      setError("Please enter a valid email");
    } else if (!network_name) {
      setError("Please enter a valid network name");
    } else if (!isAgree) {
      setError("Please accept the terms and conditions to proceed");
    } else {
      setError("");
      createAccount();
    }
  };

  return (
    <Container>
      <WrapperLeft>
        <InnerContainer gap="1.875rem">
          <div>
            {/* <img className="logo" src="/Assets/icon/wicrypt.svg" alt="logo" /> */}
            <Logo className="logo" width={115} height={25.578} />
            <div className="">
              <Title>Create Account</Title>
              <Description>
                Sign up now to take advantage of the fantastic services we
                provide.
              </Description>
              {/* <ButtonGoogle>
                                <img
                                src="/Assets/icon/google.svg"
                                alt=""
                                className="googleImg"
                                />
                                <span className="button-text">Sign in to Google</span>
                            </ButtonGoogle> */}
            </div>
          </div>
          {/* <Alternative> Or</Alternative> */}
          <Form>
            <div>
              <Label htmlFor="email" textAlign="left">
                Email Address
              </Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="email" textAlign="left">
                Network name
              </Label>
              <Input
                type="text"
                name="network_name"
                id="network_name"
                value={network_name}
                onChange={(e) => setNetwork(e.target.value)}
              />
            </div>
          </Form>
          <Terms>
            <input
              type="radio"
              name="terms"
              id="terms"
              checked={isAgree}
              onChange={() => setIsAgree(!isAgree)}
            />
            <Label htmlFor="terms" textAlign="left">
              I agree to the
              <a
                href="https://wicrypt.com/terms-condition.html"
                target="_blank"
                className="reset"
              >
                {" "}
                Terms and Conditions
              </a>
            </Label>
          </Terms>
          <ErrorMsg> {errMsg || err}</ErrorMsg>
          <MarginTop marginTop="-1rem">
            <Button
              isLoading={loading}
              text="Sign up"
              onClick={() => handleSubmit()}
              height="40px"
            />
          </MarginTop>

          <CreateLink className="">
            Already have an account?
            <Link to={"/"} className="create">
              <span> Sign in</span>
            </Link>
          </CreateLink>
        </InnerContainer>
      </WrapperLeft>
      <WrapperRightSide />
    </Container>
  );
};

export default CreateAccount;
