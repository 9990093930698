import Login from "../components/Authentication/login";
import Signup from "../components/Authentication/signup";
import GlobalStyle from "../styles/base/global";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/themes/lightTheme";
import Dashboard from "../components/Dashboard/Dashboard";
import Adverts from "../components/Adverts/Adverts";
import Account from "../components/Account/Account";
import Wallet from "../components/Wallet/Wallet";
import Home from "../pages/Home";
import WithdrawBalance from "../components/Wallet/WithdrawBalance";
import FlashSales from "../components/Adverts/FlashSales/FlashSales";
import { Toaster } from "react-hot-toast";
import Reset from "../components/Authentication/reset";
import Businesses from "../components/Businesses";
import Networks from "../components/Networks";
import BusinessDetails from "../components/Businesses/Details";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
          <GlobalStyle />
          <Toaster />
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset" element={<Reset />} />
            {/* Private Routes */}
            <Route
              path="/home"
              element={
                <ProtectedRoute redirectPath="/">
                  <Home />
                </ProtectedRoute>
              }
            >
              <Route
                path="/home"
                element={<Navigate replace to="dashboard" />}
              />
              <Route path="dashboard" element={<Dashboard />}>
                <Route
                  path="/home/dashboard"
                  element={<Navigate replace to="daily" />}
                />
              </Route>
              <Route path="adverts" element={<Adverts />} />
              <Route path="account" element={<Account />} />
              <Route path="wallet" element={<Wallet />} />
              <Route
                path="wallet/withdrawbalance"
                element={<WithdrawBalance />}
              />
              <Route path="adverts/:advertId" element={<FlashSales />} />
              <Route path="businesses" element={<Businesses />} />
              <Route path="networks" element={<Networks />} />
              <Route
                path="businesses/:businessId"
                element={<BusinessDetails />}
              />
            </Route>
            {/* Redirect to login page for unknown routes */}
            <Route path="*" element={<Navigate to="/" replace />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
