import React from "react";
import { columnsAd } from "../Tables/TableData";
import { Advert } from "./types";
import TableMain from "../Tables/TableMain";
import EmptyBox from "../Reusable/EmptyBox";

interface PendingAds {
  pendingAdverts: Advert[];
}
const greenColor = "#1A1A1A";
const Pending: React.FC<PendingAds> = ({
  pendingAdverts,
}) => {
  return (
    <>
      {pendingAdverts?.length > 0 ? (
        <div>
          <div style={{ textAlign: "left" }}>
            <TableMain
              defaultData={pendingAdverts || []}
              columns={columnsAd}
              greenColor={greenColor}
            />
            {/* <TableMaincopy
              defaultData={defaultDataAd}
              columns={columnsAd}
              greenColor={greenColor}
            /> */}
          </div>
          {/* <Walletbtn>
            <button>Load more</button>
          </Walletbtn> */}
        </div>
      ) : (
        <EmptyBox message="No pending approval" />
      )}
    </>
  );
};

export default Pending;
