import React, { ReactElement, ReactNode, useRef } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import {
  CloseBtn,
  ModalBox,
  ModalInnerBox,
  FlexBox,
  MarginBottom,
  Text10,
  Text12,
  CloseBtn2,
} from "./Reuseable.styled";
import { RoleBoxMain2, RoleInnerBox } from "../Dashboard/Dashboard.styled";

interface ModalProps {
  handleCloseModal: () => void;
  children: ReactElement;
  title?: string;
  close?: boolean;
  description?: string;
}

export const Modal = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
}: ModalProps) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <ModalBox>
      <ModalInnerBox widthBox="26rem" padding="1.5rem">
        <FlexBox alignItems="start">
          {title && (
            <MarginBottom>
              <Text12
                as={"h1"}
                fontSize="1rem"
                fontWeight="600"
                marginBottom="0.5rem"
              >
                {title}
              </Text12>
              <Text10>{description}</Text10>
            </MarginBottom>
          )}

          {close && (
            <CloseBtn onClick={handleCloseModal}>
              <CloseIcon width={13} height={13} />
            </CloseBtn>
          )}
        </FlexBox>
        {children}
      </ModalInnerBox>
    </ModalBox>
  );
};

export const ConfirmModal = ({ handleCloseModal, children }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <ModalBox ref={modalRef}>
      <ModalInnerBox widthBox="26rem" padding="2.5rem">
        {children}
      </ModalInnerBox>
    </ModalBox>
  );
};

export const AccessModal = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <RoleBoxMain2 ref={modalRef}>
      <RoleInnerBox widthBox="32.75rem">
        {close && (
          <FlexBox justifyContent="flex-end">
            <CloseBtn onClick={handleCloseModal}>
              <CloseIcon width={13} height={13} className="close-btn" />
            </CloseBtn>
          </FlexBox>
        )}
        {title && (
          <MarginBottom>
            <Text12
              as={"h2"}
              color="#1a1a1a"
              fontSize="1.375rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              {title}
            </Text12>
            <Text10 color="#6b6b6b" fontSize="0.875rem" lineHeight="1.5">
              {description}
            </Text10>
          </MarginBottom>
        )}

        {children}
      </RoleInnerBox>
    </RoleBoxMain2>
  );
};

export const BusinessModal = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <RoleBoxMain2 ref={modalRef}>
      <RoleInnerBox widthBox="30.75rem" padding="1.5rem 2.5rem">
        {close && (
          <FlexBox justifyContent="flex-end">
            <CloseBtn2 onClick={handleCloseModal}>
              <CloseIcon width={13} height={13} className="close-btn" />
            </CloseBtn2>
          </FlexBox>
        )}
        {title && (
          <MarginBottom>
            <Text12
              as={"h2"}
              color="#1a1a1a"
              fontSize="1.375rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              {title}
            </Text12>
            <Text10 color="#6b6b6b" fontSize="0.875rem" lineHeight="1.5">
              {description}
            </Text10>
          </MarginBottom>
        )}

        {children}
      </RoleInnerBox>
    </RoleBoxMain2>
  );
};
