import React, { useState, MouseEvent, useRef } from "react";
// import Filter from "../../assets/icons/Wallet/filter.svg";
import {
  Input2,
  Label2,
  Walletbtn,
  Box5,
  Form2,
  // SelectWrapper,
} from "./Wallet.styled";
import FilterGroup from "./FilterGroup";
import useOutsideClick from "../../hooks/useOutsideClick";
import TableMain from "../Tables/TableMain";
import { columnsWithdraws } from "../Tables/TableData";
import { TabComponent } from "../Reusable/types";
import { useGetTransactionsQuery } from "../../features/api/walletSlice";
import { CardLoader } from "../Reusable/CardLoader";
import EmptyBox from "../Reusable/EmptyBox";

const Withdraws = ({ show }: TabComponent) => {
  const [showRole, setShowRole] = useState(false);

  const modalRef = useRef<HTMLElement>(null);
  const [walletReference, searchByReference] = useState<string>("");
  const { data: transactions, isLoading } = useGetTransactionsQuery({
    status: "",
    transactionType: "2",
    search: walletReference
  });

  // const handleRole = (e: MouseEvent<HTMLButtonElement>): void => {
  //   e.preventDefault();
  //   setShowRole(true);
  // };

  const handleCloseRole = () => {
    setShowRole(false);
  };
  useOutsideClick(modalRef, handleCloseRole);
  const redColor = "#FF1E1E";

  if (!show) return <></>;

  return (
    <div>
      <Form2>
        {/* <Box5>
          <Label2 htmlFor="filter">Filter</Label2>
          <SelectWrapper>
            <Input2
              type="text"
              id="search"
              placeholder="All"
              inputWidth="100%"
            />
            <button className="select-btn" onClick={handleRole}>
              <img src={Filter} alt="" className="select-img-filter" />
            </button>
          </SelectWrapper>
        </Box5> */}
        <Box5>
          <Label2 htmlFor="search">Search</Label2>
          <Input2
            type="text"
            id="search"
            placeholder="Reference"
            inputWidth="100%"
            value={walletReference} 
            onChange={(e: any) => searchByReference(e.target.value)}
          />
        </Box5>
      </Form2>
      {isLoading ? <CardLoader /> : 
       (transactions?.data.length || 0) === 0 ?
       <EmptyBox message={"No withdrawals found!"} /> :
      <div style={{ textAlign: "center" }}>
        <TableMain
          defaultData={transactions?.data || []}
          columns={columnsWithdraws}
          greenColor={redColor}
        />
      </div>}
      {/* <WalletDataTableWith /> */}
      {/* <Walletbtn>
        <button>Load more</button>
      </Walletbtn> */}
      <FilterGroup
        showRole={showRole}
        handleCloseRole={handleCloseRole}
        modalRef={modalRef}
      />
    </div>
  );
};

export default Withdraws;
