
import { apiSlice } from "./apiSlice";
import { AllBanksResponse, AllBeneficiaries, AllCountriesResponse, CreateBankRequest, CreateBankResponse, EarningsRequest, FundingDetails, NetworkEarningsResponse, TransactionNetwork, UpdateFundingRequest, WithdrawalFeeResponse } from "./walletSliceTypes";


const walletSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        //Get countries
        getCountries: build.query<AllCountriesResponse, void>({
            query: () => `/countries`,
        }),

        //Get bank accounts
        getBankAccounts: build.query<AllBeneficiaries, void>({
            query: () => `/bank-accounts`,
            providesTags: ['Account']
        }),

        //Create bank accounts
        createBankAccounts: build.mutation<CreateBankResponse, CreateBankRequest>({
            query: (payload) => ({
                url: '/bank-accounts',
                method: 'POST',
                body: payload,
            }),
        }),

        //Get bank accounts
        getBanks: build.query<AllBanksResponse, void>({
            query: () => `/banks`,
        }),

        //Get transactions
        getTransactions: build.query<AllCountriesResponse, TransactionNetwork>({
            query: ({ status, transactionType, search }) => `/transactions/network?status=${status}&transactionType=${transactionType}&search=${search}`,
            providesTags: ["Overview"]
        }),

        //Withdraw earning 
        withdrawEarnings: build.mutation<AllBanksResponse, EarningsRequest>({
            query: (payload) => ({
                url: '/transactions/withdrawal',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Overview"]
        }),

        //Get withdrawal fee
        getWithdrawalFee: build.query<WithdrawalFeeResponse, void>({
            query: () => `/transactions/withdrawal-fee`,
        }),

        //Get funding details
        getFundingDetails: build.query<FundingDetails, void>({
            query: () => `/transactions/funding-details`,
            providesTags: ['FundingDetail']
        }),

         //Update funding details
         updateFundingDetails: build.mutation<AllBanksResponse, UpdateFundingRequest>({
            query: (payload) => ({
                url: '/transactions/funding-details',
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["FundingDetail"]
        }),

        //Manual Funding
        manualFunding: build.mutation<AllBanksResponse, FormData>({
            query: (payload) => ({
                url: '/transactions/ManualFunding',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Businesses"]
        }),

        //Network Earnings
        networkEarnings: build.query<NetworkEarningsResponse, number>({
            query: (period) => `/transactions/network/earnings?period=${period}`,
            providesTags: ["Overview"]
        }),

    }),
    overrideExisting: true,
})


export const { useGetCountriesQuery, 
    useGetWithdrawalFeeQuery, useManualFundingMutation,
    useGetBankAccountsQuery, useGetFundingDetailsQuery,
    useGetTransactionsQuery, useGetBanksQuery, 
    useUpdateFundingDetailsMutation, useNetworkEarningsQuery,
    useWithdrawEarningsMutation, useCreateBankAccountsMutation } = walletSlice