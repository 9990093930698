import styled, { css } from "styled-components";
import {
  COLOR_BLUE,
  COLOR_WHITE,
  COLOR_YELLOW,
} from "../../../styles/themes/colors";
import { ButtonLoader } from "../loaders";

interface BtnColorProps {
  variant?: string;
  widthProps?: string;
  BGProps?: string;
  fontSizeProps?: string;
  paddingProps?: string;
  marginTop?: string;
  height?: string;
}

const ButtonUI = styled.button<BtnColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.77rem;
  font-weight: 600;
  font-size: ${(props) =>
    !props.fontSizeProps ? "1rem" : props.fontSizeProps};
  line-height: 1.5;
  height: ${(props) => props.height || "auto"};
  border: 1.5px solid transparent;
  border-radius: 1.625rem;
  width: ${(props) => (!props.widthProps ? "100%" : props.widthProps)};
  padding: ${(props) =>
    !props.paddingProps ? "0.4rem 0.675rem" : props.paddingProps};
  margin-top: ${(props) => props.marginTop || "0"};

  cursor: pointer;
  background-color: ${COLOR_BLUE};
  background-color: ${(props) =>
    !props.BGProps ? "COLOR_BLUE" : props.BGProps};
  color: ${COLOR_WHITE};
  &:hover {
    color: #f5f5f8;
    background-color: #465a8f;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  ${({ variant }) =>
    variant === "outline" &&
    css`
      border: 1.5px solid ${COLOR_YELLOW};
      background: transparent;
      color: ${COLOR_YELLOW};
      &:hover {
        border: 1.5px solid #dad0a8;
        background: #f8f3e1;
        color: #e5b909;
        transition: border 0.3s ease, background-color 0.3s ease,
          color 0.3s ease;
      }
    `}

  .button-text {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: ${COLOR_BLUE};
  }

  .googleImg {
    width: 1.5rem;
    height: 1.5rem;
  }

  /* disabled */
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : null)};
  opacity: ${(props) => (props.disabled ? "0.6" : null)};
`;

const ButtonResetUI = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.77rem;
  background: none;
  border: none;
  padding: 0.3rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: #f5f5f8;
    border-radius: 5px;
  }
`;

// const ButtonTabUI = styled.button`
//   border: none;
//   background: transparent;
//   padding: 0.375rem 0.875rem;
//   font-size: 0.8125rem;
//   line-height: 1.5;
//   letter-spacing: -0.08px;
//   color: ${COLOR_BLACK};
//   white-space: nowrap;

//   &.active {
//     background: #e5b910;
//     color: ${COLOR_WHITE};
//     border: 0.5px solid rgba(0, 0, 0, 0.04);
//     box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
//     border-radius: 7px;
//     padding: 0 1.25rem;
//   }
// `;

interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
  text?: string;
  variant?: string;
  widthProps?: string;
  marginTop?: string;
  BGProps?: string;
  fontSizeProps?: string;
  icon?: React.ReactNode;
  paddingProps?: string;
  isLoading?: boolean;
  height?: string;
}

interface ButtonResetProps extends React.ComponentPropsWithRef<"button"> {
  children: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  variant,
  isLoading,
  icon,
  ...props
}) => {
  return (
    <ButtonUI
      fontSizeProps={props.fontSizeProps}
      paddingProps={props.paddingProps}
      widthProps={props.widthProps}
      marginTop={props.marginTop}
      BGProps={props.BGProps}
      variant={variant}
      {...props}
    >
      {icon && <>{icon}</>}
      {isLoading ? <ButtonLoader loading={isLoading} /> : text}
    </ButtonUI>
  );
};

export const ButtonReset: React.FC<ButtonResetProps> = ({
  children,
  onClick,
}) => {
  return <ButtonResetUI onClick={onClick}>{children}</ButtonResetUI>;
};
