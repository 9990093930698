// remember to make this a reusable modal to be used in both account and businesses

import React, { MouseEvent, useState } from "react";
import { Box7, BtnGroup } from "../Dashboard/Dashboard.styled";
import { Button, ButtonReset } from "../Reusable/Buttons/button";
import {
  ErrorMsg,
  Form,
  Input,
  Label,
} from "../Authentication/styles/Container.styled";
import { BusinessModal, ConfirmModal, Modal } from "../Reusable/Modal";
import { Text10, Text14, Text400, TextTitle2 } from "../../styles/all.styled";
import { ErrorMessage } from "../Reusable/Error";
import TickSquare from "../../assets/icons/Wallet/tick-square.svg";
import ArrowIcon from "../../assets/icons/Wallet/arrow-yellow.svg";
// import {
//   BtnGroup2,
//   CircleSpan,
//   FlexBox,
//   Margin,
// } from "../Campaign/Campaign.styled";
import ViewPassport from "../../assets/icons/view-passport.svg";
import { BtnReset, DetailList, ImageBox2 } from "./business.styled";
import { useNavigate, useParams } from "react-router-dom";
import {
  ConfirmProps,
  IdentificationDetailsProps,
  VerifyIdentityProps,
} from "./type";
import { MarginBox, Relative } from "../Account/Account.styled";
import {
  BtnGroup2,
  CircleSpan,
  FlexBox,
  TextBox,
  TopBox,
} from "../Reusable/Reuseable.styled";
import { useSetBusinessStatusMutation } from "../../features/api/businessesSlice";

export const AuthorizedAccess = ({
  handleCloseRole,
  handleNext,
  isLoading,
}: VerifyIdentityProps) => {
  const { businessId } = useParams();
  const navigate = useNavigate();

  const [rejectReason, setRejectreason] = useState<string>("");
  const [errMsg, setErr] = useState<string>("");

  const [setBusinessStatus, { isLoading: gettingApproveBusiness }] =
    useSetBusinessStatusMutation();
  const handleReject = () => {
    let data = {
      id: Number(businessId),
      approve: false,
      reason: rejectReason,
    };

    setBusinessStatus(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          navigate("/home/businesses");
        } else {
          setErr(res.Message);
        }
      })
      .catch((error) => {
        if (error?.data?.Message) {
          setErr(error.data.Message);
        } else {
          setErr("An error occurred.");
        }
      });
  };
  return (
    <BusinessModal
      title="Reject Request"
      description="Please indicate why this ID verification is being rejected"
      close={true}
      handleCloseModal={handleCloseRole}
    >
      <div>
        <Form className="form-margin">
          <div className="formControlPassword">
            <Label htmlFor="reason" textAlign="left">
              Reason
            </Label>
            <Input
              type="text"
              name="reason"
              id="reason"
              value={rejectReason}
              onChange={(e) => setRejectreason(e.target.value)}
            />
          </div>
        </Form>
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
        <BtnGroup>
          <Button
            text="Cancel"
            variant="outline"
            onClick={handleCloseRole}
            height="40px"
          />
          <Button
            text="Reject"
            BGProps="#FF1E1E"
            isLoading={gettingApproveBusiness}
            onClick={handleReject}
            height="40px"
          />
        </BtnGroup>
      </div>
    </BusinessModal>
  );
};

//This should also be a modal(confirm approval): verification and businesses
export const ConfirmApproval: React.FC<ConfirmProps> = ({
  // errMsg,
  // handleSubmit,
  handleCloseModal,
}) => {
  const { businessId } = useParams();
  const navigate = useNavigate();

  const [errMsg, setErr] = useState<string>("");
  // Approve kyc
  const [setBusinessStatus, { isLoading: gettingApproveBusiness }] =
    useSetBusinessStatusMutation();

  let data = {
    id: Number(businessId),
    approve: true,
    reason: "",
  };

  const handleApproved = () => {
    console.log(data);
    setBusinessStatus(data)
      .unwrap()
      .then((res) => {
        // setShowAccept(false);
        navigate("/home/businesses");
      })
      .catch((error) => {
        if (error?.data?.Message) {
          setErr(error.data.Message);
        } else {
          setErr("An error occurred.");
        }
      });
  };
  return (
    <ConfirmModal handleCloseModal={handleCloseModal}>
      <TopBox>
        <CircleSpan background="#EEE1A9" width="4rem" height="4rem">
          <img src={TickSquare} alt="" width={35} height={35} />
        </CircleSpan>
        <TextBox margin="0 auto 2rem">
          <TextTitle2 marginBottom="0.75rem" color="#25346A">
            Confirm Approval
          </TextTitle2>
          <Text400>
            Before confirming, please make sure you've gone over the user
            document and double-checked the authenticity.
          </Text400>
        </TextBox>
        {/* {errMsg && <ErrorMessage errMsg={errMsg} />} */}
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
        <BtnGroup2 flexDirection="column" width="100%">
          <Button
            type="button"
            text="Confirm"
            onClick={handleApproved}
            isLoading={gettingApproveBusiness}
            height="40px"
            paddingProps="0.5rem"
          />
          <Button
            variant="outline"
            type="button"
            text="Cancel"
            paddingProps="0.5rem"
            onClick={handleCloseModal}
          />
        </BtnGroup2>
      </TopBox>
    </ConfirmModal>
  );
};

// Modal that shows details of identification
export const IdentificationDetails = ({
  handleCloseRole,
}: IdentificationDetailsProps) => {
  return (
    <BusinessModal
      title="Identification Details"
      description=""
      close={true}
      handleCloseModal={handleCloseRole}
    >
      <FlexBox flexDirection="column" gap="1.5rem" alignItems="stretch">
        <ImageBox2>
          <img src={ViewPassport} alt="passport" width={150} height={150} />
        </ImageBox2>

        <div>
          <DetailList>
            <Text10 lineHeight="1.4" fontSize="0.75rem">
              ID type
            </Text10>
            <Text14 color="#1a1a1a" fontWeight="600">
              International Passport
            </Text14>
          </DetailList>
          <DetailList>
            <Text10 lineHeight="1.4" fontSize="0.75rem">
              First Name
            </Text10>
            <Text14 color="#1a1a1a" fontWeight="600">
              Adayemi
            </Text14>
          </DetailList>
          <DetailList>
            <Text10 lineHeight="1.4" fontSize="0.75rem">
              Last Name
            </Text10>
            <Text14 color="#1a1a1a" fontWeight="600">
              Adam
            </Text14>
          </DetailList>
          <DetailList>
            <Text10 lineHeight="1.4" fontSize="0.75rem">
              Issue Date
            </Text10>
            <Text14 color="#1a1a1a" fontWeight="600">
              May, 2009
            </Text14>
          </DetailList>
          <DetailList>
            <Text10 lineHeight="1.4" fontSize="0.75rem">
              Expiry Date
            </Text10>
            <Text14 color="#1a1a1a" fontWeight="600">
              May, 2009
            </Text14>
          </DetailList>
        </div>
        <Text10 lineHeight="normal" opacity="0.4">
          Powered by Smile ID
        </Text10>
      </FlexBox>
    </BusinessModal>
  );
};

export const VerifyIdentity = ({
  handleCloseRole,
  handleNext,
  errMsg,
  isLoading,
}: VerifyIdentityProps) => {
  const [showIdType, setShowIdType] = useState(false);
  const handleIdType = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowIdType((prev) => !prev);
  };
  return (
    <BusinessModal
      title="Verify Identity"
      description="Please indicate why this advert is being rejected"
      close={true}
      handleCloseModal={handleCloseRole}
    >
      <FlexBox
        flexDirection="column"
        gap="2rem"
        alignItems="stretch"
        MarginTop="2rem"
      >
        <Form>
          <div className="formControlPassword">
            <Label htmlFor="idType" textAlign="left">
              Select ID type
            </Label>
            <Relative>
              <Input type="text" name="idType" id="idType" />

              <div
                style={{ position: "absolute", right: "0.5rem", top: "-10px" }}
              >
                <BtnReset onClick={handleIdType}>
                  <img src={ArrowIcon} alt="" className="select-img-filter" />
                </BtnReset>
              </div>

              {showIdType && (
                <Box7>
                  <ul>
                    <li>National Id</li>
                    <li>Drivers License</li>
                    <li>PVC</li>
                  </ul>
                </Box7>
              )}
            </Relative>
          </div>
          <div className="formControlPassword">
            <Label htmlFor="idNumber" textAlign="left">
              Identification Number
            </Label>
            <Input
              type="text"
              name="idNumber"
              id="idNumber"
              placeholder="0000000000"
            />
          </div>
        </Form>
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}

        <MarginBox margin="0 auto">
          <Button
            text="Next"
            isLoading={isLoading}
            onClick={handleNext}
            widthProps="9rem"
          />
        </MarginBox>
        <Text10 lineHeight="normal" opacity="0.4">
          Powered by Smile ID
        </Text10>
      </FlexBox>
    </BusinessModal>
  );
};

export const CellRendererButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        text="View more"
        paddingProps="0.2rem 0.2rem"
        fontSizeProps="0.7rem"
        onClick={() => navigate(`"./:businessId"`)}
      />
    </>
  );
};
